import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SvgIcon from "@material-ui/core/SvgIcon";
import {withStyles} from "@material-ui/core/styles";
import clsx from "clsx";

class IconFacebook extends Component {
  render() {
    let { classes, selected, fontSize, ...other } = this.props;
    let fill1 = '#1761f1', fill2 = '#fff';
    if (selected) {
      fill1 = '#fff';
      fill2 = 'transparent';
    }
    let isFontSizeXLarge = false;
    if (fontSize === 'xlarge') {
      fontSize = 'inherit';
      isFontSizeXLarge = true;
    }
    return (
      <SvgIcon
        className={clsx(classes.root, isFontSizeXLarge && classes.xlarge)}
        viewBox="0 0 56 56"
        fontSize={fontSize}
        {...other}
      >
        <path
          fill={fill1}
          d="M55,28A27,27,0,1,0,23.79,54.63V35.79H16.94V28h6.85V22.06c0-6.76,4-10.49,10.18-10.49a41.08,41.08,0,0,1,6,.53v6.63H36.61c-3.35,0-4.4,2.08-4.4,4.21V28h7.48l-1.2,7.79H32.21V54.63A27,27,0,0,0,55,28Z"
        />
        <path
          fill={fill2}
          d="M38.49,35.79,39.69,28H32.21V22.94c0-2.13,1-4.21,4.4-4.21H40V12.1a41.08,41.08,0,0,0-6-.53c-6.16,0-10.18,3.73-10.18,10.49V28H16.94v7.79h6.85V54.63a27,27,0,0,0,8.42,0V35.79Z"
        />
      </SvgIcon>
    );
  }
}

IconFacebook.propTypes = {
  selected: PropTypes.bool,
  fontSize: PropTypes.oneOf(['small', 'default', 'large', 'xlarge', 'inherit']),
};

IconFacebook.defaultProps = {
  selected: false,
  fontSize: 'inherit',
};

const styles = () => ({
  root: {
    verticalAlign: 'sub',
  },
  xlarge: {
    fontSize: '3rem',
  },
});

export default withStyles(styles)(IconFacebook);