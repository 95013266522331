import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SvgIcon from "@material-ui/core/SvgIcon";
import {withStyles} from "@material-ui/core/styles";
import clsx from "clsx";

class IconSocialNetworks extends Component {
  render() {
    let { classes, fontSize, ...other } = this.props;
    let isFontSizeXLarge = false;
    if (fontSize === 'xlarge') {
      fontSize = 'inherit';
      isFontSizeXLarge = true;
    }
    return (
      <SvgIcon
        className={clsx(classes.root, isFontSizeXLarge && classes.xlarge)}
        viewBox="0 0 62 47"
        fontSize={fontSize}
        {...other}
      >
        <path stroke="#16b7ef" fill="transparent"
              d="M14.15,34.68a13,13,0,1,1,9.13,3.74,13.14,13.14,0,0,1-4.71-.88A9.7,9.7,0,0,1,10.92,41,10.78,10.78,0,0,0,14.15,34.68Z"/>
        <path fill="#16b7ef"
              d="M47.4,26.68a13,13,0,1,0-9.12,3.74,13.13,13.13,0,0,0,4.7-.88A9.7,9.7,0,0,0,50.63,33,10.78,10.78,0,0,1,47.4,26.68Z"/>
      </SvgIcon>
    );
  }
}

IconSocialNetworks.propTypes = {
  fontSize: PropTypes.oneOf(['small', 'default', 'large', 'xlarge', 'inherit']),
};

IconSocialNetworks.defaultProps = {
  fontSize: 'inherit',
};

const styles = () => ({
  root: {
    verticalAlign: 'sub',
  },
  xlarge: {
    fontSize: '3rem',
  },
});

export default withStyles(styles)(IconSocialNetworks);