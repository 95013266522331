import React, {Component} from 'react';
import {ReactComponent as LogoSrc} from '../../images/logo_n3.svg';
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";

class LogoN3ctar extends Component {
  render() {
    let { classes, ...other } = this.props;
    return (
      <Box className={classes.container} {...other}>
        <Link href="https://n3ctar.net" target="_blank" rel="noopener" variant="inherit" color="inherit">
          <LogoSrc className={classes.logo} />
        </Link>
      </Box>
    );
  }
}

LogoN3ctar.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = theme => ({
  container: {
    verticalAlign: 'middle',
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  },
  logo: {
    width: '100%',
    maxWidth: '30px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '30px',
    },
  },
});

export default withStyles(styles)(LogoN3ctar);