import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SvgIcon from "@material-ui/core/SvgIcon";
import {withStyles} from "@material-ui/core/styles";
import clsx from "clsx";

class IconTwitter extends Component {
  render() {
    let { classes, selected, fontSize, ...other } = this.props;
    let fill1 = '#16b7ef';
    if (selected) {
      fill1 = '#fff';
    }
    let isFontSizeXLarge = false;
    if (fontSize === 'xlarge') {
      fontSize = 'inherit';
      isFontSizeXLarge = true;
    }
    return (
      <SvgIcon
        className={clsx(classes.root, isFontSizeXLarge && classes.xlarge)}
        viewBox="0 0 56 56"
        fontSize={fontSize}
        {...other}
      >
        <path
          fill={fill1}
          d="M49.47,17c0,.47,0,1,0,1.43C49.5,33.06,38.36,50,18,50A31.36,31.36,0,0,1,1,45a22.82,22.82,0,0,0,2.64.16A22.27,22.27,0,0,0,17.4,40.38a11.08,11.08,0,0,1-10.35-7.7,11.05,11.05,0,0,0,2.09.21,11.17,11.17,0,0,0,2.91-.39A11.09,11.09,0,0,1,3.17,21.63v-.14a11,11,0,0,0,5,1.39,11.09,11.09,0,0,1-4.93-9.23A11,11,0,0,1,4.76,8.09,31.43,31.43,0,0,0,27.6,19.66a10.47,10.47,0,0,1-.29-2.52A11.07,11.07,0,0,1,46.47,9.56a22.25,22.25,0,0,0,7-2.69A10.92,10.92,0,0,1,48.63,13,21.84,21.84,0,0,0,55,11.25,22.52,22.52,0,0,1,49.47,17Z"
        />
      </SvgIcon>
    );
  }
}

IconTwitter.propTypes = {
  selected: PropTypes.bool,
  fontSize: PropTypes.oneOf(['small', 'default', 'large', 'xlarge', 'inherit']),
};

IconTwitter.defaultProps = {
  selected: false,
  fontSize: 'inherit',
};

const styles = () => ({
  root: {
    verticalAlign: 'sub',
  },
  xlarge: {
    fontSize: '3rem',
  },
});

export default withStyles(styles)(IconTwitter);