import React, {Component} from 'react';
import {ReactComponent as LogoSrc} from '../../images/logo.svg';
import {ReactComponent as LogoEmbedSrc} from '../../images/logo_embed.svg';
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";

class Logo extends Component {
  render() {
    let { classes, embed, ...other } = this.props;
    return (
      <Box className={classes.container} {...other}>
        <Link href="/" variant="inherit" color="inherit">
          {!embed && <LogoSrc className={classes.logo} />}
          {embed && <LogoEmbedSrc className={classes.logoEmbed} />}
        </Link>
      </Box>
    );
  }
}

Logo.propTypes = {
  classes: PropTypes.object.isRequired,
  embed: PropTypes.bool,
};

Logo.defaultProps = {
  embed: false,
};

const styles = theme => ({
  container: {
    verticalAlign: 'top',
  },
  logo: {
    width: '100%',
    maxWidth: '140px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100px',
    },
  },
  logoEmbed: {
    width: '100%',
    maxWidth: '300px',
  },
});

export default withStyles(styles)(Logo);