import React, {Component} from 'react';
import PropTypes from "prop-types";
import Title from "../../components/title";
import {withStyles} from "@material-ui/core/styles";
import WidgetMobileLines from "../../components/widget-mobile-lines";
import WidgetAverageMobileUse from "../../components/widget-average-mobile-use";
import WidgetMobileUse from "../../components/widget-mobile-use";
import WidgetMobileVelocityAndAccessibility from "../../components/widget-mobile-velocity-and-accessibility";
import WidgetMobileApps from "../../components/widget-mobile-apps";
import WidgetSuggestions from "../../components/widget-suggestions";
import WidgetMobileOs from "../../components/widget-mobile-os/widget-mobile-os";

class PageMobile extends Component {
  render() {
    let { classes, data } = this.props;
    return (
      <div className={classes.root}>
        <Title title={data.title} />
        <br/>
        <br/>
        <div className={classes.section}>
          <WidgetAverageMobileUse
            text={data.averageUse.text}
            icon={data.averageUse.icon}
            highlight={data.averageUse.highlight}
            sources={data.averageUse.sources}
          />
        </div>
        <div className={classes.section}>
          <WidgetMobileLines
            items={data.mobileLines.items}
            data={data.mobileLines.data}
            dataMobile={data.mobileLines.dataMobile}
          />
        </div>
        <div className={classes.section}>
          <WidgetMobileUse
            items={data.mobileUse}
          />
        </div>
        <div className={classes.section}>
          <WidgetMobileVelocityAndAccessibility
            items={data.mobileVelocityAndAccessibility}
          />
        </div>
        <div className={classes.section}>
          <WidgetMobileOs
            text={data.os.text}
            highlight={data.os.highlight}
            sources={data.os.sources}
          />
        </div>
        <div className={classes.section}>
          <WidgetMobileApps
            byCategory={data.apps.byCategory}
            byUse={data.apps.byUse}
          />
        </div>
        <div className={classes.suggestionsContainer}>
          <WidgetSuggestions />
        </div>
      </div>
    );
  }
}

PageMobile.propTypes = {
  data: PropTypes.shape({
    averageUse: PropTypes.shape({
      text: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      highlight: PropTypes.string,
      sources: PropTypes.array,
    }).isRequired,
    mobileLines: PropTypes.shape({
      items: PropTypes.array.isRequired,
      data: PropTypes.object.isRequired,
      dataMobile: PropTypes.object.isRequired,
    }).isRequired,
    mobileUse: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.string.isRequired,
      highlight: PropTypes.string,
      sources: PropTypes.array,
      graph: PropTypes.string.isRequired,
      data: PropTypes.array.isRequired,
    })).isRequired,
    os: PropTypes.shape({
      text: PropTypes.string.isRequired,
      highlight: PropTypes.string,
      sources: PropTypes.array,
    }).isRequired,
    apps: PropTypes.shape({
      byCategory: PropTypes.object.isRequired,
      byUse: PropTypes.object.isRequired,
    }).isRequired,
  }),
};

const styles = theme => ({
  root: {
    backgroundColor: '#f2f2f2',
    marginLeft: theme.spacing(-8),
    marginRight: theme.spacing(-8),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(-2),
      marginRight: theme.spacing(-2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  section: {
    paddingBottom: theme.spacing(8),
  },
  suggestionsContainer: {
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(4),
  }
});

export default withStyles(styles)(PageMobile);