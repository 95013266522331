import React, {Component} from 'react';
import SvgIcon from "@material-ui/core/SvgIcon";
import {withStyles} from "@material-ui/core/styles";

class IconNavbarTwitter extends Component {
  render() {
    let { classes, ...other } = this.props;
    return (
      <SvgIcon
        className={classes.root}
        viewBox="0 0 18 18"
        {...other}
      >
        <path d="M15.36,5.74c0,.14,0,.28,0,.42A9.27,9.27,0,0,1,6,15.5,9.3,9.3,0,0,1,1,14a6.79,6.79,0,0,0,.78,0,6.6,6.6,0,0,0,4.08-1.4,3.3,3.3,0,0,1-3.07-2.28,3.18,3.18,0,0,0,.62.06,3,3,0,0,0,.86-.12A3.28,3.28,0,0,1,1.64,7.11v0a3.13,3.13,0,0,0,1.49.41,3.29,3.29,0,0,1-1-4.38A9.36,9.36,0,0,0,8.88,6.53a3.08,3.08,0,0,1-.09-.75,3.28,3.28,0,0,1,5.68-2.24,6.87,6.87,0,0,0,2.09-.8,3.27,3.27,0,0,1-1.45,1.81A6.6,6.6,0,0,0,17,4a6.79,6.79,0,0,1-1.64,1.7Z"/>
      </SvgIcon>
    );
  }
}

const styles = () => ({
  root: {
    verticalAlign: 'middle',
  },
});

export default withStyles(styles)(IconNavbarTwitter);