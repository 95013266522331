import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SvgIcon from "@material-ui/core/SvgIcon";
import {withStyles} from "@material-ui/core/styles";
import clsx from "clsx";

class IconYoutube extends Component {
  render() {
    let { classes, selected, fontSize, ...other } = this.props;
    let fill1 = '#f50000';
    if (selected) {
      fill1 = '#fff';
    }
    let isFontSizeXLarge = false;
    if (fontSize === 'xlarge') {
      fontSize = 'inherit';
      isFontSizeXLarge = true;
    }
    return (
      <SvgIcon
        className={clsx(classes.root, isFontSizeXLarge && classes.xlarge)}
        viewBox="0 0 56 56"
        fontSize={fontSize}
        {...other}
      >
        <path
          fill={fill1}
          d="M53.87,15a6.76,6.76,0,0,0-4.77-4.77C44.89,9.1,28,9.1,28,9.1s-16.89,0-21.1,1.13A6.76,6.76,0,0,0,2.13,15C1,19.21,1,28,1,28s0,8.79,1.13,13A6.76,6.76,0,0,0,6.9,45.77C11.11,46.9,28,46.9,28,46.9s16.89,0,21.1-1.13A6.76,6.76,0,0,0,53.87,41C55,36.79,55,28,55,28S55,19.21,53.87,15ZM22.6,36.1V19.9l14,8.1Z"
        />
      </SvgIcon>
    );
  }
}

IconYoutube.propTypes = {
  selected: PropTypes.bool,
  fontSize: PropTypes.oneOf(['small', 'default', 'large', 'xlarge', 'inherit']),
};

IconYoutube.defaultProps = {
  selected: false,
  fontSize: 'inherit',
};

const styles = () => ({
  root: {
    verticalAlign: 'sub',
  },
  xlarge: {
    fontSize: '3rem',
  },
});

export default withStyles(styles)(IconYoutube);