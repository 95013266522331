import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SvgIcon from "@material-ui/core/SvgIcon";
import {withStyles} from "@material-ui/core/styles";
import clsx from "clsx";

class IconAndroid extends Component {
  render() {
    let { classes, selected, fontSize, ...other } = this.props;
    let fill1 = '#7fb832';
    if (selected) {
      fill1 = '#fff';
    }
    let isFontSizeXLarge = false;
    if (fontSize === 'xlarge') {
      fontSize = 'inherit';
      isFontSizeXLarge = true;
    }
    return (
      <SvgIcon
        className={clsx(classes.root, isFontSizeXLarge && classes.xlarge)}
        viewBox="0 0 56 56"
        fontSize={fontSize}
        {...other}
      >
        <path
          fill={fill1}
          d="M11.52,21a3,3,0,0,0-2.95,2.95V37.68a3,3,0,0,0,5.9,0V23.93A3,3,0,0,0,11.52,21Z"
        />
        <path
          fill={fill1}
          d="M44.92,21a3,3,0,0,0-3,2.95V37.68a2.95,2.95,0,0,0,5.89,0V23.93A3,3,0,0,0,44.92,21Z"
        />
        <path
          fill={fill1}
          d="M39,21H17.41a1,1,0,0,0-1,1V37.68a4.91,4.91,0,0,0,3.93,4.81v7a2.95,2.95,0,1,0,5.89,0V42.59h3.93v6.87a3,3,0,1,0,5.9,0v-7A4.92,4.92,0,0,0,40,37.68V22A1,1,0,0,0,39,21Z"
        />
        <path
          fill={fill1}
          d="M35.36,9.33l2.39-2.39a1,1,0,1,0-1.36-1.41l0,0L33.58,8.34a13.5,13.5,0,0,0-10.72,0L20.07,5.55a1,1,0,0,0-1.38,0,1,1,0,0,0,0,1.37l2.39,2.39A10.36,10.36,0,0,0,16.43,18a1,1,0,0,0,1,1H39a1,1,0,0,0,1-1A10.38,10.38,0,0,0,35.36,9.33Zm-12,5.75a1,1,0,1,1,1-1A1,1,0,0,1,23.31,15.08Zm9.82,0a1,1,0,1,1,1-1A1,1,0,0,1,33.13,15.08Z"
        />
      </SvgIcon>
    );
  }
}

IconAndroid.propTypes = {
  selected: PropTypes.bool,
  fontSize: PropTypes.oneOf(['small', 'default', 'large', 'xlarge', 'inherit']),
};

IconAndroid.defaultProps = {
  selected: false,
  fontSize: 'inherit',
};

const styles = () => ({
  root: {
    verticalAlign: 'sub',
  },
  xlarge: {
    fontSize: '3rem',
  },
});

export default withStyles(styles)(IconAndroid);