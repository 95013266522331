import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconShare from "../../icons/icon-share";
import Hidden from "@material-ui/core/Hidden";

class ButtonShare extends Component {
  render() {
    let { classes, iconSize, ...other } = this.props;
    return (
      <Button className={classes.root} {...other}>
        <Hidden xsDown>
          Compartir
        </Hidden>
        <IconShare className={classes.icon} fontSize={iconSize} />
      </Button>
    );
  }
}

ButtonShare.propTypes = {
  classes: PropTypes.object.isRequired,
  iconSize: PropTypes.oneOf(['small', 'default', 'large', 'inherit']),
};


ButtonShare.defaultProps = {
  size: 'small',
  iconSize: 'small',
  disableElevation: true,
};

const styles = theme => ({
  root: {
    minWidth: 'auto',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0),
    },
  },
  icon: {
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(0),
    },
  }
});

export default withStyles(styles)(ButtonShare);