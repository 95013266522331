import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Item from "../item";
import Grid from "@material-ui/core/Grid";
import Graph from "./components/graph";
import Hidden from "@material-ui/core/Hidden";
import Share from "../share";
import Utils from "../../utils/utils";
import {withRouter} from "react-router";

const anchor = 'velocidad-media-de-descarga';

class WidgetDownloadSpeed extends Component {
  componentDidMount() {
    let hash = this.props.location.hash.substr(1);
    if (anchor !== hash) {
      return;
    }
    Utils.scrollTo(hash);
  }

  render() {
    let { text, highlight, sources, data, dataMobile } = this.props;
    return (
      <Grid container id={anchor}>
        <Grid item xs={10}>
          <Item text={text} highlight={highlight} sources={sources} />
        </Grid>
        <Grid item xs={2} align="right">
          <Share fragment={anchor} />
        </Grid>

        <Hidden smDown>
          <Grid item xs={12} align="center">
            <Graph categories={data.texts} data={data.values} />
          </Grid>
        </Hidden>

        <Hidden mdUp>
          <Grid item xs={12} align="center">
            <Graph categories={dataMobile.texts} data={dataMobile.values} />
          </Grid>
        </Hidden>
      </Grid>
    );
  }
}

WidgetDownloadSpeed.propTypes = {
  text: PropTypes.string.isRequired,
  highlight: PropTypes.string.isRequired,
  sources: PropTypes.array,
  data: PropTypes.object.isRequired,
  dataMobile: PropTypes.object.isRequired,
};

export default withRouter(WidgetDownloadSpeed);