import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SvgIcon from "@material-ui/core/SvgIcon";
import {withStyles} from "@material-ui/core/styles";
import clsx from "clsx";

class IconBank extends Component {
  render() {
    let { classes, fontSize, ...other } = this.props;
    let isFontSizeXLarge = false;
    if (fontSize === 'xlarge') {
      fontSize = 'inherit';
      isFontSizeXLarge = true;
    }
    return (
      <SvgIcon
        className={clsx(classes.root, isFontSizeXLarge && classes.xlarge)}
        viewBox="0 0 62 47"
        fontSize={fontSize}
        {...other}
      >
        <path
          fill="#16b7ef"
          d="M48.15,37.38V36.13a1.21,1.21,0,0,0-1.21-1.21h-.65v-.8A1.59,1.59,0,0,0,45,32.57V20.85a1.59,1.59,0,0,0,1.29-1.55v-.88h.13a1.74,1.74,0,0,0,1.73-1.73v-.88a1.83,1.83,0,0,0,.35-3.45L31.26,4.62a1.86,1.86,0,0,0-1.5,0L12.49,12.36a1.83,1.83,0,0,0,0,3.32,2.43,2.43,0,0,0,.38.13v.88a1.73,1.73,0,0,0,1.73,1.73h.13v.88A1.58,1.58,0,0,0,16,20.85V32.57a1.58,1.58,0,0,0-1.28,1.55v.8H14a1.21,1.21,0,0,0-1.2,1.21v1.26a1.77,1.77,0,0,0-1.4,1.73V40a1.07,1.07,0,0,0,1.08,1.07h36A1.08,1.08,0,0,0,49.58,40v-.87A1.78,1.78,0,0,0,48.15,37.38Zm-3-3.26v.8h-5v-.8a.46.46,0,0,1,.45-.46h4.11A.47.47,0,0,1,45.17,34.12ZM32.84,32.57V20.85a1.58,1.58,0,0,0,1.28-1.55v-.88H39v.88a1.58,1.58,0,0,0,1.28,1.55V32.57A1.58,1.58,0,0,0,39,34.12v.8H34.12v-.8A1.58,1.58,0,0,0,32.84,32.57Zm-12.17,0V20.85A1.59,1.59,0,0,0,22,19.3v-.88h4.9v.88a1.59,1.59,0,0,0,1.29,1.55V32.57a1.59,1.59,0,0,0-1.29,1.55v.8H22v-.8A1.59,1.59,0,0,0,20.67,32.57Zm7.77-12.81A.47.47,0,0,1,28,19.3v-.88h5v.88a.47.47,0,0,1-.46.46Zm3.28,1.12V32.54H29.27V20.88ZM28,34.12a.47.47,0,0,1,.46-.46h4.11a.47.47,0,0,1,.46.46v.8H28Zm13.45-1.58V20.88h2.46V32.54ZM45.17,19.3h0a.47.47,0,0,1-.46.46H40.6a.46.46,0,0,1-.45-.46v-.88h5ZM13.23,14.73a.66.66,0,0,1-.3-.07h0a.7.7,0,0,1,0-1.28L30.22,5.64a.73.73,0,0,1,.58,0L48,13.38a.7.7,0,0,1-.29,1.35Zm1.34,2.57a.61.61,0,0,1-.62-.61v-.84H47v.84a.61.61,0,0,1-.61.61Zm1.25,2v-.88h5v.88a.47.47,0,0,1-.46.46h-4.1A.46.46,0,0,1,15.82,19.3Zm3.74,1.58V32.54H17.1V20.88ZM15.82,34.12a.46.46,0,0,1,.46-.46h4.1a.47.47,0,0,1,.46.46v.8h-5Zm-1.87,2A.09.09,0,0,1,14,36h32.9a.09.09,0,0,1,.09.09v1.22H14ZM48.46,40H12.56v-.83a.66.66,0,0,1,.65-.66h34.6a.66.66,0,0,1,.65.66Z"
        />
        <path
          fill="#16b7ef"
          d="M30.56,7.73a2.87,2.87,0,1,0,2.87,2.87A2.87,2.87,0,0,0,30.56,7.73Zm1,3.93a1,1,0,0,1-.39.32,1.32,1.32,0,0,1-.3.09v.37h-.55v-.37A1.31,1.31,0,0,1,30,12a1,1,0,0,1-.39-.33,1,1,0,0,1-.2-.5l.54-.08a.68.68,0,0,0,.23.38.64.64,0,0,0,.41.14.66.66,0,0,0,.38-.11.3.3,0,0,0,.14-.26.28.28,0,0,0-.06-.18.53.53,0,0,0-.2-.11l-.72-.22a.75.75,0,0,1-.61-.75.85.85,0,0,1,.12-.46A.82.82,0,0,1,30,9.2a.87.87,0,0,1,.28,0V8.76h.55v.4a.85.85,0,0,1,.22,0,1,1,0,0,1,.58.74l-.56.1a.44.44,0,0,0-.1-.22.4.4,0,0,0-.19-.15.51.51,0,0,0-.25-.06.47.47,0,0,0-.24,0,.34.34,0,0,0-.17.11.27.27,0,0,0-.06.17.23.23,0,0,0,.09.18.81.81,0,0,0,.28.13l.5.14a1.33,1.33,0,0,1,.56.3.73.73,0,0,1,.16.5A.86.86,0,0,1,31.55,11.66Z"
        />
      </SvgIcon>
    );
  }
}

IconBank.propTypes = {
  fontSize: PropTypes.oneOf(['small', 'default', 'large', 'xlarge', 'inherit']),
};

IconBank.defaultProps = {
  fontSize: 'inherit',
};

const styles = () => ({
  root: {
    verticalAlign: 'sub',
  },
  xlarge: {
    fontSize: '3rem',
  },
});

export default withStyles(styles)(IconBank);