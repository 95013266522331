import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SvgIcon from "@material-ui/core/SvgIcon";
import {withStyles} from "@material-ui/core/styles";
import clsx from "clsx";

class IconSpotify extends Component {
  render() {
    let { classes, selected, fontSize, ...other } = this.props;
    let fill1 = '#fff', fill2 = '#1ed760';
    if (selected) {
      fill1 = 'transparent';
      fill2 = '#fff';
    }
    let isFontSizeXLarge = false;
    if (fontSize === 'xlarge') {
      fontSize = 'inherit';
      isFontSizeXLarge = true;
    }
    return (
      <SvgIcon
        className={clsx(classes.root, isFontSizeXLarge && classes.xlarge)}
        viewBox="0 0 56 56"
        fontSize={fontSize}
        {...other}
      >
        <circle fill={fill1} cx="27" cy="27" r="23"/>
        <path
          fill={fill2}
          d="M28,1A27,27,0,1,0,55,28,27,27,0,0,0,28,1ZM40.38,39.94a1.67,1.67,0,0,1-2.31.56c-6.34-3.87-14.32-4.75-23.72-2.6a1.68,1.68,0,1,1-.75-3.28c10.29-2.35,19.11-1.34,26.22,3A1.67,1.67,0,0,1,40.38,39.94Zm3.31-7.35a2.11,2.11,0,0,1-2.9.69c-7.26-4.46-18.32-5.75-26.9-3.14a2.11,2.11,0,1,1-1.23-4c9.81-3,22-1.53,30.33,3.59A2.11,2.11,0,0,1,43.69,32.59ZM44,24.94c-8.7-5.17-23.06-5.65-31.37-3.13A2.52,2.52,0,1,1,11.14,17c9.54-2.89,25.39-2.33,35.41,3.61A2.53,2.53,0,0,1,44,24.94Z"
        />
      </SvgIcon>
    );
  }
}

IconSpotify.propTypes = {
  selected: PropTypes.bool,
  fontSize: PropTypes.oneOf(['small', 'default', 'large', 'xlarge', 'inherit']),
};

IconSpotify.defaultProps = {
  selected: false,
  fontSize: 'inherit',
};

const styles = () => ({
  root: {
    verticalAlign: 'sub',
  },
  xlarge: {
    fontSize: '3rem',
  },
});

export default withStyles(styles)(IconSpotify);