import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SvgIcon from "@material-ui/core/SvgIcon";
import {withStyles} from "@material-ui/core/styles";
import clsx from "clsx";

class IconEmail extends Component {
  render() {
    let { classes, selected, fontSize, ...other } = this.props;
    let fill1 = '#fff', fill2 = '#0071bc';
    if (selected) {
      fill1 = 'transparent';
      fill2 = '#fff';
    }
    let isFontSizeXLarge = false;
    if (fontSize === 'xlarge') {
      fontSize = 'inherit';
      isFontSizeXLarge = true;
    }
    return (
      <SvgIcon
        className={clsx(classes.root, isFontSizeXLarge && classes.xlarge)}
        viewBox="0 0 56 56"
        fontSize={fontSize}
        {...other}
      >
        <rect fill={fill1} x="3" y="9" width="50" height="38"/>
        <path
          fill={fill2}
          d="M50.5,7.75H5.5A4.51,4.51,0,0,0,1,12.25v31.5a4.51,4.51,0,0,0,4.5,4.5h45a4.51,4.51,0,0,0,4.5-4.5V12.25A4.51,4.51,0,0,0,50.5,7.75ZM5.5,10h45a2.44,2.44,0,0,1,.47.09c-3.9,3.57-16.82,15.39-21.34,19.46a2.25,2.25,0,0,1-3.26,0C21.85,25.48,8.93,13.66,5,10.09A2.44,2.44,0,0,1,5.5,10ZM3.25,43.75V12.25a2.14,2.14,0,0,1,.13-.62c3,2.73,12,11,17.84,16.29-5.83,5-14.85,13.55-17.85,16.42A2.29,2.29,0,0,1,3.25,43.75ZM50.5,46H5.5A2.19,2.19,0,0,1,5,45.9c3.11-3,12.18-11.56,17.91-16.46.74.68,1.42,1.3,2,1.78a4.49,4.49,0,0,0,6.28,0c.54-.48,1.22-1.1,2-1.78C38.83,34.34,47.9,42.94,51,45.9A2.19,2.19,0,0,1,50.5,46Zm2.25-2.25a2.29,2.29,0,0,1-.12.59c-3-2.87-12-11.42-17.85-16.42,5.85-5.32,14.86-13.56,17.84-16.29a2.14,2.14,0,0,1,.13.62Z"
        />
      </SvgIcon>
    );
  }
}

IconEmail.propTypes = {
  selected: PropTypes.bool,
  fontSize: PropTypes.oneOf(['small', 'default', 'large', 'xlarge', 'inherit']),
};

IconEmail.defaultProps = {
  selected: false,
  fontSize: 'inherit',
};

const styles = () => ({
  root: {
    verticalAlign: 'sub',
  },
  xlarge: {
    fontSize: '3rem',
  },
});

export default withStyles(styles)(IconEmail);