import React, {Component} from 'react';
import PropTypes from "prop-types";
import Highcharts from 'highcharts';
import HighchartsReact from "highcharts-react-official";

class GraphPie extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chartOptions: {
        chart: {
          type: 'pie',
          backgroundColor: 'transparent',
          spacing: [0, 0, 0, 0],
          margin: [0, 0, 0, 0],
          height: 106,
        },
        colors: ['#16b7ef', '#dfdfdf'],
        title: {text: null},
        tooltip: {enabled: false},
        legend: {enabled: false},
        plotOptions: {
          pie: {
            allowPointSelect: false,
            borderWidth: 0,
            dataLabels: {enabled: false},
            states: {hover: {enabled: false}},
          }
        },
        series: [{
          data: props.data
        }],
        credits: {enabled: false},
      }
    };
  }

  render() {
    let { chartOptions } = this.state;
    return (
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    );
  }
}

GraphPie.propTypes = {
  data: PropTypes.array.isRequired,
};

export default GraphPie;