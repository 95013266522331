import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Item from "./components/item";
import Grid from "@material-ui/core/Grid";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Hidden from "@material-ui/core/Hidden";
import {withStyles} from "@material-ui/core/styles";

class WidgetStripe extends Component {
  render() {
    let { classes, dataStripes } = this.props;
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    return (
      <div className={classes.root}>
        <Hidden mdUp>
          <Slider {...settings}>
            {dataStripes.map((data, index) =>
              <div key={index}>
                <Item key={index} text={data.text} highlight={data.highlight} url={data.url} />
              </div>
            )}
          </Slider>
        </Hidden>

        <Hidden smDown>
          <Grid container alignItems="stretch" justify="space-between">
            {dataStripes.slice(0, 3).map((data, index) =>
              <Grid key={index} item>
                <Item text={data.text} highlight={data.highlight} url={data.url} />
              </Grid>
            )}
          </Grid>
          <Grid container alignItems="stretch" justify="space-between">
            {dataStripes.slice(3, 6).map((data, index) =>
              <Grid key={index} item>
                <Item text={data.text} highlight={data.highlight} url={data.url} />
              </Grid>
            )}
          </Grid>
        </Hidden>
      </div>
    );
  }
}

WidgetStripe.propTypes = {
  dataStripes: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    highlight: PropTypes.string.isRequired,
  })).isRequired,
};

const styles = theme => ({
  root: {
    marginBottom: theme.spacing(3),
  },
});

export default  withStyles(styles)(WidgetStripe);