import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid";
import Utils from "../../utils/utils";
import Share from "../share";
import {withRouter} from "react-router";
import Item from "../item";
import {withStyles} from "@material-ui/core/styles";

const anchor = 'sistemas-operativos';

class WidgetMobileOs extends Component {
  componentDidMount() {
    let script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://www.statcounter.com/js/fusioncharts.js";
    document.body.appendChild(script);

    script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://gs.statcounter.com/chart.php?mobile_os_combined-AR-monthly-201907-202007&chartWidth=100%";
    document.body.appendChild(script);

    let hash = this.props.location.hash.substr(1);
    if (anchor !== hash) {
      return;
    }
    Utils.scrollTo(hash);
  }

  render() {
    let { classes, text, highlight, sources } = this.props;
    return (
      <Fragment>
        <Grid container id={anchor}>
          <Grid item xs={10}>
            <Item text={text} highlight={highlight} sources={sources} />
          </Grid>
          <Grid item xs={2} align="right">
            <Share fragment={anchor} />
          </Grid>
        </Grid>

        <div className={classes.embedContainer}>
          <div
            id="mobile_os_combined-AR-monthly-201907-202007"
            width="100%"
            height="400"
            className={classes.embed}>
          </div>
        </div>
      </Fragment>
    );
  }
}

WidgetMobileOs.propTypes = {
  text: PropTypes.string.isRequired,
  highlight: PropTypes.string,
  sources: PropTypes.array,
};

const styles = theme => ({
  embedContainer: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  embed: {
    width: '100%',
    height: '400px',
  }
});

export default withRouter(withStyles(styles)(WidgetMobileOs));