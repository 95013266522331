import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Typography} from "@material-ui/core";
import Link from "@material-ui/core/Link";

class Source extends Component {
  render() {
    let { text, url } = this.props;
    return (
      <Link href={url} display="block" target="_blank" rel="noopener" color="inherit" variant="caption">
        <Typography variant="caption">{text}</Typography>
      </Link>
    );
  }
}

Source.propTypes = {
  text: PropTypes.string.isRequired,
  fontSize: PropTypes.oneOf(['small', 'default', 'large', 'xlarge', 'inherit']),
};

export default Source;