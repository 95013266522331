import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactGA from 'react-ga'
import { Route } from 'react-router-dom'

class GoogleAnalytics extends Component {
  componentDidMount () {
    this.logPageChange(
      this.props.location.pathname,
      this.props.location.search,
      this.props.location.hash
    )
  }

  componentDidUpdate ({ location: prevLocation }) {
    const { location: { pathname, search, hash } } = this.props
    const isDifferentPathname = pathname !== prevLocation.pathname
    const isDifferentSearch = search !== prevLocation.search
    const isDifferentHash = search !== prevLocation.hash

    if (isDifferentPathname || isDifferentSearch || isDifferentHash) {
      this.logPageChange(pathname, search, hash)
    }
  }

  logPageChange (pathname, search = '', hash = '') {
    const page = pathname + search + hash;
    const { location } = window
    ReactGA.set({
      page,
      location: `${location.origin}${page}`,
      ...this.props.options
    })
    ReactGA.pageview(page)
  }

  render () {
    return null
  }
}

GoogleAnalytics.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string
  }).isRequired,
  options: PropTypes.object
}

const RouteTracker = () => <Route component={GoogleAnalytics} />

const init = (options = {}) => {
  ReactGA.initialize(
    "UA-49424637-3", {...options}
  )

  return true
}

export default {
  GoogleAnalytics,
  RouteTracker,
  init
}