import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Item from "../../../item";
import GraphPie from "../graph-pie";
import GraphIcon from "../graph-icon";

class Element extends Component {
  render() {
    let { text, highlight, sources, graph, data } = this.props;
    return (
      <Fragment>
        <Item text={text} highlight={highlight} sources={sources} />
        <div align="center">
          {graph === 'icon' && <GraphIcon data={data} />}
          {graph === 'pie' && <GraphPie data={data} />}
        </div>
      </Fragment>
    );
  }
}

Element.propTypes = {
  text: PropTypes.string.isRequired,
  highlight: PropTypes.string,
  sources: PropTypes.array,
  graph: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
}

export default Element;