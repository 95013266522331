import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SvgIcon from "@material-ui/core/SvgIcon";
import {withStyles} from "@material-ui/core/styles";
import clsx from "clsx";

class IconHealth extends Component {
  render() {
    let { classes, fontSize, ...other } = this.props;
    let isFontSizeXLarge = false;
    if (fontSize === 'xlarge') {
      fontSize = 'inherit';
      isFontSizeXLarge = true;
    }
    return (
      <SvgIcon
        className={clsx(classes.root, isFontSizeXLarge && classes.xlarge)}
        viewBox="0 0 62 47"
        fontSize={fontSize}
        {...other}
      >
        <path fill="#16b7ef"
              d="M24.56,40H36.43V31h9V19.1h-9v-9H24.56v9h-9V31h9Zm9.82-2.05H26.61v-9h-9V21.15h9v-9h7.77v9h9v7.77h-9Z"/>
      </SvgIcon>
    );
  }
}

IconHealth.propTypes = {
  fontSize: PropTypes.oneOf(['small', 'default', 'large', 'xlarge', 'inherit']),
};

IconHealth.defaultProps = {
  fontSize: 'inherit',
};

const styles = () => ({
  root: {
    verticalAlign: 'sub',
  },
  xlarge: {
    fontSize: '3rem',
  },
});

export default withStyles(styles)(IconHealth);