import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SvgIcon from "@material-ui/core/SvgIcon";
import {withStyles} from "@material-ui/core/styles";
import clsx from "clsx";

class IconWifi extends Component {
  render() {
    let { classes, fontSize, color, className, ...other } = this.props;
    let isFontSizeXLarge = false;
    if (fontSize === 'xlarge') {
      fontSize = 'inherit';
      isFontSizeXLarge = true;
    }
    let fill = '#f2f2f2';
    if (color) {
      fill = color;
    }
    return (
      <SvgIcon
        className={clsx(classes.root, isFontSizeXLarge && classes.xlarge, className && className)}
        viewBox="0 0 63 49"
        fontSize={fontSize}
        {...other}
      >
        <path
          fill={fill}
          d="M21.19,16.7A14.71,14.71,0,0,1,42,16.7a.86.86,0,0,0,.6.24.84.84,0,0,0,.6-1.44A16.39,16.39,0,0,0,20,15.5a.85.85,0,1,0,1.2,1.2Z"
        />
        <path
          fill={fill}
          d="M40,19.91a.85.85,0,0,0,0-1.2,11.86,11.86,0,0,0-16.73,0,.85.85,0,0,0,.59,1.46.73.73,0,0,0,.38-.09.69.69,0,0,0,.22-.16,10.16,10.16,0,0,1,14.34,0A.83.83,0,0,0,40,19.91Z"
        />
        <path
          fill={fill}
          d="M36.19,23.32a.82.82,0,0,0,.6-.25.83.83,0,0,0,0-1.2,7.36,7.36,0,0,0-10.42,0,.85.85,0,0,0,1.2,1.2,5.67,5.67,0,0,1,8,0A.85.85,0,0,0,36.19,23.32Z"
        />
        <path
          fill={fill}
          d="M29.14,24.63a.83.83,0,0,0-.26.61.86.86,0,0,0,1.46.59,1.76,1.76,0,0,1,2.49,0,.85.85,0,0,0,1.2-1.2,3.47,3.47,0,0,0-4.89,0Z"
        />
        <path
          fill={fill}
          d="M57.38,33.62h-3.5L52.94,5.06a2.41,2.41,0,0,0-4.81,0l-.94,28.56H41.05a.85.85,0,1,0,0,1.69H57.38a1.42,1.42,0,0,1,1.41,1.42v7a1.42,1.42,0,0,1-1.41,1.42H21.75a.85.85,0,0,0,0,1.7H57.38a3.12,3.12,0,0,0,3.11-3.12v-7a3.11,3.11,0,0,0-3.11-3.11ZM49.83,5.12a.71.71,0,0,1,1.41,0l.94,28.5H48.89Z"
        />
        <path fill={fill} d="M9.25,41.21a.85.85,0,1,0,0,1.7H12a.85.85,0,0,0,0-1.7Z"/>
        <path fill={fill} d="M18.36,41.21H15.65a.85.85,0,0,0,0,1.7h2.71a.85.85,0,1,0,0-1.7Z"/>
        <path fill={fill} d="M22.05,41.21a.85.85,0,0,0,0,1.7h2.71a.85.85,0,0,0,0-1.7Z"/>
        <path fill={fill} d="M28.45,41.21a.85.85,0,0,0,0,1.7h2.71a.85.85,0,0,0,0-1.7Z"/>
        <path fill={fill} d="M47.63,42.91a.85.85,0,1,0,0-1.7H44.92a.85.85,0,1,0,0,1.7Z"/>
        <path fill={fill} d="M54,42.91a.85.85,0,0,0,0-1.7H51.23a.85.85,0,0,0,0,1.7Z"/>
        <path
          fill={fill}
          d="M42.66,33.62H16L15,5.06a2.4,2.4,0,0,0-4.8,0l-.43,13-.11,3.4L9.28,33.62H5.78a3.11,3.11,0,0,0-3.11,3.11v7a3.12,3.12,0,0,0,3.11,3.12H24.36a.85.85,0,0,0,0-1.7H5.78a1.42,1.42,0,0,1-1.41-1.42v-7a1.42,1.42,0,0,1,1.41-1.42H42.66a.85.85,0,0,0,0-1.69ZM11,33.62l.94-28.5a.71.71,0,0,1,1.41,0l.94,28.5Z"
        />
      </SvgIcon>
    );
  }
}

IconWifi.propTypes = {
  fontSize: PropTypes.oneOf(['small', 'default', 'large', 'xlarge', 'inherit']),
  color: PropTypes.string,
  className: PropTypes.string,
};

IconWifi.defaultProps = {
  fontSize: 'inherit',
};

const styles = () => ({
  root: {
    verticalAlign: 'bottom',
  },
  xlarge: {
    fontSize: '3rem',
  },
});

export default withStyles(styles)(IconWifi);