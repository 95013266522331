import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Typography} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import LogoN3ctar from "../../components/logo-n3ctar/logo-n3ctar";

class PageCommingSoon extends Component {
  render() {
    let { classes } = this.props;
    return (
      <div style={{height: '65vh'}} align="center">
        <Typography variant="h5" component="h1" className={classes.commingNext}>En construcción</Typography>
        <br/>
        <br/>
        <Typography variant="body2" component="h1">Estamos procesando los datos de esta sección</Typography>
        <LogoN3ctar />
      </div>
    );
  }
}

PageCommingSoon.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = theme => ({
  commingNext: {
    textTransform: 'uppercase',
    color: theme.palette.primary.dark,
  }
});

export default withStyles(styles)(PageCommingSoon);