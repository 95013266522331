import React, {Component, Fragment} from 'react';
import PropTypes from "prop-types";
import IconPhoneUser from "../../../../icons/icon-phone-user";

class GraphIcon extends Component {
  render() {
    let { data } = this.props;
    let withPhone = new Array(data[0]).fill(true);
    let withoutPhone = new Array(data[1]).fill(false);
    return (
      <Fragment>
        <div>
          {withPhone.slice(0, 5).map((el, index) =>
            <IconPhoneUser key={index} withPhone={true} display="inline-block" fontSize="xlarge" />
          )}
        </div>
        <div>
          {withPhone.slice(5).map((el, index) =>
            <IconPhoneUser key={index} withPhone={true} display="inline-block" fontSize="xlarge" />
          )}
          {withoutPhone.map((el, index) =>
            <IconPhoneUser key={index} withPhone={false} display="inline-block" fontSize="xlarge" />
          )}
        </div>
      </Fragment>
    );
  }
}

GraphIcon.propTypes = {
  data: PropTypes.array.isRequired,
};

export default GraphIcon;