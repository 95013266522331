import React, {Component} from 'react';
import PropTypes from "prop-types";
import Title from "../../components/title";
import {withStyles} from "@material-ui/core/styles";
import WidgetAbout from "../../components/widget-about";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import WidgetTeam from "../../components/widget-team";
import Hidden from "@material-ui/core/Hidden";

class PageAbout extends Component {
  render() {
    let { classes, data } = this.props;
    return (
      <div className={classes.root}>
        <Title title={data.title} />
        <br/>
        <br/>
        <div className={classes.section}>
          <Grid container justify="space-between">
            <Grid item xs={12} md={5}>
              <WidgetAbout
               title={data.project.title}
               logo={data.project.logo}
               texts={data.project.texts}
              />
              <Hidden mdUp>
                <br/>
                <br/>
              </Hidden>
            </Grid>
            <Grid item xs={12} md={5}>
              <WidgetAbout
                title={data.alliances.title}
                logo={data.alliances.logo}
                texts={data.alliances.texts}
              />
            </Grid>
          </Grid>
        </div>
        <div className={classes.section}>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </div>
        <div className={classes.section}>
          <WidgetTeam
            title={data.team.title}
            people={data.team.people}
          />
        </div>
      </div>
    );
  }
}

PageAbout.propTypes = {
  data: PropTypes.shape({
    project: PropTypes.shape({
      text: PropTypes.string.isRequired,
      logo: PropTypes.string.isRequired,
      texts: PropTypes.array,
    }).isRequired,
    alliances: PropTypes.shape({
      text: PropTypes.string.isRequired,
      logo: PropTypes.string.isRequired,
      texts: PropTypes.array,
    }).isRequired,
    team: PropTypes.shape({
      title: PropTypes.string.isRequired,
      people: PropTypes.array,
    }).isRequired,
  }).isRequired,
};

const styles = theme => ({
  root: {
    backgroundColor: '#f2f2f2',
    marginLeft: theme.spacing(-8),
    marginRight: theme.spacing(-8),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(-2),
      marginRight: theme.spacing(-2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  section: {
    paddingBottom: theme.spacing(4),
  }
});

export default withStyles(styles)(PageAbout);