import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Utils from "../../../../utils/utils";
import {Typography} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import IconPlus from "../../../../icons/icon-plus";
import {Link} from "react-router-dom";
import clsx from "clsx";

class Item extends Component {
  constructor(props) {
    super(props);

    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);

    this.state = {
      hover: false,
    }
  }

  onMouseEnter() {
    this.setState({hover: true});
  }

  onMouseLeave() {
    this.setState({hover: false});
  }

  render() {
    let { hover } = this.state;
    let { classes, text, highlight, url } = this.props;
    let textBeforeHighlight = Utils.getTextBeforeHighlight(text, highlight);
    let textAfterHighlight = Utils.getTextAfterHighlight(text, highlight);
    return (
      <Link to={url} className={classes.rootLink}>
        <Grid
          container
          alignItems="center"
          className={clsx(classes.root, hover && classes.rootHover)}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
        >
          <Grid item xs={12} container className={clsx(classes.container, hover && classes.containerHover)}>
            <Grid item xs={12} md={11}>
              <Typography variant="body1" className={classes.text}>
                {textBeforeHighlight} <span className={classes.textHighlight}>{highlight}</span> {textAfterHighlight}
              </Typography>
            </Grid>
            <Grid item xs={12} md={1}>
              <IconPlus className={clsx(classes.icon, hover && classes.iconHover)} />
            </Grid>
          </Grid>
        </Grid>
      </Link>
    );
  }
}

Item.propTypes = {
  text: PropTypes.string.isRequired,
  highlight: PropTypes.string,
  url: PropTypes.string.isRequired,
};

const styles = theme => ({
  root: {
    width: '300px',
    maxWidth: '100%',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    }
  },
  rootLink: {
    color: theme.palette.common.black,
    textDecoration: 'none',
  },
  container: {
    marginTop: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(0.5),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(0.5),
    borderBottom: '1px solid #f2f2f2',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      borderBottom: 'none',
    },
  },
  containerHover: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  icon: {
    borderRadius: '50%',
    marginRight: theme.spacing(0.25),
    padding: theme.spacing(0.25),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(1),
    },
  },
  iconHover: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  text: {
    whiteSpace: 'pre-wrap',
  },
  textHover: {
    color: theme.palette.common.white,
  },
  textHighlight: {
    fontWeight: theme.typography.fontWeightBold,
    whiteSpace: 'pre-wrap',
  },
})

export default withStyles(styles)(Item);