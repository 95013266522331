import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SvgIcon from "@material-ui/core/SvgIcon";
import {withStyles} from "@material-ui/core/styles";
import clsx from "clsx";

class IconiOS extends Component {
  render() {
    let { classes, selected, fontSize, ...other } = this.props;
    let fill1 = '#000';
    if (selected) {
      fill1 = '#fff';
    }
    let isFontSizeXLarge = false;
    if (fontSize === 'xlarge') {
      fontSize = 'inherit';
      isFontSizeXLarge = true;
    }
    return (
      <SvgIcon
        className={clsx(classes.root, isFontSizeXLarge && classes.xlarge)}
        viewBox="0 0 56 56"
        fontSize={fontSize}
        {...other}
      >
        <path
          fill={fill1}
          d="M20.69,52.45C13.15,52.41,6.92,37,6.92,29.19c0-12.8,9.6-15.61,13.3-15.61a15.29,15.29,0,0,1,5,1.24,11.33,11.33,0,0,0,2.87.82A9,9,0,0,0,30.16,15a16.67,16.67,0,0,1,6.2-1.49h0c1.82,0,7.32.39,10.63,5.36L47.78,20l-1.12.84c-1.59,1.2-4.5,3.4-4.5,7.74A8.72,8.72,0,0,0,47,36.67c.69.42,1.42.85,1.42,1.8,0,.62-4.94,13.9-12.11,13.9a9.63,9.63,0,0,1-4.09-1,8.31,8.31,0,0,0-3.64-.88,9.39,9.39,0,0,0-2.88.78,14,14,0,0,1-5,1.17Z"
        />
        <path
          fill={fill1}
          d="M37.12,1.81c.19,6.73-4.63,11.4-9.44,11.11C26.89,7.55,32.49,1.81,37.12,1.81Z"
        />
      </SvgIcon>
    );
  }
}

IconiOS.propTypes = {
  selected: PropTypes.bool,
  fontSize: PropTypes.oneOf(['small', 'default', 'large', 'xlarge', 'inherit']),
};

IconiOS.defaultProps = {
  selected: false,
  fontSize: 'inherit',
};

const styles = () => ({
  root: {
    verticalAlign: 'sub',
  },
  xlarge: {
    fontSize: '3rem',
  },
});

export default withStyles(styles)(IconiOS);