import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SvgIcon from "@material-ui/core/SvgIcon";
import {withStyles} from "@material-ui/core/styles";
import clsx from "clsx";

class IconTinder extends Component {
  render() {
    let { classes, selected, fontSize, ...other } = this.props;
    let fill1 = 'url(#linear_gradient_1)', fill2 = 'transparent';
    if (selected) {
      fill1 = '#fff';
      fill2 = 'transparent';
    }
    let isFontSizeXLarge = false;
    if (fontSize === 'xlarge') {
      fontSize = 'inherit';
      isFontSizeXLarge = true;
      fill2 = '#f2f2f2';
    }
    return (
      <SvgIcon
        className={clsx(classes.root, isFontSizeXLarge && classes.xlarge)}
        viewBox="0 0 56 56"
        fontSize={fontSize}
        {...other}
      >
        <defs>
          <linearGradient id="linear_gradient_1" x1="-58.29" y1="459.27" x2="-57.38" y2="461.05"
                          gradientTransform="matrix(28, 0, 0, -32.72, 1647, 15090.29)" gradientUnits="userSpaceOnUse">
            <stop offset="0" stopColor="#ff7854"/>
            <stop offset="1" stopColor="#fd267d"/>
          </linearGradient>
        </defs>
        <path
          fill={fill1}
          d="M18.76,22.82c10.4-3.3,12-12.87,10.73-21.46,0,0,0-.49.33-.33C39.88,6,51.1,16.38,51.1,32.39A22.64,22.64,0,0,1,28,55C13.15,55,4.9,44.6,4.9,32.23c0-7.43,4.95-14.86,10.72-18.16,0,0,.5,0,.5.33,0,1.65.66,5.78,2.47,8.25Z"
        />
        <path
          fill={fill2}
          d="M15.62,14.07s.5,0,.5.33c0,1.65.66,5.78,2.47,8.25l.17.17c10.4-3.3,12-12.87,10.73-21.46a.7.7,0,0,1,.05-.25L29.49,1c-1.64,2.45-9.33,9-15.22,13.94C14.71,14.63,15.16,14.33,15.62,14.07Z"
        />
      </SvgIcon>
    );
  }
}

IconTinder.propTypes = {
  selected: PropTypes.bool,
  fontSize: PropTypes.oneOf(['small', 'default', 'large', 'xlarge', 'inherit']),
};

IconTinder.defaultProps = {
  selected: false,
  fontSize: 'inherit',
};

const styles = () => ({
  root: {
    verticalAlign: 'sub',
  },
  xlarge: {
    fontSize: '3rem',
  },
});

export default withStyles(styles)(IconTinder);