import React, {Component} from 'react';
import PropTypes from "prop-types";
import Utils from "../../../../utils/utils";
import {withStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";
import Source from "../../../source";

class ItemAlternative extends Component {
  render() {
    let { classes, text, highlight, sources } = this.props;
    let textBeforeHighlight = Utils.getTextBeforeHighlight(text, highlight);
    let textAfterHighlight = Utils.getTextAfterHighlight(text, highlight);
    return (
      <div className={classes.root}>
        <div className={classes.containerIcon}>
          <Typography className={classes.reference}>*</Typography>
        </div>
        <div className={classes.containerText}>
          <Typography variant="body2" className={classes.text}>
            {textBeforeHighlight} <span className={classes.highlight}>{highlight}</span> {textAfterHighlight}
          </Typography>
          {sources.map((source, index) =>
            <Source key={index} text={source.text} url={source.url} />
          )}
        </div>
      </div>
    );
  }
}

ItemAlternative.propTypes = {
  text: PropTypes.string.isRequired,
  highlight: PropTypes.string.isRequired,
  sources: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  })),
};

ItemAlternative.defaultProps = {
  sources: [],
};

const styles = theme => ({
  root: {
    backgroundColor: '#e6e6e6',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(3),
      marginTop: theme.spacing(-2),
    },
  },
  containerIcon: {
    float: 'left',
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.main,
  },
  containerText: {
    paddingLeft: theme.spacing(1),
  },
  highlight: {
    fontSize: '0.9rem',
    fontWeight: theme.typography.fontWeightBold,
  },
  text: {
    whiteSpace: 'pre-wrap',
  },
});

export default withStyles(styles)(ItemAlternative);