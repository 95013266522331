import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import FooterEmbed from "../../components/footer-embed";

class LayoutEmbed extends Component {
  componentDidMount() {
    document.body.classList.add('embed');
  }

  render() {
    let { page } = this.props;
    let PageComponent = page.component;
    return (
      <Fragment>
        <PageComponent {...page.props} />
        <FooterEmbed />
      </Fragment>
    );
  }
}

LayoutEmbed.propTypes = {
  page: PropTypes.object.isRequired,
};

export default LayoutEmbed;