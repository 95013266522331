import React, {Component} from 'react';
import PropTypes from "prop-types";
import Highcharts from 'highcharts';
import HighchartsReact from "highcharts-react-official";
import Utils from "../../../../utils/utils";

class Graph extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chartOptions: {
        chart: {
          type: 'line',
          backgroundColor: 'transparent',
          spacingTop: 32,
        },
        colors: ['#cccccc'],
        title: {text: null},
        xAxis: {
          crosshair: {
            width: 2,
            color: '#e6e6e6',
          },
          gridLineWidth: 0,
          lineWidth: 1,
          lineColor: '#cccccc',
          title: {
            text: 'TRIMESTRES',
            margin: 20,
            style: {color: '#16b7ef', fontWeight: 700}
          },
          labels: {autoRotation: false},
          categories: props.categories,
        },
        yAxis: {
          gridLineWidth: 1,
          gridLineColor: '#cccccc',
          lineWidth: 1,
          lineColor: '#cccccc',
          tickInterval: 10,
          margin: 8,
          min: Math.min.apply(null, props.data) - 10,
          title: {
            text: 'ACCESOS POR <br/>CADA 100 HABITANTES',
            align: 'high',
            rotation: 0,
            style: {color: '#16b7ef', fontWeight: 700},
          },
        },
        tooltip: {
          backgroundColor: '#fff',
          borderColor: '#16b7ef',
          borderRadius: 0,
          borderWidth: 2,
          distance: 16,
          outside: true,
          shadow: false,
          useHTML: true,
          valueDecimals: 2,
          formatter: function() {
            return '' +
              '<div class="MuiTypography-body2" style="padding-right: 8px; padding-bottom: 4px; vertical-align: top;">' +
                '<b>' + this.x + '</b>' +
              '</div>' +
              '<div class="MuiTypography-body2" style="vertical-align: top;">' +
                'Accesos cada 100<br/>habitantes<br/>' +
                '<b style="font-size: 0.9rem; color: #16b7ef;">' + Utils.formatNumber(this.y) + '</b>' +
              '</div>';
          },
        },
        legend: {enabled: false},
        plotOptions: {
          line: {
            marker: {
              enabled: false,
              states: {
                hover: {
                  lineWidth: 2,
                  lineColor: '#16b7ef',
                  fillColor: '#fff',
                  radiusPlus: 4,
                },
              },
            },
          }
        },
        series: [{
          data: props.data
        }],
        credits: {enabled: false},
        responsive: {
          rules: [{
            condition: {maxWidth: 960},
            chartOptions: {
              chart: {
                spacingLeft: 0,
                spacingTop: 64,
              },
              yAxis: {
                title: {
                  textAlign: 'left',
                  y: -32,
                  x: -138,
                  offset: -138,
                }
              }
            },
          }],
        },
      }
    };
  }

  render() {
    let { chartOptions } = this.state;
    return (
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    );
  }
}

Graph.propTypes = {
  categories: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
};

export default Graph;