import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SvgIcon from "@material-ui/core/SvgIcon";
import {withStyles} from "@material-ui/core/styles";
import clsx from "clsx";

class IconMobile extends Component {
  render() {
    let { classes, fontSize, ...other } = this.props;
    let isFontSizeXLarge = false;
    if (fontSize === 'xlarge') {
      fontSize = 'inherit';
      isFontSizeXLarge = true;
    }
    return (
      <SvgIcon
        className={clsx(classes.root, isFontSizeXLarge && classes.xlarge)}
        viewBox="0 0 56 56"
        fontSize={fontSize}
        {...other}
      >
        <circle fill="#fff" cx="28" cy="28.18" r="26.18"/>
        <circle fill="#16b7ef" cx="27.94" cy="28.07" r="26.18"/>
        <path fill="#fff"
              d="M35.19,46.75H20.12a2.86,2.86,0,0,1-2.86-2.86v-32a2.87,2.87,0,0,1,2.86-2.86H35.19a2.87,2.87,0,0,1,2.86,2.86v32A2.86,2.86,0,0,1,35.19,46.75ZM20.12,10a2,2,0,0,0-2,2v32a2,2,0,0,0,2,2H35.19a2,2,0,0,0,2-2v-32a2,2,0,0,0-2-2Z"/>
        <path fill="#fff"
              d="M35,39.51H20.3a1,1,0,0,1-1-1V12.75a1,1,0,0,1,1-1H35a1,1,0,0,1,1,1v25.8A1,1,0,0,1,35,39.51ZM20.3,12.68a.08.08,0,0,0-.07.07v25.8a.08.08,0,0,0,.07.07H35a.07.07,0,0,0,.07-.07V12.75a.07.07,0,0,0-.07-.07Z"/>
        <path fill="#fff"
              d="M27.65,44.58a2.16,2.16,0,1,1,2.16-2.16A2.16,2.16,0,0,1,27.65,44.58Zm0-3.42a1.27,1.27,0,1,0,1.27,1.26A1.27,1.27,0,0,0,27.65,41.16Z"/>
        <circle fill="#fff" cx="35.45" cy="20.5" r="8"/>
        <polyline fill="none" style={{stroke: '#16b7ef', strokeMiterlimit: 10}} points="35.95 15.5 35.95 20.23 38.63 18.45"/>
      </SvgIcon>
    );
  }
}

IconMobile.propTypes = {
  fontSize: PropTypes.oneOf(['small', 'default', 'large', 'xlarge', 'inherit']),
};

IconMobile.defaultProps = {
  fontSize: 'inherit',
};

const styles = () => ({
  root: {
    verticalAlign: 'sub',
  },
  xlarge: {
    fontSize: '3rem',
  },
});

export default withStyles(styles)(IconMobile);