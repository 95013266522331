import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {Typography} from "@material-ui/core";
import Utils from "../../utils/utils";
import Source from "../source";

class Highlight extends Component {
  render() {
    let { classes, text, highlight, icon, sources } = this.props;
    let textBeforeHighlight = Utils.getTextBeforeHighlight(text, highlight);
    let textAfterHighlight = Utils.getTextAfterHighlight(text, highlight);
    let IconComponent = Utils.getIconComponentFromSlug(icon);
    return (
      <div className={classes.root}>
        <div className={classes.containerIcon}>
          <IconComponent fontSize="xlarge" />
        </div>
        <div className={classes.containerText}>
          <Typography component="p" variant="h6" className={classes.text}>
            {textBeforeHighlight} <span className={classes.highlight}>{highlight}</span> {textAfterHighlight}
          </Typography>
          {sources.map((source, index) =>
            <Source key={index} text={source.text} url={source.url} />
          )}
        </div>
      </div>
    );
  }
}

Highlight.propTypes = {
  text: PropTypes.string.isRequired,
  highlight: PropTypes.string,
  icon: PropTypes.string.isRequired,
  sources: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  })),
};

Highlight.defaultProps = {
  sources: [],
};

const styles = theme => ({
  root: {

  },
  containerIcon: {
    float: 'left',
    marginTop: theme.spacing(-1),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(0),
    },
  },
  containerText: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(4),
    marginBottom: theme.spacing(3),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(0.75),
    paddingTop: theme.spacing(0.75),
    backgroundColor: theme.palette.common.white,
    borderTopRightRadius: theme.spacing(3),
    borderBottomRightRadius: theme.spacing(3),
  },
  text: {
    color: theme.palette.primary.light,
  },
  highlight: {
    fontWeight: theme.typography.fontWeightBold,
  },
});

export default withStyles(styles)(Highlight);