import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from "react-router";
import {withStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";
import Link from "@material-ui/core/Link";

class WidgetTeam extends Component {
  render() {
    let { classes, title, people } = this.props;
    return (
      <div className={classes.root}>
        <Typography className={classes.title}>{title}</Typography>
        {people.map((p, index) =>
          <Typography key={index} variant="body2">
            <span className={classes.highlight}>
              <Link href={p.url} target="_blank"> {p.name} </Link>
            </span> - {p.role}
          </Typography>
        )}
      </div>
    );
  }
}

WidgetTeam.propTypes = {
  title: PropTypes.string.isRequired,
  people: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  })).isRequired,
};

const styles = theme => ({
  root: {
    paddingLeft: theme.spacing(3),
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    paddingBottom: theme.spacing(2),
  },
  highlight: {
    fontWeight: theme.typography.fontWeightBold,
  },
});

export default withRouter(withStyles(styles)(WidgetTeam));