import React, {Component, Fragment} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from "highcharts-react-official";
import mapData from "./mapData";
import PropTypes from "prop-types";

// Load Highcharts modules
require('highcharts/modules/map')(Highcharts);

class Graph extends Component {
  constructor(props) {
    super(props);

    this.updateChartOptions = this.updateChartOptions.bind(this);

    this.state = {
      chartOptions: null,
    };
  }

  updateChartOptions(onChangeRegion, activeRegion, mobile) {
    let chartOptions = {
      title: '',
      chart: {
        type: 'map',
        backgroundColor: 'transparent',
        spacingTop: 0,
      },
      plotOptions: {
        map: {
          allAreas: false,
          joinBy: ['code', 'code'],
          states: {hover: {enabled: false}},
        },
      },
      tooltip: {enabled: false},
      legend: {enabled: false},
      series: [{
        mapData: mapData,
        name: 'NOROESTE',
        data: ['AR-Y', 'AR-A', 'AR-T', 'AR-G', 'AR-F', 'AR-K'].map(code => ({code: code})),
        color: '#16b7ef',
        events: {
          click: () => onChangeRegion('noroeste'),
        },
        selected: activeRegion === 'noroeste',
        opacity: (!mobile || activeRegion === 'noroeste') ? 1 : 0.4,
      }, {
        mapData: mapData,
        name: 'NORESTE',
        data: ['AR-P', 'AR-N', 'AR-E', 'AR-W', 'AR-H'].map(code => ({code: code})),
        color: '#7fb832',
        events: {
          click: () => onChangeRegion('noreste'),
        },
        selected: activeRegion === 'noreste',
        opacity: (!mobile || activeRegion === 'noreste') ? 1 : 0.4,
      }, {
        mapData: mapData,
        name: 'CUYO',
        data: ['AR-M', 'AR-D', 'AR-J'].map(code => ({code: code})),
        color: '#fbb300',
        events: {
          click: () => onChangeRegion('cuyo'),
        },
        selected: activeRegion === 'cuyo',
        opacity: (!mobile || activeRegion === 'cuyo') ? 1 : 0.4,
      }, {
        mapData: mapData,
        name: 'PAMPEANA',
        data: ['AR-L', 'AR-S', 'AR-X', 'AR-B'].map(code => ({code: code})),
        color: '#5127ab',
        events: {
          click: () => onChangeRegion('pampeana'),
        },
        selected: activeRegion === 'pampeana',
        opacity: (!mobile || activeRegion === 'pampeana') ? 1 : 0.4,
      }, {
        mapData: mapData,
        name: 'PATAGONIA',
        data: ['AR-Q', 'AR-R', 'AR-U', 'AR-Z', 'AR-V'].map(code => ({code: code})),
        color: '#1e8675',
        events: {
          click: () => onChangeRegion('patagonia'),
        },
        selected: activeRegion === 'patagonia',
        opacity: (!mobile || activeRegion === 'patagonia') ? 1 : 0.4,
      }, {
        mapData: mapData,
        name: 'CABA',
        data: ['AR-CCC'].map(code => ({code: code})),
        color: '#db0052',
        events: {
          click: () => onChangeRegion('caba'),
        },
        selected: activeRegion === 'caba',
        opacity: (!mobile || activeRegion === 'caba') ? 1 : 0.4,
      }],
      credits: {enabled: false},
    };
    this.setState({chartOptions: chartOptions});
    return chartOptions;
  }

  componentDidMount() {
    this.updateChartOptions(this.props.onChangeRegion, this.props.activeRegion, this.props.mobile);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.activeRegion !== this.props.activeRegion) {
      this.updateChartOptions(this.props.onChangeRegion, this.props.activeRegion, this.props.mobile);
    }
  }

  render() {
    let { chartOptions } = this.state;
    return (
      <Fragment>
        {chartOptions &&
          <HighchartsReact highcharts={Highcharts} constructorType="mapChart" options={chartOptions} />
        }
      </Fragment>
    );
  }
}

Graph.propTypes = {
  activeRegion: PropTypes.string,
  mobile: PropTypes.bool,
  onChangeRegion: PropTypes.func,
};

Graph.defaultProps = {
  activeRegion: '',
  mobile: false,
  onChangeRegion: (region) => { },
};

export default Graph;