import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SvgIcon from "@material-ui/core/SvgIcon";
import {withStyles} from "@material-ui/core/styles";
import clsx from "clsx";

class IconGoogle extends Component {
  render() {
    let { classes, selected, fontSize, ...other } = this.props;
    let fill1 = 'transparent', fill2 = '#2196f3', fill3 = '#f44336', fill4 = '#4caf50', fill5 = '#ffc107';
    if (selected) {
      fill1 = 'transparent';
      fill2 = '#fff';
      fill3 = '#fff';
      fill4 = '#fff';
      fill5 = '#fff';
    }
    let isFontSizeXLarge = false;
    if (fontSize === 'xlarge') {
      fill1 = '#fff';
      fontSize = 'inherit';
      isFontSizeXLarge = true;
    }
    return (
      <SvgIcon
        className={clsx(classes.root, isFontSizeXLarge && classes.xlarge)}
        viewBox="0 0 56 56"
        fontSize={fontSize}
        {...other}
      >
        <circle fill={fill1} cx="28" cy="27" r="26"/>
        <path
          fill={fill2}
          d="M54.57,23a.44.44,0,0,1,.43.44V28a27.43,27.43,0,0,1-.46,5A27,27,0,1,1,46.77,8.6a.43.43,0,0,1,0,.62l-6.45,6.45a.44.44,0,0,1-.61,0A17,17,0,1,0,44.26,33H28.43a.44.44,0,0,1-.43-.44V23.44a.44.44,0,0,1,.43-.44Z"
        />
        <path
          fill={fill3}
          d="M12.56,20.88,4.36,15A27,27,0,0,1,46.77,8.6a.43.43,0,0,1,0,.62l-6.45,6.45a.44.44,0,0,1-.61,0,17,17,0,0,0-27.16,5.2Z"
        />
        <path
          fill={fill4}
          d="M47.81,46.35a27,27,0,0,1-44-6.27l8.37-5.74a17,17,0,0,0,27.39,6.09Z"
        />
        <path
          fill={fill5}
          d="M11,28a16.92,16.92,0,0,0,1.22,6.34L3.85,40.08A27,27,0,0,1,4.36,15l8.2,5.93A16.78,16.78,0,0,0,11,28Z"
        />
      </SvgIcon>
    );
  }
}

IconGoogle.propTypes = {
  selected: PropTypes.bool,
  fontSize: PropTypes.oneOf(['small', 'default', 'large', 'xlarge', 'inherit']),
};

IconGoogle.defaultProps = {
  selected: false,
  fontSize: 'inherit',
};

const styles = () => ({
  root: {
    verticalAlign: 'sub',
  },
  xlarge: {
    fontSize: '3rem',
  },
});

export default withStyles(styles)(IconGoogle);