import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid";
import Utils from "../../utils/utils";
import Hidden from "@material-ui/core/Hidden";
import Share from "../share";
import {withRouter} from "react-router";
import Slider from "react-slick";
import Box from "@material-ui/core/Box";
import Item from "../item";
import Categories from "./components/categories/categories";
import Apps from "./components/apps/apps";

const anchor = 'apps';

class WidgetMobileApps extends Component {
  componentDidMount() {
    let hash = this.props.location.hash.substr(1);
    if (anchor !== hash) {
      return;
    }
    Utils.scrollTo(hash);
  }

  render() {
    let { byCategory, byUse } = this.props;
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    return (
      <Fragment>
        <Grid container id={anchor}>
          <Hidden smDown>
            <Grid item xs={12} align="right">
              <Share fragment={anchor} />
            </Grid>
          </Hidden>
        </Grid>
        <br/>

        <Hidden mdUp>
          <Grid item xs={12}>
            <Slider {...settings}>
              <div>
                <Box position="relative" width="auto" zIndex="1" align="left">
                  <Box position="absolute" top={0} right={0}>
                    <Share fragment={anchor} />
                  </Box>
                </Box>
                <Item text={byCategory.text} highlight={byCategory.highlight} sources={byCategory.sources} />
                <Box pl={3} pr={3}>
                  <Categories data={byCategory.data} />
                </Box>
              </div>
              <div>
                <Box position="relative" width="auto" zIndex="1" align="left">
                  <Box position="absolute" top={0} right={0}>
                    <Share fragment={anchor} />
                  </Box>
                </Box>
                <Item text={byUse.text} highlight={byUse.highlight} sources={byUse.sources} />
                <Box pl={3} pr={3}>
                  <Grid container>
                    <Grid item xs={6}>
                      <Apps data={byUse.data.apps} title="apps" />
                    </Grid>
                    <Grid item xs={6}>
                      <Apps data={byUse.data.games} title="juegos" />
                    </Grid>
                  </Grid>
                </Box>
              </div>
            </Slider>
          </Grid>
        </Hidden>

        <Hidden smDown>
          <Grid item xs={12} container justify="space-between">
            <Grid item xs={6}>
              <Item text={byCategory.text} highlight={byCategory.highlight} sources={byCategory.sources} />
              <Box pl={3}>
                <Categories data={byCategory.data} />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Item text={byUse.text} highlight={byUse.highlight} sources={byUse.sources} />
              <Box pl={3}>
                <Grid container>
                  <Grid item xs={6}>
                    <Apps data={byUse.data.apps} title="apps" />
                  </Grid>
                  <Grid item xs={6}>
                    <Apps data={byUse.data.games} title="juegos" />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Hidden>

      </Fragment>
    );
  }
}

WidgetMobileApps.propTypes = {
  byCategory: PropTypes.shape({
    text: PropTypes.string.isRequired,
    highlight: PropTypes.string,
    sources: PropTypes.array,
    data: PropTypes.array.isRequired,
  }).isRequired,
  byUse: PropTypes.shape({
    text: PropTypes.string.isRequired,
    highlight: PropTypes.string,
    sources: PropTypes.array,
    data: PropTypes.object.isRequired,
  }).isRequired,
};

export default withRouter(WidgetMobileApps);