import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import SvgIcon from "@material-ui/core/SvgIcon";
import {withStyles} from "@material-ui/core/styles";
import clsx from "clsx";

class IconPhoneUser extends Component {
  render() {
    let { classes, fontSize, withPhone, ...other } = this.props;
    let isFontSizeXLarge = false;
    if (fontSize === 'xlarge') {
      fontSize = 'inherit';
      isFontSizeXLarge = true;
    }
    return (
      <SvgIcon
        className={clsx(classes.root, isFontSizeXLarge && classes.xlarge)}
        viewBox="0 0 34 62"
        fontSize={fontSize}
        {...other}
      >
        {withPhone &&
          <Fragment>
            <path fill="#16b7ef" d="M15.53,13.34a5.26,5.26,0,0,0,3.86-1.59A5.23,5.23,0,0,0,21,7.89,5.25,5.25,0,0,0,19.39,4a5.26,5.26,0,0,0-3.86-1.59A5.25,5.25,0,0,0,11.68,4a5.25,5.25,0,0,0-1.6,3.86,5.23,5.23,0,0,0,1.6,3.86A5.25,5.25,0,0,0,15.53,13.34Z" />
            <path fill="#16b7ef" d="M10.23,14.12a6,6,0,0,0-6,6V34.51a2.32,2.32,0,0,0,2.34,2.33,2.21,2.21,0,0,0,1.65-.68,2.22,2.22,0,0,0,.69-1.65V20.92h.67V57.59a2.75,2.75,0,0,0,2.73,2.72A2.75,2.75,0,0,0,15,57.59V36.84h.75V57.59a2.72,2.72,0,0,0,4.65,1.92,2.62,2.62,0,0,0,.81-1.92V20.92h.67V34.51a2.34,2.34,0,0,0,2.34,2.33,2.32,2.32,0,0,0,2.34-2.33V20.17a6,6,0,0,0-6-6Z"/>
            <rect fill="#fff" x="20.58" y="26.57" width="13.2" height="7.28" rx="1" transform="translate(-11.69 42.06) rotate(-64.97)"/>
            <path fill="#16b7ef" d="M24.71,35.5a.76.76,0,0,1,.64-1.37.76.76,0,1,1-.64,1.37Zm.51-1.08a.44.44,0,0,0-.59.21.44.44,0,0,0,.8.38A.44.44,0,0,0,25.22,34.42Z"/>
            <path fill="#16b7ef" d="M26.78,37.31,22,35.07a1,1,0,0,1-.48-1.33L26.25,23.6a1,1,0,0,1,1.33-.48l4.78,2.23a1,1,0,0,1,.49,1.33L28.11,36.82A1,1,0,0,1,26.78,37.31Zm.67-13.91a.7.7,0,0,0-.92.33L21.8,33.88a.69.69,0,0,0,.33.91L26.91,37a.68.68,0,0,0,.92-.33l4.74-10.14a.69.69,0,0,0-.34-.92Z"/>
            <path fill="#16b7ef" d="M27.79,35l-4.66-2.17a.34.34,0,0,1-.16-.45l3.82-8.18a.34.34,0,0,1,.45-.17l4.66,2.18a.34.34,0,0,1,.16.45l-3.82,8.18A.34.34,0,0,1,27.79,35ZM27.11,24.3s0,0,0,0l-3.82,8.18s0,0,0,0l4.67,2.18s0,0,0,0l3.82-8.18s0,0,0,0Z"/>
          </Fragment>
        }
        {!withPhone &&
          <Fragment>
            <path fill="#dfdfdf" d="M15.53,13.34a5.26,5.26,0,0,0,3.86-1.59A5.23,5.23,0,0,0,21,7.89,5.25,5.25,0,0,0,19.39,4a5.26,5.26,0,0,0-3.86-1.59A5.25,5.25,0,0,0,11.68,4a5.25,5.25,0,0,0-1.6,3.86,5.23,5.23,0,0,0,1.6,3.86A5.25,5.25,0,0,0,15.53,13.34Z"/>
            <path fill="#dfdfdf" d="M10.23,14.12a6,6,0,0,0-6,6V34.51a2.32,2.32,0,0,0,2.34,2.33,2.21,2.21,0,0,0,1.65-.68,2.22,2.22,0,0,0,.69-1.65V20.92h.67V57.59a2.75,2.75,0,0,0,2.73,2.72A2.75,2.75,0,0,0,15,57.59V36.84h.75V57.59a2.72,2.72,0,0,0,4.65,1.92,2.62,2.62,0,0,0,.81-1.92V20.92h.67V34.51a2.34,2.34,0,0,0,2.34,2.33,2.32,2.32,0,0,0,2.34-2.33V20.17a6,6,0,0,0-6-6Z"/>
          </Fragment>
        }
      </SvgIcon>
    );
  }
}

IconPhoneUser.propTypes = {
  fontSize: PropTypes.oneOf(['small', 'default', 'large', 'xlarge', 'inherit']),
  withPhone: PropTypes.bool,
};

IconPhoneUser.defaultProps = {
  fontSize: 'inherit',
  withPhone: true,
};

const styles = () => ({
  root: {
    verticalAlign: 'bottom',
  },
  xlarge: {
    fontSize: '3rem',
  },
});

export default withStyles(styles)(IconPhoneUser);