import React, {Component} from 'react';
import {ReactComponent as LogoSrc} from '../../images/logo_flacso.svg';
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";

class LogoFlacso extends Component {
  render() {
    let { classes, embed, ...other } = this.props;
    return (
      <Box className={classes.container} {...other}>
        <Link href="https://www.flacso.org.ar/" variant="inherit" color="inherit">
          <LogoSrc className={classes.logo} />
        </Link>
      </Box>
    );
  }
}

LogoFlacso.propTypes = {
  classes: PropTypes.object.isRequired,
  embed: PropTypes.bool,
};

LogoFlacso.defaultProps = {
  embed: false,
};

const styles = () => ({
  container: {
    verticalAlign: 'top',
  },
  logo: {
    height: '100%',
    maxHeight: '65px',
  },
});

export default withStyles(styles)(LogoFlacso);