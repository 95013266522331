import React, {Component, Fragment} from 'react';
import Utils from "../../../../utils/utils";

class TimeCount extends Component {
  constructor(props) {
    super(props);

    this.intervalFunction = this.intervalFunction.bind(this);

    this.state = {
      time: 0,
      intervalId: null,
    }
  }

  componentDidMount() {
    setTimeout(() => {
      let intervalId = setInterval(this.intervalFunction, 1000);
      this.setState({intervalId: intervalId});
    }, 1000);
  }

  intervalFunction() {
    this.setState((state) => ({ time: state.time + 1}));
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  render() {
    let { time } = this.state;
    return (
      <Fragment>
        {Utils.formatTime(time)}
      </Fragment>
    );
  }
}

export default TimeCount;