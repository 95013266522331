import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid";
import Share from "../share";
import Utils from "../../utils/utils";
import {withRouter} from "react-router";
import Highlight from "../highlight";
import Box from "@material-ui/core/Box";

const anchor = 'uso-promedio';

class WidgetAverageMobileUse extends Component {
  componentDidMount() {
    let hash = this.props.location.hash.substr(1);
    if (anchor !== hash) {
      return;
    }
    Utils.scrollTo(hash);
  }

  render() {
    let { text, highlight, icon, sources } = this.props;
    return (
      <Grid container id={anchor}>
        <Grid item xs={11} md={9} container>
          <Grid item xs={12} md={11}>
            <Box mb={-2}>
              <Highlight text={text} highlight={highlight} sources={sources} icon={icon} />
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={1} md={3} align="right">
          <Share fragment={anchor} />
        </Grid>
      </Grid>
    );
  }
}

WidgetAverageMobileUse.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  highlight: PropTypes.string,
  sources: PropTypes.array,
};

export default withRouter(WidgetAverageMobileUse);