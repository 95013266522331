import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";
import IconItem from "../../icons/icon-item";
import Utils from "../../utils/utils";
import Source from "../source";

class Item extends Component {
  render() {
    let { classes, text, highlight, sources } = this.props;
    let textBeforeHighlight = Utils.getTextBeforeHighlight(text, highlight);
    let textAfterHighlight = Utils.getTextAfterHighlight(text, highlight);
    return (
      <div className={classes.root}>
        <div className={classes.containerIcon}>
          <IconItem fontSize="small" />
        </div>
        <div className={classes.containerText}>
          <Typography>
            {textBeforeHighlight} <span className={classes.highlight}>{highlight}</span> {textAfterHighlight}
          </Typography>
          {sources.map((source, index) =>
            <Source key={index} text={source.text} url={source.url} />
          )}
        </div>
      </div>
    );
  }
}

Item.propTypes = {
  text: PropTypes.string.isRequired,
  highlight: PropTypes.string.isRequired,
  sources: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  })),
}

Item.defaultProps = {
  sources: [],
}

const styles = theme => ({
  root: {
    paddingBottom: theme.spacing(3),
  },
  containerIcon: {
    float: 'left',
    marginTop: theme.spacing(-0.25),
  },
  containerText: {
    paddingLeft: theme.spacing(3),
  },
  highlight: {
    fontWeight: theme.typography.fontWeightBold,
  },
});

export default withStyles(styles)(Item);