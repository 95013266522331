import React, {Component} from 'react';
import PropTypes from "prop-types";
import Highcharts from 'highcharts';
import HighchartsReact from "highcharts-react-official";
import Utils from "../../../../utils/utils";

class Graph extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chartOptions: {
        chart: {
          type: 'column',
          backgroundColor: 'transparent',
          spacingTop: 32,
        },
        colors: ['#cccccc'],
        title: {text: null},
        xAxis: {
          gridLineWidth: 0,
          lineWidth: 1,
          lineColor: '#cccccc',
          title: {
            text: 'TRIMESTRES',
            margin: 20,
            style: {color: '#16b7ef', fontWeight: 700}
          },
          labels: {autoRotation: false},
          categories: props.categories,
        },
        yAxis: {
          gridLineWidth: 1,
          gridLineColor: '#cccccc',
          lineWidth: 1,
          lineColor: '#cccccc',
          tickInterval: 5,
          title: {
            text: 'MBPS',
            align: 'high',
            rotation: 0,
            style: {color: '#16b7ef', fontWeight: 700},
          },
        },
        tooltip: {
          backgroundColor: '#fff',
          borderColor: '#16b7ef',
          borderRadius: 0,
          borderWidth: 2,
          distance: 16,
          outside: true,
          shadow: false,
          useHTML: true,
          valueDecimals: 2,
          formatter: function() {
            return '' +
              '<div class="MuiTypography-body2" style="padding-right: 8px; padding-bottom: 4px; vertical-align: top;">' +
                '<b>' + this.x.replace("T", "<br/>") + ' trimestre</b>' +
              '</div>' +
              '<div class="MuiTypography-body2" style="vertical-align: top;">' +
                'mbps:<br/>' +
                '<b style="font-size: 0.9rem; color: #16b7ef;">' + Utils.formatPercent(this.y, 2) + '</b>' +
              '</div>';
          },
        },
        legend: {enabled: false},
        plotOptions: {
          column: {
            borderColor: '#cccccc',
            states: {hover: {color: '#16b7ef', borderColor: '#16b7ef'}}
          }
        },
        series: [{
          data: props.data
        }],
        credits: {enabled: false},
      }
    };
  }

  render() {
    let { chartOptions } = this.state;
    return (
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    );
  }
}

Graph.propTypes = {
  categories: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
};

export default Graph;