import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from "@material-ui/core/styles";
import Item from "../item";
import Grid from "@material-ui/core/Grid";
import Graph from "./components/graph";
import {Typography} from "@material-ui/core";
import Utils from "../../utils/utils";
import Share from "../share";
import {withRouter} from "react-router";
import Hidden from "@material-ui/core/Hidden";

const anchor = 'dispositivos-por-region';

const colors = {
  NOROESTE: '#16b7ef',
  NORESTE: '#7fb832',
  CUYO: '#fbb300',
  PAMPEANA: '#5127ab',
  PATAGONIA: '#1e8675',
  CABA: '#db0052',
}

class WidgetInternetUsersByRegion extends Component {
  constructor(props) {
    super(props);

    this.onClickRegion = this.onClickRegion.bind(this);

    this.state = {
      activeRegion: 'noroeste',
    };
  }

  onClickRegion(region) {
    this.setState({activeRegion: region});
  }

  componentDidMount() {
    let hash = this.props.location.hash.substr(1);
    if (anchor !== hash) {
      return;
    }
    Utils.scrollTo(hash);
  }

  render() {
    let { activeRegion } = this.state;
    let { classes, text, highlight, sources, data } = this.props;
    let dataProcessed = data.map(d => {
      let values = d.values.map(v => {
        let IconComponent = Utils.getIconComponentFromSlug(v.icon);
        return {text: v.text, icon: IconComponent, value: v.value};
      })
      return {text: d.text, values: values};
    });
    return (
      <Grid container id={anchor}>
        <Grid item xs={10}>
          <Item text={text} highlight={highlight} sources={sources} />
        </Grid>
        <Grid item xs={2} align="right">
          <Share fragment={anchor} />
        </Grid>

        <Hidden smDown>
          <Grid item xs={12} container justify="space-evenly" alignItems="center">
            <Grid item xs={3}>
              {dataProcessed.slice(0, 3).map((d, index) =>
                <div key={index} className={classes.dataContainer}>
                  <Typography variant="body2" className={classes.dataTitle}>{d.text}</Typography>
                  <Grid container>
                    {d.values.map((v, index) =>
                      <Grid key={index} item xs={4}>
                        <v.icon fontSize="large" color={colors[d.text]} className={classes.icon} />
                        <Typography className={classes.highlight}>{Utils.formatPercent(v.value)}</Typography>
                      </Grid>
                    )}
                  </Grid>
                </div>
              )}
            </Grid>
            <Grid item xs={3}>
              <Graph />
            </Grid>
            <Grid item xs={3}>
              {dataProcessed.slice(3, 6).map((d, index) =>
                <div key={index} className={classes.dataContainer}>
                  <Typography variant="body2" className={classes.dataTitle}>{d.text}</Typography>
                  <Grid container>
                    {d.values.map((v, index) =>
                      <Grid key={index} item xs={4}>
                        <v.icon fontSize="large" color={colors[d.text]} className={classes.icon} />
                        <Typography className={classes.highlight}>{Utils.formatPercent(v.value)}</Typography>
                      </Grid>
                    )}
                  </Grid>
                </div>
              )}
            </Grid>
          </Grid>
        </Hidden>

        <Hidden mdUp>
          <Grid item xs={12} container alignItems="center">
            <Grid item xs={6}>
              <Graph mobile onChangeRegion={this.onClickRegion} activeRegion={activeRegion} />
            </Grid>
            <Grid item xs={6}>
              {dataProcessed.map((d, index) =>
                <Fragment key={index}>
                  {activeRegion === d.text.toLowerCase() &&
                    <div className={classes.dataContainer}>
                      <Typography variant="body2" className={classes.dataTitle}>{d.text}</Typography>
                      <Grid container>
                        {d.values.map((v, index) =>
                          <Grid key={index} item xs={4}>
                            <v.icon fontSize="large" color={colors[d.text]} className={classes.icon}/>
                            <Typography className={classes.highlight}>{Utils.formatPercent(v.value)}</Typography>
                          </Grid>
                        )}
                      </Grid>
                    </div>
                  }
                </Fragment>
              )}
            </Grid>
          </Grid>
        </Hidden>
      </Grid>
    );
  }
}

WidgetInternetUsersByRegion.propTypes = {
  text: PropTypes.string.isRequired,
  highlight: PropTypes.string.isRequired,
  sources: PropTypes.array,
  data: PropTypes.array.isRequired,
};

const styles = theme => ({
  root: {},
  sliderContainer: {
    paddingLeft: theme.spacing(5.5),
    paddingRight: theme.spacing(5.5),
  },
  highlight: {
    display: 'inline-block',
    fontSize: '0.9rem',
    fontWeight: theme.typography.fontWeightBold,
  },
  icon: {
    display: 'inline-block',
    paddingRight: theme.spacing(1),
  },
  dataContainer: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(0),
      marginTop: theme.spacing(-8),
    },
  },
  dataTitle: {
    marginBottom: theme.spacing(1),
  },
});

export default withRouter(withStyles(styles)(WidgetInternetUsersByRegion));