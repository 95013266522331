import IconFacebook from "../icons/icon-facebook";
import IconEcommerce from "../icons/icon-ecommerce";
import IconTwitter from "../icons/icon-twitter";
import IconGmail from "../icons/icon-gmail";
import IconGoogle from "../icons/icon-google";
import IconInstagram from "../icons/icon-instagram";
import IconNetflix from "../icons/icon-netflix";
import IconSms from "../icons/icon-sms";
import IconTinder from "../icons/icon-tinder";
import IconWhatsapp from "../icons/icon-whatsapp";
import IconYoutube from "../icons/icon-youtube";
import IconSpotify from "../icons/icon-spotify";
import IconEmail from "../icons/icon-email";
import IconAndroid from "../icons/icon-android";
import IconiOS from "../icons/icon-ios";
import IconInternet from "../icons/icon-internet";
import IconPhone from "../icons/icon-phone";
import IconWifi from "../icons/icon-wifi";
import IconComputer from "../icons/icon-computer";
import IconMobile from "../icons/icon-mobile";
import IconPhoneUser from "../icons/icon-phone-user";
import IconBank from "../icons/icon-bank";
import IconChat from "../icons/icon-chat";
import IconDate from "../icons/icon-date";
import IconShop from "../icons/icon-shop";
import IconEntertainment from "../icons/icon-entertainment";
import IconGame from "../icons/icon-game";
import IconMap from "../icons/icon-map";
import IconMusic from "../icons/icon-music";
import IconSocialNetworks from "../icons/icon-social-networks";
import IconHealth from "../icons/icon-health";
import Logo from "../components/logo";
import LogoFlacso from "../components/logo-flacso";

class Utils {
  static getTextBeforeHighlight = function(text, highlight) {
    if (highlight.trim() && text.indexOf(highlight) !== -1) {
      return text.split(highlight)[0];
    }
    return text;
  }

  static getTextAfterHighlight = function(text, highlight) {
    if (highlight.trim() && text.indexOf(highlight) !== -1) {
      return text.split(highlight)[1];
    }
    return null;
  }

  static getLogoComponentFromSlug = function(slug) {
    switch (slug) {
      case 'oia': return Logo;
      case 'flacso': return LogoFlacso;
      default: return null;
    }
  }

  static getIconComponentFromSlug = function(slug) {
    switch (slug) {
      case 'android': return IconAndroid;
      case 'ios': return IconiOS;
      case 'ecommerce': return IconEcommerce;
      case 'twitter': return IconTwitter;
      case 'facebook': return IconFacebook;
      case 'spotify': return IconSpotify;
      case 'tinder': return IconTinder;
      case 'instagram': return IconInstagram;
      case 'youtube': return IconYoutube;
      case 'netflix': return IconNetflix;
      case 'email': return IconEmail;
      case 'gmail': return IconGmail;
      case 'sms': return IconSms;
      case 'whatsapp': return IconWhatsapp;
      case 'google': return IconGoogle;
      case 'internet': return IconInternet;
      case 'phone': return IconPhone;
      case 'computer': return IconComputer;
      case 'wifi': return IconWifi;
      case 'mobile': return IconMobile;
      case 'phone-user': return IconPhoneUser;
      case 'bank': return IconBank;
      case 'chat': return IconChat;
      case 'date': return IconDate;
      case 'shop': return IconShop;
      case 'entertainment': return IconEntertainment;
      case 'game': return IconGame;
      case 'map': return IconMap;
      case 'music': return IconMusic;
      case 'social-networks': return IconSocialNetworks;
      case 'health': return IconHealth;
      default: return null;
    }
  }

  static formatTime = function(seconds) {
    let from = 11, length = 8;
    if (seconds < (60 * 10)) { // less 10 minutes
      from = 15; length = 4;
    } else if (seconds < (60 * 60)) { // less 1 hour
      from = 14; length = 5;
    }
    return new Date(seconds * 1000).toISOString().substr(from, length);
  }

  static formatNumber = function(number) {
    return Number(number).toLocaleString("es-ar", {maximumFractionDigits: 0});
  }

  static formatPercent = function(number, decimals = 1) {
    return Number(number).toLocaleString("es-ar", {maximumFractionDigits: decimals}) + '%';
  }

  static scrollTo = function(hash) {
    let element = document.getElementById(hash);
    if (element) {
      setTimeout(function() {
        let offset = parseInt(window.getComputedStyle(document.body).getPropertyValue('margin-top').replace('px', ''));
        let elementPosition = element.offsetTop;
        let offsetPosition = elementPosition - offset;
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
        });
      }, 1000);
      return true;
    }
    return false;
  }
}

export default Utils;