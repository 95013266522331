import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";
import LogoN3ctar from "../logo-n3ctar";
import LogoGeored from "../logo-geored";

class Footer extends Component {
  render() {
    let { classes } = this.props;
    return (
      <div className={classes.root}>
       <Grid container spacing={1}>
        <Grid item xs={12} align="center">
          <Typography display="block" variant="caption" className={classes.owner}>
            OIA es un proyecto de <LogoN3ctar display="inline-block"/>
          </Typography>
          <Typography display="block" variant="caption">
            hosteado por <LogoGeored display="inline-block" />
          </Typography>
        </Grid>
       </Grid>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    marginLeft: theme.spacing(-8),
    marginRight: theme.spacing(-8),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    backgroundColor: '#cccccc',
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(-2),
      marginRight: theme.spacing(-2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  owner: {
    fontWeight: theme.typography.fontWeightBold,
  },
});

export default withStyles(styles)(Footer);