import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SvgIcon from "@material-ui/core/SvgIcon";
import {withStyles} from "@material-ui/core/styles";
import clsx from "clsx";

class IconPhone extends Component {
  render() {
    let { classes, fontSize, color, className, ...other } = this.props;
    let isFontSizeXLarge = false;
    if (fontSize === 'xlarge') {
      fontSize = 'inherit';
      isFontSizeXLarge = true;
    }
    let fill = '#f2f2f2';
    if (color) {
      fill = color;
    }
    return (
      <SvgIcon
        className={clsx(classes.root, isFontSizeXLarge && classes.xlarge, className && className)}
        viewBox="0 0 37 63"
        fontSize={fontSize}
        {...other}
      >
        <path
          fill={fill}
          d="M30.6,61.37h-24a4.55,4.55,0,0,1-4.54-4.55V6A4.54,4.54,0,0,1,6.65,1.47h24A4.55,4.55,0,0,1,35.15,6V56.82A4.55,4.55,0,0,1,30.6,61.37ZM6.65,2.89A3.13,3.13,0,0,0,3.52,6V56.82A3.13,3.13,0,0,0,6.65,60h24a3.13,3.13,0,0,0,3.13-3.13V6A3.13,3.13,0,0,0,30.6,2.89Z"
        />
        <path
          fill={fill}
          d="M30.3,49.86H7a1.53,1.53,0,0,1-1.53-1.53v-41A1.54,1.54,0,0,1,7,5.81H30.3a1.54,1.54,0,0,1,1.53,1.53v41A1.53,1.53,0,0,1,30.3,49.86ZM7,7.23a.11.11,0,0,0-.11.11v41a.11.11,0,0,0,.11.12H30.3a.12.12,0,0,0,.12-.12v-41a.12.12,0,0,0-.12-.11Z"
        />
        <path
          fill={fill}
          d="M18.63,57.91a3.43,3.43,0,1,1,3.42-3.42A3.43,3.43,0,0,1,18.63,57.91Zm0-5.43a2,2,0,1,0,2,2A2,2,0,0,0,18.63,52.48Z"
        />
      </SvgIcon>
    );
  }
}

IconPhone.propTypes = {
  fontSize: PropTypes.oneOf(['small', 'default', 'large', 'xlarge', 'inherit']),
  color: PropTypes.string,
  className: PropTypes.string,
};

IconPhone.defaultProps = {
  fontSize: 'inherit',
};

const styles = () => ({
  root: {
    verticalAlign: 'bottom',
  },
  xlarge: {
    fontSize: '3rem',
  },
});

export default withStyles(styles)(IconPhone);