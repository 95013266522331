import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from "@material-ui/core/styles";
import Item from "../item";
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";
import Utils from "../../utils/utils";
import Hidden from "@material-ui/core/Hidden";
import Share from "../share";
import {withRouter} from "react-router";

const anchor = 'poblacion-con-internet';

class WidgetPopulationWithInternet extends Component {
  componentDidMount() {
    let hash = this.props.location.hash.substr(1);
    if (anchor !== hash) {
      return;
    }
    Utils.scrollTo(hash);
  }

  render() {
    let { classes, text, highlight, sources, data } = this.props;
    return (
      <Fragment>
        <Grid container id={anchor}>
          <Grid item xs={10}>
            <Item text={text} highlight={highlight} sources={sources} />
          </Grid>
          <Grid item xs={2} align="right">
            <Share fragment={anchor} />
          </Grid>
        </Grid>

        <Hidden smDown>
          <div align="center">
            <div className={classes.firstContainer}>
              <Typography variant="body1" className={classes.firstText}>{data[0].text}</Typography>
              <Typography className={classes.firstHighlight}>{Utils.formatPercent(data[0].value)}</Typography>
            </div>
            {data.slice(1).map((d, index) =>
              <div key={index} className={classes.container}>
                <Typography variant="body2">{d.text}</Typography>
                <Typography className={classes.highlight}>{Utils.formatPercent(d.value)}</Typography>
              </div>
            )}
          </div>
        </Hidden>

        <Hidden mdUp>
          <Grid container justify="center" alignItems="stretch">
            <Grid item xs={3} className={classes.firstContainer}>
              <Typography variant="body1" className={classes.firstText}>{data[0].text}</Typography>
              <Typography className={classes.firstHighlight}>{Utils.formatPercent(data[0].value)}</Typography>
            </Grid>
            <Grid item xs={6}>
              {data.slice(1).map((d, index) =>
                <div key={index} className={classes.container}>
                  <Typography variant="body2">{d.text}</Typography>
                  <Typography className={classes.highlight}>{Utils.formatPercent(d.value)}</Typography>
                </div>
              )}
            </Grid>
          </Grid>
        </Hidden>
      </Fragment>
    );
  }
}

WidgetPopulationWithInternet.propTypes = {
  text: PropTypes.string.isRequired,
  highlight: PropTypes.string.isRequired,
  sources: PropTypes.array,
  data: PropTypes.array.isRequired,
};

const styles = theme => ({
  container: {
    display: 'inline-block',
    textAlign: 'left',
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      paddingLeft: theme.spacing(2),
      padding: theme.spacing(1),
    },
  },
  firstContainer: {
    display: 'inline-block',
    textAlign: 'left',
    padding: theme.spacing(3),
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      paddingLeft: theme.spacing(2),
      padding: theme.spacing(1),
    },
  },
  firstText: {
    color: theme.palette.primary.main,
  },
  firstHighlight: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.h6.fontSize,
    fontWeight: theme.typography.fontWeightBold,
  },
  highlight: {
    fontSize: '0.9rem',
    fontWeight: theme.typography.fontWeightBold,
  },
});

export default withRouter(withStyles(styles)(WidgetPopulationWithInternet));