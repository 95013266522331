import React, {Component, Fragment} from 'react';
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";
import IconNavbarFacebook from "../../icons/icon-navbar-facebook";
import IconNavbarInstagram from "../../icons/icon-navbar-instagram";
import IconNavbarTwitter from "../../icons/icon-navbar-twitter";
import IconNavbarFlipboard from "../../icons/icon-navbar-flipboard";
import IconNavbarEmail from "../../icons/icon-navbar-email";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from '@material-ui/icons/Menu';
import Grid from "@material-ui/core/Grid";
import Drawer from "@material-ui/core/Drawer";
import Logo from "../logo";
import clsx from "clsx";
import ExternalLink from "@material-ui/core/Link";
import {Link} from "react-router-dom";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

class MenuNavbarMobile extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.onClickSubMenu = this.onClickSubMenu.bind(this);
    this.onCloseSubMenu = this.onCloseSubMenu.bind(this);

    this.state = {
      open: false,
      anchorEl: null,
    };
  }

  handleClick() {
    this.setState((state) => ({open: !state.open}));
  }

  onClickSubMenu(event) {
    this.setState({anchorEl: event.currentTarget});
  }

  onCloseSubMenu() {
    this.setState({anchorEl: null});
  }

  render() {
    let { open, anchorEl } = this.state;
    let { classes } = this.props;
    const urls = [
      {text: 'CUÁNTOS SOMOS', url: '/cuantos-somos'},
      {text: 'MOBILE', url: '/mobile'},
      {text: 'COSTUMBRES ARGENTINAS', url: '/costumbres-argentinas'},
      {text: 'SOBRE OIA', url: '/sobre-oia'},
    ];
    return (
      <Fragment>
        <IconButton onClick={this.handleClick}><MenuIcon /></IconButton>
        <Drawer
          anchor="right"
          open={open}
          classes={{paper: classes.menuItemContainer}}
          onClose={this.handleClick}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} align="right">
              <Logo />
            </Grid>
            <Grid item xs={12} align="right">
              <Link to="/" className={clsx(classes.menuItem, window.location.pathname === '/' && classes.menuItemActive)}>
                <Typography variant="body2" display="inline">HOME</Typography>
              </Link>
            </Grid>
            {urls.map((item, index) =>
              <Grid key={index} item xs={12} align="right">
                <Link to={item.url} className={clsx(classes.menuItem, window.location.pathname.startsWith(item.url) && classes.menuItemActive)}>
                  <Typography variant="body2" display="inline">{item.text}</Typography>
                </Link>
              </Grid>
            )}
            <Grid item xs={12} align="right">
              <ExternalLink onClick={this.onClickSubMenu} className={classes.menuItem}>
                <Typography variant="body2" display="inline">INFORMES</Typography>
              </ExternalLink>
              <Menu
                classes={{paper: classes.dropdownPaper}}
                elavation={5}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={this.onCloseSubMenu}
                getContentAnchorEl={null}
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                transformOrigin={{vertical: 'top', horizontal: 'center'}}
              >
                <MenuItem onClick={this.onCloseSubMenu}>
                  <ExternalLink href={window.location.origin + '/mega'} target="_blank" className={classes.menuItem}>
                    <Typography variant="body2">¿Cuánto cuesta Internet <br/> en la Argentina? 2018</Typography>
                  </ExternalLink>
                </MenuItem>
                <MenuItem onClick={this.onCloseSubMenu}>
                  <ExternalLink href={window.location.origin + '/pornografia'} target="_blank" className={classes.menuItem}>
                    <Typography variant="body2">Consumo de pornografía en <br/>Internet en Argentina 2016</Typography>
                  </ExternalLink>
                </MenuItem>
              </Menu>
            </Grid>

            <Grid item xs={12} align="right">
              <ExternalLink href="https://www.facebook.com/OIArg" target="_blank" rel="noopener" className={classes.menuItem}>
                <IconNavbarFacebook display="inline-block" fontSize="small" />
              </ExternalLink>
              {/*<ExternalLink href="#" target="_blank" rel="noopener" className={classes.menuItem}>*/}
              {/*  <IconNavbarInstagram display="inline-block" fontSize="small" />*/}
              {/*</ExternalLink>*/}
              <ExternalLink href="https://twitter.com/OIArgentina" target="_blank" rel="noopener" className={classes.menuItem}>
                <IconNavbarTwitter display="inline-block" fontSize="small" />
              </ExternalLink>
              <ExternalLink href="https://flipboard.com/@sergioadrian/oia-170dlus7y" target="_blank" rel="noopener" className={classes.menuItem}>
                <IconNavbarFlipboard display="inline-block" fontSize="small" />
              </ExternalLink>
              <ExternalLink href="mailto:contacto@n3ctar.com" target="_blank" rel="noopener" className={classes.menuItem}>
                <IconNavbarEmail display="inline-block" fontSize="small" />
              </ExternalLink>
            </Grid>
          </Grid>
        </Drawer>
      </Fragment>
    );
  }
}

MenuNavbarMobile.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = theme => ({
  menuItemContainer: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingRight: theme.spacing(2),
    width: '40%',
  },
  menuItem: {
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    textDecoration: 'none!important',
    color: theme.palette.common.black,
    '&:hover': {
      color: theme.palette.primary.main,
    }
  },
  menuItemActive: {
    color: theme.palette.primary.main,
  },
  dropdownPaper: {
    borderRadius: 0,
    marginTop: theme.spacing(1),
  }
});

export default withStyles(styles)(MenuNavbarMobile);