import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";

class Title extends Component {
  render() {
    let { classes, title } = this.props;
    return (
      <div className={classes.root}>
        <Typography component="h1" variant="h6">{title}</Typography>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    marginLeft: theme.spacing(-8),
    marginRight: theme.spacing(-8),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(-2),
      marginRight: theme.spacing(-2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(2),
    },
  },
});

export default withStyles(styles)(Title);