import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from "react-router";
import {withStyles} from "@material-ui/core/styles";
import Item from "../item";
import Utils from "../../utils/utils";
import Paragraph from "./components/Paragraph/paragraph";

class WidgetAbout extends Component {
  render() {
    let { classes, title, logo, texts } = this.props;
    let LogoComponent = Utils.getLogoComponentFromSlug(logo);
    return (
      <Fragment>
        <Item text={title} highlight="" />
        <div className={classes.containerText}>
          <div className={classes.containerLogo}>
            <LogoComponent fontSize="default" />
          </div>
          {texts.map((text, index) =>
            <Paragraph key={index} text={text.text} highlight={text.highlight} />
          )}
        </div>
      </Fragment>
    );
  }
}

WidgetAbout.propTypes = {
  title: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  texts: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    highlight: PropTypes.string,
  })).isRequired,
};

const styles = theme => ({
  containerLogo: {
    paddingBottom: theme.spacing(3),
  },
  containerText: {
    paddingLeft: theme.spacing(3),
  },
});

export default withRouter(withStyles(styles)(WidgetAbout));