import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SvgIcon from "@material-ui/core/SvgIcon";
import {withStyles} from "@material-ui/core/styles";
import clsx from "clsx";

class IconNetflix extends Component {
  render() {
    let { classes, selected, fontSize, ...other } = this.props;
    let fill1 = '#fff', fill2 = '#d5000f';
    if (selected) {
      fill1 = 'transparent';
      fill2 = '#fff';
    }
    let isFontSizeXLarge = false;
    if (fontSize === 'xlarge') {
      fontSize = 'inherit';
      isFontSizeXLarge = true;
    }
    return (
      <SvgIcon
        className={clsx(classes.root, isFontSizeXLarge && classes.xlarge)}
        viewBox="0 0 56 56"
        fontSize={fontSize}
        {...other}
      >
        <rect fill={fill1} x="18" y="1" width="17" height="51"/>
        <g id="Netflix_Logo_RGB">
          <path
            fill={fill2}
            d="M33.3,1V31.56L22.28,1h-8V55q4.23-.6,8.47-1.15V24.48l9.8,28.16c3.08-.36,6.17-.69,9.27-1V1Z"
          />
        </g>
      </SvgIcon>
    );
  }
}

IconNetflix.propTypes = {
  selected: PropTypes.bool,
  fontSize: PropTypes.oneOf(['small', 'default', 'large', 'xlarge', 'inherit']),
};

IconNetflix.defaultProps = {
  selected: false,
  fontSize: 'inherit',
};

const styles = () => ({
  root: {
    verticalAlign: 'sub',
  },
  xlarge: {
    fontSize: '3rem',
  },
});

export default withStyles(styles)(IconNetflix);