import React from 'react';
import './app.css';
import theme from "../../theme";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {routes} from "../../routes";
import Container from "@material-ui/core/Container";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import GA from "../../utils/google-analytics";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Container disableGutters maxWidth="xl">
        <Router>
          { GA.init() && <GA.RouteTracker /> }
          <Switch>
            {routes.map((route, index) => {
              let LayoutComponent = route.layout.component;
              return (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  component={(props) => <LayoutComponent {...props} page={route.page} />}
                />
              )})}
          </Switch>
        </Router>
      </Container>
    </ThemeProvider>
  );
}

export default App;
