import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SvgIcon from "@material-ui/core/SvgIcon";
import {withStyles} from "@material-ui/core/styles";
import clsx from "clsx";

class IconGmail extends Component {
  render() {
    let { classes, selected, fontSize, ...other } = this.props;
    let fill1 = '#f2f2f2', fill2 = '#231f20', fill3 = '#d54b3d', fill4 = '#d72b27', fill5 = 'url(#Degradado_sin_nombre_2)';
    if (selected) {
      fill1 = 'transparent';
      fill2 = 'transparent';
      fill3 = '#fff';
      fill4 = '#fff';
      fill5 = 'transparent';
    }
    let isFontSizeXLarge = false;
    if (fontSize === 'xlarge') {
      fontSize = 'inherit';
      isFontSizeXLarge = true;
    }
    return (
      <SvgIcon
        className={clsx(classes.root, isFontSizeXLarge && classes.xlarge)}
        viewBox="0 0 56 56"
        fontSize={fontSize}
        {...other}
      >
        <defs>
          <linearGradient id="Degradado_sin_nombre_2" x1="-436.27" y1="673.4" x2="-436.06" y2="673.4"
                          gradientTransform="matrix(253.16, 0, 0, -174.83, 110447.58, 117763.24)"
                          gradientUnits="userSpaceOnUse">
            <stop offset="0" stopOpacity="0.1"/>
            <stop offset="1" stopOpacity="0.2"/>
          </linearGradient>
        </defs>
        <path
          fill={fill1}
          d="M51.56,48.48H4.38A3.38,3.38,0,0,1,1,45.11V11A3.38,3.38,0,0,1,4.38,7.66H51.56A3.38,3.38,0,0,1,54.93,11V45.11a3.35,3.35,0,0,1-3.37,3.37Z"
        />
        <path
          fill={fill2}
          opacity="0.1"
          isolation="isolate"
          d="M7.75,48.48,27.92,32.26l.14-.87-20.79-15,0,31.34Z"
        />
        <path
          fill={fill3}
          d="M4.38,48.48A3.35,3.35,0,0,1,1,45.11V11C1,9.1,2.5,8.77,4.38,8.77S7.75,9.15,7.75,11v37.5Z"
        />
        <path
          fill={fill4}
          d="M4.38,9.25c2.4,0,2.89.72,2.89,1.73V48H4.38a2.9,2.9,0,0,1-2.89-2.89V11c0-1.06.48-1.73,2.89-1.73Zm0-.48C2.5,8.77,1,9.15,1,11V45.11a3.35,3.35,0,0,0,3.37,3.37H7.75V11c0-1.88-1.5-2.21-3.37-2.21Z"
        />
        <path
          fill={fill3}
          d="M51.56,48.48H48.19V10.89c0-1.88,1.49-2.12,3.37-2.12s3.37.24,3.37,2.12V45.16a3.34,3.34,0,0,1-3.37,3.32Z"
        />
        <path
          fill={fill4}
          d="M51.56,9.25c2.16,0,2.89.43,2.89,1.64V45.16a2.9,2.9,0,0,1-2.89,2.89H48.67V10.89c0-1.26.72-1.64,2.89-1.64Zm0-.48c-1.88,0-3.37.24-3.37,2.12V48.53h3.37a3.35,3.35,0,0,0,3.37-3.37V10.89c0-1.88-1.5-2.12-3.37-2.12Z"
        />
        <path
          fill={fill5}
          d="M37.11,48.48,1.29,12.38l1.88.77,24.94,18L54.93,11.44V45.16a3.34,3.34,0,0,1-3.37,3.32Z"
        />
        <path
          fill={fill3}
          d="M27.92,32.26,2.45,13.77a3.4,3.4,0,0,1-.82-4.71,3.5,3.5,0,0,1,4.82-.73L28,24,49.63,8.14a3.38,3.38,0,0,1,3.95,5.49L27.92,32.26Z"
        />
        <path
          fill={fill4}
          d="M51.56,8h0a2.91,2.91,0,0,1,1.73,5.25L27.92,31.68,2.74,13.39A2.94,2.94,0,0,1,2,9.34a3,3,0,0,1,2.41-1.2,2.76,2.76,0,0,1,1.73.58l21.47,15.6.29.19.29-.19L49.82,8.48A3.18,3.18,0,0,1,51.56,8Zm0-.48a3.34,3.34,0,0,0-2,.62L27.92,23.93,6.4,8.29a3.32,3.32,0,0,0-2-.63,3.48,3.48,0,0,0-2.8,1.4,3.46,3.46,0,0,0,.87,4.71L27.92,32.31,53.53,13.63a3.43,3.43,0,0,0,.77-4.72,3.29,3.29,0,0,0-2.74-1.44Z"
        />
      </SvgIcon>
    );
  }
}

IconGmail.propTypes = {
  selected: PropTypes.bool,
  fontSize: PropTypes.oneOf(['small', 'default', 'large', 'xlarge', 'inherit']),
};

IconGmail.defaultProps = {
  selected: false,
  fontSize: 'inherit',
};

const styles = () => ({
  root: {
    verticalAlign: 'sub',
  },
  xlarge: {
    fontSize: '3rem',
  },
});

export default withStyles(styles)(IconGmail);