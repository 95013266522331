import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Utils from "../../../../utils/utils";
import {Typography} from "@material-ui/core";

class Graph extends Component {
  render() {
    let { classes, title, type, total, icon, data } = this.props;
    let IconComponent = Utils.getIconComponentFromSlug(icon);
    return (
      <Grid container alignItems="stretch">
        <Grid item xs={4} container alignItems="center" className={classes.withBackground}>
          <Grid item xs={12} align="center">
            <IconComponent fontSize="xlarge" display="inline" />
            <Typography variant="body2" className={classes.type}>{type}</Typography>
            <Typography className={classes.highlight}>{Utils.formatPercent(total)}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={8} container spacing={2} className={classes.detail}>
          <Grid item xs={12}>
            <Typography variant="body2" color="primary">{title}</Typography>
          </Grid>
          {data.map((d, index) =>
            <Grid key={index} item xs={6}>
              <Typography variant="body2">{d.text}</Typography>
              <Typography className={classes.highlight}>{Utils.formatPercent(d.value)}</Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }
}

Graph.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  icon: PropTypes.string.isRequired,
  data: PropTypes.array,
};

const styles = theme => ({
  root: {},
  detail: {
    marginLeft: theme.spacing(0.5),
  },
  withBackground: {
    backgroundColor: theme.palette.primary.main,
  },
  highlight: {
    fontSize: '0.9rem',
    fontWeight: theme.typography.fontWeightBold,
  },
  type: {
    marginTop: theme.spacing(1),
    textTransform: 'uppercase',
  },
});

export default  withStyles(styles)(Graph);