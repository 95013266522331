import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SvgIcon from "@material-ui/core/SvgIcon";
import {withStyles} from "@material-ui/core/styles";
import clsx from "clsx";

class IconMusic extends Component {
  render() {
    let { classes, fontSize, ...other } = this.props;
    let isFontSizeXLarge = false;
    if (fontSize === 'xlarge') {
      fontSize = 'inherit';
      isFontSizeXLarge = true;
    }
    return (
      <SvgIcon
        className={clsx(classes.root, isFontSizeXLarge && classes.xlarge)}
        viewBox="0 0 62 47"
        fontSize={fontSize}
        {...other}
      >
        <path fill="#16b7ef"
              d="M44.06,6,24.48,11.56a1.39,1.39,0,0,0-1,1.33V31.67a5.53,5.53,0,1,0,2.78,4.78v-17l16.8-4.75v11.4a5.53,5.53,0,1,0,2.78,4.77V7.35A1.39,1.39,0,0,0,44.06,6Z"/>
      </SvgIcon>
    );
  }
}

IconMusic.propTypes = {
  fontSize: PropTypes.oneOf(['small', 'default', 'large', 'xlarge', 'inherit']),
};

IconMusic.defaultProps = {
  fontSize: 'inherit',
};

const styles = () => ({
  root: {
    verticalAlign: 'sub',
  },
  xlarge: {
    fontSize: '3rem',
  },
});

export default withStyles(styles)(IconMusic);