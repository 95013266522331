import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Item from "../item";
import Grid from "@material-ui/core/Grid";
import Graph from "./components/graph";
import Hidden from "@material-ui/core/Hidden";
import Share from "../share";
import Utils from "../../utils/utils";
import {withRouter} from "react-router";
import ItemAlternative from "./components/item-alternative";
import {withStyles} from "@material-ui/core/styles";
import clsx from "clsx";

const anchor = 'cantidad-lineas-mobiles';

class WidgetMobileLines extends Component {
  componentDidMount() {
    let hash = this.props.location.hash.substr(1);
    if (anchor !== hash) {
      return;
    }
    Utils.scrollTo(hash);
  }

  render() {
    let { classes, items, data, dataMobile } = this.props;
    return (
      <Grid container id={anchor}>
        <Grid item xs={12} md={9} container className={classes.section}>
          <Grid item xs={10} md={5} className={classes.containerFact}>
            <Item text={items[0].fact.text} highlight={items[0].fact.highlight} sources={items[0].fact.sources} />
          </Grid>
          <Hidden mdUp>
            <Grid item xs={1} align="right">
              <Share fragment={anchor} />
            </Grid>
          </Hidden>
          <Grid item xs={12} md={1} />
          <Grid item xs={10} md={5}>
            <ItemAlternative text={items[0].conclusion.text} highlight={items[0].conclusion.highlight} sources={items[0].conclusion.sources} />
          </Grid>
        </Grid>
        <Hidden smDown>
          <Grid item xs={12} md={3} align="right">
            <Share fragment={anchor} />
          </Grid>
        </Hidden>

        {items.slice(1).map((item, index, array) =>
          <Grid key={index} item xs={12} md={9} container className={clsx((index < (array.length - 1)) && classes.section)}>
            <Grid item xs={12} md={5} className={classes.containerFact}>
              <Item text={item.fact.text} highlight={item.fact.highlight} sources={item.fact.sources} />
            </Grid>
            <Grid item xs={12} md={1} />
            <Grid item xs={10} md={5}>
              <ItemAlternative text={item.conclusion.text} highlight={item.conclusion.highlight} sources={item.conclusion.sources} />
            </Grid>
          </Grid>
        )}

        <Hidden smDown>
          <Grid item xs={12} align="center">
            <Graph categories={data.texts} data={data.values} />
          </Grid>
        </Hidden>

        <Hidden mdUp>
          <Grid item xs={12} align="center">
            <Graph categories={dataMobile.texts} data={dataMobile.values} />
          </Grid>
        </Hidden>
      </Grid>
    );
  }
}

WidgetMobileLines.propTypes = {
  items: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  dataMobile: PropTypes.object.isRequired,
};

const styles = theme => ({
  section: {
    paddingBottom: theme.spacing(4),
  },
  containerFact: {
    paddingTop: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(1),
    },
  }
});

export default withRouter(withStyles(styles)(WidgetMobileLines));