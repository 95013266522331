import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SvgIcon from "@material-ui/core/SvgIcon";
import {withStyles} from "@material-ui/core/styles";
import clsx from "clsx";

class IconComputer extends Component {
  render() {
    let { classes, fontSize, color, className, ...other } = this.props;
    let isFontSizeXLarge = false;
    if (fontSize === 'xlarge') {
      fontSize = 'inherit';
      isFontSizeXLarge = true;
    }
    let fill = '#f2f2f2';
    if (color) {
      fill = color;
    }
    return (
      <SvgIcon
        className={clsx(classes.root, isFontSizeXLarge && classes.xlarge, className && className)}
        viewBox="0 0 77 54"
        fontSize={fontSize}
        {...other}
      >
        <path
          fill={fill}
          d="M63.83,51.44h5.3A5.47,5.47,0,0,0,74.59,46V44.71a.86.86,0,0,0-.86-.86H71.41V6.33a4.14,4.14,0,0,0-4.14-4.14H9.89A4.14,4.14,0,0,0,5.75,6.33V43.85H3.43a.87.87,0,0,0-.86.86V46A5.47,5.47,0,0,0,8,51.44H63.83ZM61,49.73H8A3.77,3.77,0,0,1,4.28,46v-.4H29.91v2.08a.85.85,0,0,0,.85.85H46.4a.86.86,0,0,0,.86-.85V45.57H72.88V46a3.77,3.77,0,0,1-3.75,3.76H61ZM45.54,46.79H31.62V45.56H45.54Zm24.16-3H7.46V6.33A2.43,2.43,0,0,1,9.89,3.9H67.27A2.43,2.43,0,0,1,69.7,6.33Z"
        />
        <path
          fill={fill}
          d="M64.94,6.24H12.8A2.93,2.93,0,0,0,9.88,9.17V40.56a.85.85,0,0,0,.85.86H67a.86.86,0,0,0,.86-.86V9.17A2.93,2.93,0,0,0,64.94,6.24Zm1.21,33.47H11.59V9.17A1.21,1.21,0,0,1,12.8,8H64.94a1.21,1.21,0,0,1,1.21,1.21Z"
        />
      </SvgIcon>
    );
  }
}

IconComputer.propTypes = {
  fontSize: PropTypes.oneOf(['small', 'default', 'large', 'xlarge', 'inherit']),
  color: PropTypes.string,
  className: PropTypes.string,
};

IconComputer.defaultProps = {
  fontSize: 'inherit',
};

const styles = () => ({
  root: {
    verticalAlign: 'bottom',
  },
  xlarge: {
    fontSize: '3rem',
  },
});

export default withStyles(styles)(IconComputer);