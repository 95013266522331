import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SvgIcon from "@material-ui/core/SvgIcon";
import {withStyles} from "@material-ui/core/styles";
import clsx from "clsx";

class IconGame extends Component {
  render() {
    let { classes, fontSize, ...other } = this.props;
    let isFontSizeXLarge = false;
    if (fontSize === 'xlarge') {
      fontSize = 'inherit';
      isFontSizeXLarge = true;
    }
    return (
      <SvgIcon
        className={clsx(classes.root, isFontSizeXLarge && classes.xlarge)}
        viewBox="0 0 62 47"
        fontSize={fontSize}
        {...other}
      >
        <path fill="#16b7ef"
              d="M23.31,21.64a2.87,2.87,0,1,0,2.87,2.87,2.88,2.88,0,0,0-2.87-2.87Zm0,4.35a1.48,1.48,0,1,1,1.48-1.48A1.48,1.48,0,0,1,23.31,26Z"/>
        <path fill="#16b7ef"
              d="M35.37,24.51a2.87,2.87,0,1,0,2.87-2.87A2.87,2.87,0,0,0,35.37,24.51ZM38.24,23a1.48,1.48,0,1,1-1.48,1.48A1.48,1.48,0,0,1,38.24,23Z"/>
        <path fill="#16b7ef"
              d="M42.87,35.1,43.5,37a5.65,5.65,0,0,0,3,3.32,5.56,5.56,0,0,0,2.33.51A5.73,5.73,0,0,0,51,40.4a5.58,5.58,0,0,0,3.38-6.12h0L51.23,15.63v0a8.66,8.66,0,0,0-5.29-6.76V7.55a2.09,2.09,0,0,0-2.08-2.09H41.24a2.1,2.1,0,0,0-2.09,2.09V8.94a8.92,8.92,0,0,0-2.52,1.73H24.92A8.92,8.92,0,0,0,22.4,8.94V7.55a2.1,2.1,0,0,0-2.09-2.09H17.69a2.09,2.09,0,0,0-2.08,2.09V8.84a8.67,8.67,0,0,0-5.29,6.75v0l-1,6-.46,2.74,1.37.23.46-2.74,1-6s0-.05,0-.08a7.27,7.27,0,0,1,7.18-6.26,7.19,7.19,0,0,1,5.06,2.07v.95a2.55,2.55,0,0,0,2.55,2.55h2.91a.7.7,0,0,0,0-1.39H26.48a1.16,1.16,0,0,1-1.16-1.16v-.54H36.23v.54a1.16,1.16,0,0,1-1.16,1.16H32.16a.7.7,0,0,0,0,1.39h2.91a2.55,2.55,0,0,0,2.55-2.55v-.95a7.15,7.15,0,0,1,5.06-2.07,7.27,7.27,0,0,1,7.18,6.26s0,.05,0,.08L53,34.51a4.22,4.22,0,0,1-8.17,2.05l-.64-1.91M17,8.4V7.55a.69.69,0,0,1,.69-.7h2.62a.7.7,0,0,1,.7.7v.91a8.48,8.48,0,0,0-2.14-.27A8.25,8.25,0,0,0,17,8.4Zm23.54.06V7.55a.7.7,0,0,1,.7-.7h2.62a.69.69,0,0,1,.69.7V8.4a8.25,8.25,0,0,0-1.87-.21A8.48,8.48,0,0,0,40.54,8.46Z"/>
        <path fill="#16b7ef"
              d="M42.87,35.1l1.31-.45L43.3,32l-1.24-3.68A.7.7,0,0,0,41,28a4.38,4.38,0,0,1-5.74-.51.71.71,0,0,0-.5-.21H26.8a.71.71,0,0,0-.5.21,4.38,4.38,0,0,1-5.74.51.7.7,0,0,0-1.07.34l-2.76,8.22a4.22,4.22,0,0,1-8.17-2.05l1.66-9.9-1.37-.23-1.66,9.9a5.58,5.58,0,0,0,3.38,6.12,5.73,5.73,0,0,0,2.15.43,5.56,5.56,0,0,0,2.33-.51,5.65,5.65,0,0,0,3-3.32l2.48-7.39a5.79,5.79,0,0,0,6.56-.94h7.37a5.79,5.79,0,0,0,6.56.94l1,2.85Z"/>
        <path fill="#16b7ef"
              d="M18.92,12.51a2.17,2.17,0,0,0-2.17,2.17v.15H16.6a2.17,2.17,0,0,0,0,4.34h.15v.16a2.17,2.17,0,0,0,4.34,0v-.16h.16a2.17,2.17,0,1,0,0-4.34h-.16v-.15A2.17,2.17,0,0,0,18.92,12.51Zm2.33,3.71a.78.78,0,0,1,0,1.56H20.4a.7.7,0,0,0-.7.7v.85a.78.78,0,0,1-1.56,0v-.85a.69.69,0,0,0-.69-.7H16.6a.78.78,0,0,1,0-1.56h.85a.69.69,0,0,0,.69-.69v-.85a.78.78,0,1,1,1.56,0v.85a.69.69,0,0,0,.7.69Z"/>
        <path fill="#16b7ef"
              d="M43.1,11.56a2,2,0,1,0,2,2A2,2,0,0,0,43.1,11.56Zm0,2.67a.64.64,0,0,1,0-1.28.64.64,0,0,1,0,1.28Z"/>
        <path fill="#16b7ef"
              d="M43.1,18.39a2,2,0,1,0,2,2,2,2,0,0,0-2-2Zm0,2.67a.64.64,0,1,1,0-1.28.64.64,0,0,1,0,1.28Z"/>
        <path fill="#16b7ef"
              d="M48.55,17a2,2,0,1,0-2,2A2,2,0,0,0,48.55,17Zm-2.67,0a.64.64,0,0,1,1.28,0,.64.64,0,1,1-1.28,0Z"/>
        <path fill="#16b7ef"
              d="M37.66,17a2,2,0,1,0,2-2A2,2,0,0,0,37.66,17Zm2.67,0a.64.64,0,0,1-1.28,0,.64.64,0,0,1,1.28,0Z"/>
        <path fill="#16b7ef"
              d="M28.72,19.69a.69.69,0,0,0,.7-.69.7.7,0,0,0-.7-.7H26.64A.7.7,0,0,0,26,19a.69.69,0,0,0,.69.69Z"/>
        <path fill="#16b7ef"
              d="M34.91,18.3H32.83a.7.7,0,0,0-.7.7.69.69,0,0,0,.7.69h2.08A.69.69,0,0,0,35.6,19a.7.7,0,0,0-.69-.7Z"/>
      </SvgIcon>
    );
  }
}

IconGame.propTypes = {
  fontSize: PropTypes.oneOf(['small', 'default', 'large', 'xlarge', 'inherit']),
};

IconGame.defaultProps = {
  fontSize: 'inherit',
};

const styles = () => ({
  root: {
    verticalAlign: 'sub',
  },
  xlarge: {
    fontSize: '3rem',
  },
});

export default withStyles(styles)(IconGame);