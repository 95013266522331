import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SvgIcon from "@material-ui/core/SvgIcon";
import {withStyles} from "@material-ui/core/styles";
import clsx from "clsx";

class IconEntertainment extends Component {
  render() {
    let { classes, fontSize, ...other } = this.props;
    let isFontSizeXLarge = false;
    if (fontSize === 'xlarge') {
      fontSize = 'inherit';
      isFontSizeXLarge = true;
    }
    return (
      <SvgIcon
        className={clsx(classes.root, isFontSizeXLarge && classes.xlarge)}
        viewBox="0 0 62 47"
        fontSize={fontSize}
        {...other}
      >
        <path fill="#16b7ef"
              d="M15.29,29.82h4.1v.55a.73.73,0,0,0,1.46,0v-.55H46.26a.74.74,0,1,0,0-1.47H20.85V27.8a.73.73,0,0,0-1.46,0v.55h-4.1a.74.74,0,1,0,0,1.47Z"/>
        <path fill="#16b7ef"
              d="M49.52,7.63H12a.74.74,0,0,0-.73.74V34a.73.73,0,0,0,.73.73H49.52a.73.73,0,0,0,.73-.73V8.37A.74.74,0,0,0,49.52,7.63Zm-.73,25.65h-36V9.1h36Z"/>
        <path fill="#16b7ef"
              d="M28,24.88a1.31,1.31,0,0,0,.69-.19l5.55-3.33a1.34,1.34,0,0,0,0-2.31l-5.55-3.33a1.35,1.35,0,0,0-2,1.15v6.67a1.34,1.34,0,0,0,.69,1.17,1.38,1.38,0,0,0,.66.17Zm.12-7.8,5.2,3.12-5.2,3.13Z"/>
        <path fill="#16b7ef"
              d="M53.21,11.44V7.38a2.7,2.7,0,0,0-2.71-2.7H11.05a2.7,2.7,0,0,0-2.71,2.7V36.24H6.51a.73.73,0,0,0-.73.73v2.37a3.29,3.29,0,0,0,3.29,3.3H52.48a3.3,3.3,0,0,0,3.3-3.3V37a.74.74,0,0,0-.74-.73H53.21V11.44Zm-1.46,3.43V36.24H9.8V7.38a1.25,1.25,0,0,1,1.25-1.24H50.5a1.25,1.25,0,0,1,1.25,1.24v7.49Zm2.56,24.47a1.83,1.83,0,0,1-1.83,1.83H9.07a1.83,1.83,0,0,1-1.83-1.83V37.71H54.31Z"/>
      </SvgIcon>
    );
  }
}

IconEntertainment.propTypes = {
  fontSize: PropTypes.oneOf(['small', 'default', 'large', 'xlarge', 'inherit']),
};

IconEntertainment.defaultProps = {
  fontSize: 'inherit',
};

const styles = () => ({
  root: {
    verticalAlign: 'sub',
  },
  xlarge: {
    fontSize: '3rem',
  },
});

export default withStyles(styles)(IconEntertainment);