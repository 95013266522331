import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";
import Utils from "../../../../utils/utils";
import Source from "../../../source";

class Item extends Component {
  render() {
    let { classes, header, values, sources } = this.props;
    return (
      <Fragment>
        {header &&
          <Typography color="primary" variant="body2" className={classes.text}>cada 100 habitantes</Typography>
        }
        {values.map((item, index) =>
          <Typography key={index} variant="body2" className={classes.text}>
            {Utils.getTextBeforeHighlight(item.text, item.highlight)}<span className={classes.highlight}>{item.highlight}</span> {Utils.getTextAfterHighlight(item.text, item.highlight)}
          </Typography>
        )}
        <div className={classes.sourcesContainer}>
          {sources.map((source, index) =>
            <Source key={index} text={source.text} url={source.url} />
          )}
        </div>
      </Fragment>
    );
  }
}

Item.propTypes = {
  header: PropTypes.bool,
  values: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    highlight: PropTypes.string.isRequired,
  })).isRequired,
  sources: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  })),
}

Item.defaultProps = {
  header: false,
  sources: [],
}

const styles = theme => ({
  highlight: {
    fontSize: '0.9rem',
    fontWeight: theme.typography.fontWeightBold,
  },
  text: {
    whiteSpace: 'pre-wrap',
    marginBottom: theme.spacing(2),
  },
  sourcesContainer: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(4),
    },
  },
});

export default withStyles(styles)(Item);