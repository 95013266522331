import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SvgIcon from "@material-ui/core/SvgIcon";
import {withStyles} from "@material-ui/core/styles";
import clsx from "clsx";

class IconChat extends Component {
  render() {
    let { classes, fontSize, ...other } = this.props;
    let isFontSizeXLarge = false;
    if (fontSize === 'xlarge') {
      fontSize = 'inherit';
      isFontSizeXLarge = true;
    }
    return (
      <SvgIcon
        className={clsx(classes.root, isFontSizeXLarge && classes.xlarge)}
        viewBox="0 0 62 47"
        fontSize={fontSize}
        {...other}
      >
        <path fill="#16b7ef"
              d="M39.12,44.46H22.43a3.17,3.17,0,0,1-3.16-3.16V5.92a3.17,3.17,0,0,1,3.16-3.17H39.12a3.17,3.17,0,0,1,3.16,3.17V41.3A3.17,3.17,0,0,1,39.12,44.46ZM22.43,3.74a2.18,2.18,0,0,0-2.17,2.18V41.3a2.18,2.18,0,0,0,2.17,2.18H39.12a2.18,2.18,0,0,0,2.17-2.18V5.92a2.18,2.18,0,0,0-2.17-2.18Z"/>
        <path fill="#16b7ef"
              d="M38.91,36.45H22.64a1.06,1.06,0,0,1-1.06-1.06V6.84a1.06,1.06,0,0,1,1.06-1.06H38.91A1.06,1.06,0,0,1,40,6.84V35.39A1.06,1.06,0,0,1,38.91,36.45ZM22.64,6.76a.09.09,0,0,0-.08.08V35.39a.09.09,0,0,0,.08.08H38.91a.08.08,0,0,0,.07-.08V6.84a.08.08,0,0,0-.07-.08Z"/>
        <path fill="#16b7ef"
              d="M30.78,42.06a2.39,2.39,0,1,1,2.38-2.38A2.39,2.39,0,0,1,30.78,42.06Zm0-3.78a1.4,1.4,0,1,0,1.39,1.4A1.39,1.39,0,0,0,30.78,38.28Z"/>
        <path fill="#16b7ef"
              d="M36.76,8.33H29.15a.74.74,0,0,0-.73.74v4.79a.74.74,0,0,0,.73.74H34.2a2.57,2.57,0,0,0,2.49,1.77,3.5,3.5,0,0,1-.82-1.77h.89a.74.74,0,0,0,.74-.74V9.07A.74.74,0,0,0,36.76,8.33Z"/>
        <path fill="#16b7ef"
              d="M36.76,25.33H29.15a.74.74,0,0,0-.73.74v4.79a.74.74,0,0,0,.73.74H34.2a2.57,2.57,0,0,0,2.49,1.77,3.5,3.5,0,0,1-.82-1.77h.89a.74.74,0,0,0,.74-.74V26.07A.74.74,0,0,0,36.76,25.33Z"/>
        <path fill="#16b7ef"
              d="M24.15,17.33h7.61a.74.74,0,0,1,.74.74v4.79a.74.74,0,0,1-.74.74H26.71a2.56,2.56,0,0,1-2.48,1.77A3.44,3.44,0,0,0,25,23.6h-.89a.74.74,0,0,1-.73-.74V18.07A.74.74,0,0,1,24.15,17.33Z"/>
      </SvgIcon>
    );
  }
}

IconChat.propTypes = {
  fontSize: PropTypes.oneOf(['small', 'default', 'large', 'xlarge', 'inherit']),
};

IconChat.defaultProps = {
  fontSize: 'inherit',
};

const styles = () => ({
  root: {
    verticalAlign: 'sub',
  },
  xlarge: {
    fontSize: '3rem',
  },
});

export default withStyles(styles)(IconChat);