import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SvgIcon from "@material-ui/core/SvgIcon";
import {withStyles} from "@material-ui/core/styles";
import clsx from "clsx";

class IconMap extends Component {
  render() {
    let { classes, fontSize, ...other } = this.props;
    let isFontSizeXLarge = false;
    if (fontSize === 'xlarge') {
      fontSize = 'inherit';
      isFontSizeXLarge = true;
    }
    return (
      <SvgIcon
        className={clsx(classes.root, isFontSizeXLarge && classes.xlarge)}
        viewBox="0 0 62 47"
        fontSize={fontSize}
        {...other}
      >
        <path fill="#16b7ef"
              d="M36.18,17.94l-11.69-4L13,18.11V45.18L24.5,41l11.64,4L48,41.85v-27ZM25,15.16l10.5,3.6v25L25,40.11ZM14,18.81l10-3.63V40.11L14,43.75ZM47,41.08l-10.5,2.78v-25L47,16.11Z"/>
        <path fill="#16b7ef"
              d="M39.82,7.73a9.34,9.34,0,0,0-1.88-2.79A8.13,8.13,0,0,0,35.1,3.13a8.91,8.91,0,0,0-3.33-.67,9.23,9.23,0,0,0-3.39.67,9.52,9.52,0,0,0-2.84,1.81,9.53,9.53,0,0,0-1.88,2.79A8.67,8.67,0,0,0,23,11.06c0,4.53,8.23,17.66,8.47,18v.06l.3.48.3-.48V29c.13-.12,8.42-13.37,8.42-18v-.37A8.2,8.2,0,0,0,39.82,7.73Zm-5.75,5.56a3.17,3.17,0,0,1-2.3,1,3.33,3.33,0,0,1-2.36-1,3.27,3.27,0,0,1-.91-2.24,3.46,3.46,0,0,1,.91-2.3,3.59,3.59,0,0,1,2.36-.9,3.41,3.41,0,0,1,2.3.9,3.2,3.2,0,0,1,1,2.3A3,3,0,0,1,34.07,13.29Z"/>
      </SvgIcon>
    );
  }
}

IconMap.propTypes = {
  fontSize: PropTypes.oneOf(['small', 'default', 'large', 'xlarge', 'inherit']),
};

IconMap.defaultProps = {
  fontSize: 'inherit',
};

const styles = () => ({
  root: {
    verticalAlign: 'sub',
  },
  xlarge: {
    fontSize: '3rem',
  },
});

export default withStyles(styles)(IconMap);