import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SvgIcon from "@material-ui/core/SvgIcon";
import {withStyles} from "@material-ui/core/styles";
import clsx from "clsx";

class IconDate extends Component {
  render() {
    let { classes, fontSize, ...other } = this.props;
    let isFontSizeXLarge = false;
    if (fontSize === 'xlarge') {
      fontSize = 'inherit';
      isFontSizeXLarge = true;
    }
    return (
      <SvgIcon
        className={clsx(classes.root, isFontSizeXLarge && classes.xlarge)}
        viewBox="0 0 62 47"
        fontSize={fontSize}
        {...other}
      >
        <path fill="#16b7ef"
              d="M30.49,37.73h0a.91.91,0,0,0,.8-.25L43,25.83A9.14,9.14,0,0,0,30.57,12.4l-.08.07-.08-.07A9.14,9.14,0,0,0,18,25.83L29.68,37.48a.89.89,0,0,0,.79.25Zm0-2-.08-.09-11.1-11.1A7.33,7.33,0,0,1,29.68,14.18l.18.18a.9.9,0,0,0,1.27,0l.18-.18A7.33,7.33,0,0,1,41.68,24.55l0,0Z"/>
      </SvgIcon>
    );
  }
}

IconDate.propTypes = {
  fontSize: PropTypes.oneOf(['small', 'default', 'large', 'xlarge', 'inherit']),
};

IconDate.defaultProps = {
  fontSize: 'inherit',
};

const styles = () => ({
  root: {
    verticalAlign: 'sub',
  },
  xlarge: {
    fontSize: '3rem',
  },
});

export default withStyles(styles)(IconDate);