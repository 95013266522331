import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from "@material-ui/core/styles";
import Item from "../item";
import Grid from "@material-ui/core/Grid";
import Graph from "./components/graph";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Hidden from "@material-ui/core/Hidden";
import Utils from "../../utils/utils";
import Share from "../share";
import {withRouter} from "react-router";

const anchor = 'dispositivos-por-edad';

class WidgetInternetUsersByAge extends Component {
  componentDidMount() {
    let hash = this.props.location.hash.substr(1);
    if (anchor !== hash) {
      return;
    }
    Utils.scrollTo(hash);
  }

  render() {
    let { classes, text, highlight, sources, data } = this.props;
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    return (
      <Grid container id={anchor}>
        <Grid item xs={10}>
          <Item text={text} highlight={highlight} sources={sources} />
        </Grid>
        <Grid item xs={2} align="right">
          <Share fragment={anchor} />
        </Grid>

        <Hidden mdUp>
          <Grid item xs={12}>
            <Slider {...settings}>
              {data.map((d, index) =>
                <div key={index}>
                  <div className={classes.sliderContainer}>
                    <Graph title={d.title} type={d.type} total={d.total} icon={d.icon} data={d.data} />
                  </div>
                </div>
              )}
            </Slider>
          </Grid>
        </Hidden>

        <Hidden smDown>
          <Grid item xs={12} container justify="space-evenly">
            {data.map((d, index) =>
              <Grid key={index} item xs={12} md={3}>
                <Graph title={d.title} type={d.type} total={d.total} icon={d.icon} data={d.data} />
              </Grid>
            )}
          </Grid>
        </Hidden>
      </Grid>
    );
  }
}

WidgetInternetUsersByAge.propTypes = {
  text: PropTypes.string.isRequired,
  highlight: PropTypes.string.isRequired,
  sources: PropTypes.array,
  data: PropTypes.array.isRequired,
};

const styles = theme => ({
  root: {},
  sliderContainer: {
    paddingLeft: theme.spacing(5.5),
    paddingRight: theme.spacing(5.5),
  }
});

export default withRouter(withStyles(styles)(WidgetInternetUsersByAge));