import React, {Component} from 'react';
import SvgIcon from "@material-ui/core/SvgIcon";
import {withStyles} from "@material-ui/core/styles";

class IconPlus extends Component {
  render() {
    let { classes } = this.props;
    return (
      <SvgIcon className={classes.root} viewBox="0 0 24 24" {...this.props}>
        <path d="M12,21.91a.63.63,0,0,1-.64-.63V2.64a.64.64,0,1,1,1.27,0V21.28A.63.63,0,0,1,12,21.91Z" />
        <path d="M21.28,12.59H2.64a.64.64,0,1,1,0-1.27H21.28a.64.64,0,0,1,0,1.27Z" />
      </SvgIcon>
    );
  }
}

const styles = () => ({
  root: {
    verticalAlign: 'middle',
  }
});

export default withStyles(styles)(IconPlus);