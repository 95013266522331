import React, {Component, Fragment} from 'react';
import PropTypes from "prop-types";
import CountUp from 'react-countup';

const SECONDS_PER_ANIMATION = 10;

class Animation extends Component {
  constructor(props) {
    super(props);

    this.intervalFunction = this.intervalFunction.bind(this);

    this.state = {
      intervalId: null,
      valuePerSecond: props.valuePerSecond,
      value: props.valuePerSecond * SECONDS_PER_ANIMATION,
    }
  }

  componentDidMount() {
    let intervalId = setInterval(this.intervalFunction, 1000 * SECONDS_PER_ANIMATION);
    this.setState({intervalId: intervalId});
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  intervalFunction() {
    this.setState((state) => ({ value: state.value + state.valuePerSecond * SECONDS_PER_ANIMATION}))
  }

  render() {
    let { value } = this.state;
    return (
      <Fragment>
        <CountUp
          end={value}
          separator="."
          duration={SECONDS_PER_ANIMATION}
          delay={2}
          preserveValue={true}
          useEasing={false}
        />
      </Fragment>
    );
  }
}

Animation.propTypes = {
  valuePerSecond: PropTypes.number.isRequired,
};

export default Animation;