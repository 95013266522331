import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SvgIcon from "@material-ui/core/SvgIcon";
import {withStyles} from "@material-ui/core/styles";
import clsx from "clsx";

class IconEcommerce extends Component {
  render() {
    let { classes, selected, fontSize, ...other } = this.props;
    let fill1 = '#006837'
    if (selected) {
      fill1 = '#fff';
    }
    let isFontSizeXLarge = false;
    if (fontSize === 'xlarge') {
      fontSize = 'inherit';
      isFontSizeXLarge = true;
    }
    return (
      <SvgIcon
        className={clsx(classes.root, isFontSizeXLarge && classes.xlarge)}
        viewBox="0 0 56 56"
        fontSize={fontSize}
        {...other}
      >
        <path
          fill={fill1}
          d="M31.65,31.85a3,3,0,0,0-.76-.47c-.29-.14-.59-.26-.9-.38l-.69-.26v5.42a6.74,6.74,0,0,0,1.1-.31,3.88,3.88,0,0,0,1-.52,2.77,2.77,0,0,0,.71-.78,2,2,0,0,0,.27-1.06,2.22,2.22,0,0,0-.2-1A2,2,0,0,0,31.65,31.85Z"
        />
        <path
          fill={fill1}
          d="M24.32,22.33a2.29,2.29,0,0,0,.32,1.3,2.54,2.54,0,0,0,.76.74,3,3,0,0,0,.87.38c.29.07.5.13.65.18V19.88a5,5,0,0,0-1.81.75A1.92,1.92,0,0,0,24.32,22.33Z"
        />
        <path
          fill={fill1}
          d="M28,1A27,27,0,1,0,55,28,27,27,0,0,0,28,1Zm9.31,35.41a7.31,7.31,0,0,1-1.87,2.46,9,9,0,0,1-2.78,1.58,13.7,13.7,0,0,1-3.36.76v2.53H26.92V41.21a17.6,17.6,0,0,1-4.75-.65A10.13,10.13,0,0,1,18,38.29L21.83,34a5.2,5.2,0,0,0,2.27,1.65,10.48,10.48,0,0,0,2.82.59v-6.1l-2.64-.79a9.25,9.25,0,0,1-4.17-2.44,6.17,6.17,0,0,1-1.42-4.31,6.69,6.69,0,0,1,.68-3.07,7.42,7.42,0,0,1,1.83-2.35,9.46,9.46,0,0,1,2.63-1.59,11.52,11.52,0,0,1,3.09-.79V12.26H29.3v2.53a17.51,17.51,0,0,1,4,.5A9.34,9.34,0,0,1,37,17.06l-3.46,4.22a5.31,5.31,0,0,0-2-1.09,9.77,9.77,0,0,0-2.27-.42v5.74l3.46,1a8,8,0,0,1,3.81,2.36A6.16,6.16,0,0,1,38,33.05,7.7,7.7,0,0,1,37.31,36.41Z"
        />
      </SvgIcon>
    );
  }
}

IconEcommerce.propTypes = {
  selected: PropTypes.bool,
  fontSize: PropTypes.oneOf(['small', 'default', 'large', 'xlarge', 'inherit']),
};

IconEcommerce.defaultProps = {
  selected: false,
  fontSize: 'inherit',
};

const styles = () => ({
  root: {
    verticalAlign: 'sub',
  },
  xlarge: {
    fontSize: '3rem',
  },
});

export default withStyles(styles)(IconEcommerce);