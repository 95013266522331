import React, {Component, Fragment} from 'react';
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import ClockStatic from "../clock-static";
import Paper from "@material-ui/core/Paper";
import IconClose from "../../icons/icon-close";
import {Typography} from "@material-ui/core";
import IconWhatsapp from "../../icons/icon-whatsapp";
import IconLinkedin from "../../icons/icon-linkedin";
import IconFacebook from "../../icons/icon-facebook";
import IconTwitter from "../../icons/icon-twitter";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import copy from "copy-to-clipboard";
import ButtonCopy from "../button-copy/button-copy";
import clsx from "clsx";

class ShareClocks extends Component {
  constructor(props) {
    super(props);

    this.onGenerateEmbedCode = this.onGenerateEmbedCode.bind(this);
    this.copyText = this.copyText.bind(this);
    this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
    this.onChangeSwitch = this.onChangeSwitch.bind(this);

    let checkBoxes = props.dataClocks.map(data => {
      return {slug: data.slug, value: false};
    });

    this.state = {
      switchCheck: false,
      checkBoxes: checkBoxes,
      embedCode: null,
      showEmbedCode: false,
      disableButton: true,
    }
  }

  onGenerateEmbedCode() {
    if (this.state.disableButton) {
      return;
    }
    let embedCode = this.state.checkBoxes
      .filter(data => { return data.value; })
      .map(data => { return data.slug; });

    embedCode = '<iframe src="' + window.location.origin + '/embed/relojes?f=' + embedCode.join(',') + '"></iframe>';

    this.setState({
      embedCode: embedCode,
      showEmbedCode: true,
    });
  }

  copyText() {
    copy(this.state.embedCode);
  }

  onChangeCheckbox(event) {
    event.persist();
    this.setState(state => {
      let disableButton = true;
      const list = state.checkBoxes.map((data, index) => {
        if (index === parseInt(event.target.name)) {
          if (event.target.checked) {
            disableButton = false;
          }
          return {slug: data.slug, value: event.target.checked};
        } else {
          if (data.value) {
            disableButton = false;
          }
          return data;
        }
      });

      return {
        switchCheck: state.switchCheck,
        disableButton: disableButton,
        checkBoxes: list,
        embedCode: null,
        showEmbedCode: false,
      };
    });
  }

  onChangeSwitch() {
    this.setState(state => {
      const list = state.checkBoxes.map(data => {
        return {slug: data.slug, value: !state.switchCheck};
      });

      return {
        switchCheck: !state.switchCheck,
        disableButton: state.switchCheck,
        checkBoxes: list,
        embedCode: null,
        showEmbedCode: false,
      };
    });
  }

  render() {
    let { switchCheck, checkBoxes, embedCode, showEmbedCode, disableButton } = this.state;
    let { classes, dataClocks, onClose } = this.props;
    let fragment = '%23relojes';
    let text = '%20';
    let url = encodeURIComponent(window.location.origin);

    let facebookURL = 'https://www.facebook.com/sharer/sharer.php?u=' + url + fragment + '&quote=' + text;
    let twitterURL = 'https://twitter.com/intent/tweet?text=' + text + url + fragment;
    let whatsappURL = 'https://api.whatsapp.com/send?text=' + text + url + fragment;
    let linkedinURL = 'https://www.linkedin.com/shareArticle?mini=true&url='  + url + fragment + '&title=OIA&summary=' + text;

    return (
      <Paper elevation={5} className={classes.root}>
        <Button className={classes.buttonClose} onClick={onClose}>
          <IconClose fontSize="small" />
        </Button>
        <Typography variant="body2" display="inline" className={classes.text}>
          Compartir en
        </Typography>
        <Link onClick={() => {window.open(twitterURL, '', 'width=550,height=400')}} className={classes.shareLink}>
          <IconTwitter display="inline-block" fontSize="small" />
        </Link>
        <Link onClick={() => {window.open(facebookURL, '', 'width=550,height=400')}} className={classes.shareLink}>
          <IconFacebook display="inline-block" fontSize="small" />
        </Link>
        <Link onClick={() => {window.open(whatsappURL, '', 'width=550,height=400')}} className={classes.shareLink}>
          <IconWhatsapp display="inline-block" fontSize="small" />
        </Link>
        <Link onClick={() => {window.open(linkedinURL, '', 'width=550,height=400')}} className={classes.shareLink}>
          <IconLinkedin display="inline-block" fontSize="small" />
        </Link>
        <Divider className={classes.divider} />
        <Typography variant="body2" className={classes.text}>Embeber {"< />"}</Typography>
        <FormControlLabel
          classes={{root: classes.switchRoot, label: classes.switchLabel}}
          label="TODOS"
          labelPlacement="start"
          control={<Switch checked={switchCheck} onChange={this.onChangeSwitch} color="primary" size="small" />}
        />
        <Typography variant="body2" className={classes.subtext}>Seleccionar:</Typography>
        <FormControl component="fieldset">
          <Grid container spacing={1}>
            {dataClocks.map((data, index) =>
              <Grid item xs={12} md={6} key={index}>
                <FormControlLabel
                  classes={{root: classes.formControlLabel}}
                  control={<Checkbox
                    color="primary"
                    classes={{root: classes.checkboxRoot}}
                    checked={checkBoxes[index].value}
                    name={'' + index}
                    onChange={this.onChangeCheckbox}
                  />}
                  label={<ClockStatic
                    icon={data.icon}
                    text={data.main.text}
                    clock={data.main.clock}
                  />}
                />
              </Grid>
            )}
          </Grid>
          <div align="right">
            <Button
              disableElevation
              size="small"
              className={clsx(classes.button,disableButton && classes.buttonDisable)}
              onClick={this.onGenerateEmbedCode}
            >
              Generar código
            </Button>
          </div>
        </FormControl>
        {showEmbedCode && embedCode &&
          <Fragment>
            <Typography className={classes.embedCode}>{embedCode}</Typography>
            <ButtonCopy onClick={this.copyText} />
          </Fragment>
        }
      </Paper>
    );
  }
}

ShareClocks.propTypes = {
  dataClocks: PropTypes.arrayOf(PropTypes.shape({
    slug: PropTypes.string.isRequired,
    main: PropTypes.object.isRequired,
  })).isRequired,
  onClose: PropTypes.func.isRequired,
};

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(2),
  },
  shareLink: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(0.5),
    textDecoration: 'none!important',
    cursor: 'pointer',
  },
  text: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  subtext: {
    marginBottom: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1.5),
  },
  buttonClose: {
    float: 'right',
    minWidth: 'auto',
  },
  formControlLabel: {
    marginLeft: theme.spacing(0),
  },
  switchRoot: {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(1),
  },
  switchLabel: {
    marginRight: theme.spacing(0.5),
  },
  checkboxRoot: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(1),
  },
  buttonDisable: {
    color: theme.palette.common.white + '!important',
    backgroundColor: '#cccccc!important',
    cursor: 'default',
  },
  embedCode: {
    paddingTop: theme.spacing(1),
    display: 'inline-block',
  }
});

export default withStyles(styles)(ShareClocks);