import React, {Component} from 'react';
import SvgIcon from "@material-ui/core/SvgIcon";
import {withStyles} from "@material-ui/core/styles";

class IconShare extends Component {
  render() {
    let { classes } = this.props;
    return (
      <SvgIcon className={classes.root} viewBox="0 0 24 24" {...this.props}>
        <circle cx="18" cy="4" r="3"/>
        <circle cx="6" cy="12" r="3"/>
        <circle cx="18" cy="20" r="3"/>
        <polygon points="17.28 20.29 5.29 12.19 17.24 4.01 17.92 5 7.42 12.18 17.95 19.3 17.28 20.29"/>
      </SvgIcon>
    );
  }
}

const styles = () => ({
  root: {
    verticalAlign: 'middle',
  }
});

export default withStyles(styles)(IconShare);