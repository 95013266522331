import React, {Component, Fragment} from 'react';
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";
import Utils from "../../utils/utils";

class ClockStatic extends Component {
  render() {
    let { classes, icon, text, clock } = this.props;
    let textBeforeHighlight = Utils.getTextBeforeHighlight(text, '**clock**');
    let textAfterHighlight = Utils.getTextAfterHighlight(text, '**clock**');
    let IconComponent = Utils.getIconComponentFromSlug(icon);
    return (
      <Fragment>
        <IconComponent fontSize="default" display="inline" />
        <Typography display="inline" className={classes.text}>
          {textBeforeHighlight} <span className={classes.textHighlight}>{clock.prepend + Utils.formatNumber(clock.valuePerSecond) + clock.append}</span> {textAfterHighlight}
        </Typography>
      </Fragment>
    );
  }
}

ClockStatic.propTypes = {
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  clock: PropTypes.shape({
    valuePerSecond: PropTypes.number.isRequired,
    prepend: PropTypes.string.isRequired,
    append: PropTypes.string.isRequired,
  }),
};

const styles = theme => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    cursor: 'pointer',
  },
  text: {
    paddingLeft: theme.spacing(1),
  },
  textHighlight: {
    fontWeight: theme.typography.fontWeightBold,
  },
});

export default withStyles(styles)(ClockStatic);