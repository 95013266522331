import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SvgIcon from "@material-ui/core/SvgIcon";
import {withStyles} from "@material-ui/core/styles";
import clsx from "clsx";

class IconInternet extends Component {
  render() {
    let { classes, fontSize, ...other } = this.props;
    let isFontSizeXLarge = false;
    if (fontSize === 'xlarge') {
      fontSize = 'inherit';
      isFontSizeXLarge = true;
    }
    return (
      <SvgIcon
        className={clsx(classes.root, isFontSizeXLarge && classes.xlarge)}
        viewBox="0 0 56 56"
        fontSize={fontSize}
        {...other}
      >
        <circle fill="#fff" cx="28" cy="28.18" r="26.18"/>
        <g id="Internet_conection" data-name="Internet conection">
          <path fill="#16b7ef" d="M45.06,39.7H38.82a20.19,20.19,0,0,1-4.73,8.09A20.81,20.81,0,0,0,45.06,39.7Z"/>
          <path fill="#16b7ef" d="M48.68,28.9H40.59a38.49,38.49,0,0,1-1.23,9h6.81A20.56,20.56,0,0,0,48.68,28.9Z"/>
          <path fill="#16b7ef" d="M28.9,48.62c3.32-.52,6.21-3.93,8-8.92h-8Z"/>
          <path fill="#16b7ef" d="M45.06,16.3a20.79,20.79,0,0,0-11-8.08,20.09,20.09,0,0,1,4.73,8.08Z"/>
          <path fill="#16b7ef" d="M28.9,7.38V16.3h8C35.11,11.32,32.22,7.91,28.9,7.38Z"/>
          <path fill="#16b7ef" d="M46.17,18.1H39.36a38.57,38.57,0,0,1,1.23,9h8.09A20.6,20.6,0,0,0,46.17,18.1Z"/>
          <path fill="#16b7ef" d="M28.9,37.9h8.58a36,36,0,0,0,1.31-9H28.9Z"/>
          <path fill="#16b7ef" d="M28.9,27.1h9.89a36.12,36.12,0,0,0-1.31-9H28.9Z"/>
          <path fill="#16b7ef" d="M9.83,37.9h6.81a38,38,0,0,1-1.23-9H7.32A20.56,20.56,0,0,0,9.83,37.9Z"/>
          <path fill="#16b7ef" d="M7.32,27.1h8.09a38,38,0,0,1,1.23-9H9.83A20.6,20.6,0,0,0,7.32,27.1Z"/>
          <path fill="#16b7ef" d="M21.91,47.79a20.19,20.19,0,0,1-4.73-8.09H10.94A20.81,20.81,0,0,0,21.91,47.79Z"/>
          <path fill="#16b7ef" d="M19.1,16.3h8V7.38C23.78,7.91,20.89,11.32,19.1,16.3Z"/>
          <path fill="#16b7ef" d="M27.1,28.9H17.21a36,36,0,0,0,1.31,9H27.1Z"/>
          <path fill="#16b7ef" d="M27.1,48.62V39.7h-8C20.89,44.69,23.78,48.1,27.1,48.62Z"/>
          <path fill="#16b7ef" d="M10.94,16.3h6.24a20.09,20.09,0,0,1,4.73-8.08A20.79,20.79,0,0,0,10.94,16.3Z"/>
          <path fill="#16b7ef" d="M27.1,18.1H18.52a36.12,36.12,0,0,0-1.31,9H27.1Z"/>
          <path fill="#16b7ef"
                d="M52.3,50.5H48.7a1.8,1.8,0,0,0-1.55.9H41.48A26.94,26.94,0,0,0,55,26.38c-.76-12.91-12.3-24.5-25.2-25.32A27,27,0,1,0,28,55a.9.9,0,0,0,.9-.9V50.5H27.1v2.68A25.2,25.2,0,0,1,28,2.8c.55,0,1.09,0,1.64.06,12,.76,22.81,11.58,23.51,23.62a25.16,25.16,0,0,1-16,25,.9.9,0,0,0,.33,1.74h9.63a1.78,1.78,0,0,0,1.55.9h3.6v-.9h1.8V51.4H52.3Z"/>
        </g>
      </SvgIcon>
    );
  }
}

IconInternet.propTypes = {
  fontSize: PropTypes.oneOf(['small', 'default', 'large', 'xlarge', 'inherit']),
};

IconInternet.defaultProps = {
  fontSize: 'inherit',
};

const styles = () => ({
  root: {
    verticalAlign: 'sub',
  },
  xlarge: {
    fontSize: '3rem',
  },
});

export default withStyles(styles)(IconInternet);