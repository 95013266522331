import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid";
import Utils from "../../utils/utils";
import Hidden from "@material-ui/core/Hidden";
import Share from "../share";
import {withRouter} from "react-router";
import Slider from "react-slick";
import Box from "@material-ui/core/Box";
import Item from "../item";
import Graph from "./components/graph";

const anchor = 'velocidad-y-accesibilidad';

class WidgetMobileVelocityAndAccessibility extends Component {
  componentDidMount() {
    let hash = this.props.location.hash.substr(1);
    if (anchor !== hash) {
      return;
    }
    Utils.scrollTo(hash);
  }

  render() {
    let { items } = this.props;
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    return (
      <Fragment>
        <Grid container id={anchor}>
          <Hidden smDown>
            <Grid item xs={12} align="right">
              <Share fragment={anchor} />
            </Grid>
          </Hidden>
        </Grid>
        <br/>

        <Hidden mdUp>
          <Grid item xs={12}>
            <Slider {...settings}>
              {items.map((item, index) =>
                <div key={index}>
                  <Box position="relative" width="auto" zIndex="1" align="left">
                    <Box position="absolute" top={0} right={0}>
                      <Share fragment={anchor} />
                    </Box>
                  </Box>
                  <Item text={item.text} highlight={item.highlight} sources={item.sources} />
                  <Box pl={4} pr={4}>
                    <Graph data={item.data} categories={item.categories} title={index === 0 ? 'MPBPS' : '% TIEMPO'} mobile />
                  </Box>
                </div>
              )}
            </Slider>
          </Grid>
        </Hidden>

        <Hidden smDown>
          <Grid item xs={12} container justify="space-between">
            {items.map((item, index) =>
              <Grid key={index} item xs={5}>
                <Item text={item.text} highlight={item.highlight} sources={item.sources} />
                <Graph data={item.data} categories={item.categories} title={index === 0 ? 'MPBPS' : '% TIEMPO'} />
              </Grid>
            )}
          </Grid>
        </Hidden>

      </Fragment>
    );
  }
}

WidgetMobileVelocityAndAccessibility.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    highlight: PropTypes.string,
    sources: PropTypes.array,
    data: PropTypes.array.isRequired,
    categories: PropTypes.array.isRequired,
  })).isRequired,
};

export default withRouter(WidgetMobileVelocityAndAccessibility);