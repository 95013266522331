import React, {Component} from 'react';
import SvgIcon from "@material-ui/core/SvgIcon";
import {withStyles} from "@material-ui/core/styles";

class IconNavbarEmail extends Component {
  render() {
    let { classes, ...other } = this.props;
    return (
      <SvgIcon
        className={classes.root}
        viewBox="0 0 18 18"
        {...other}
      >
        <path d="M.85,3.66c2.34,2,6.43,5.45,7.64,6.54a.7.7,0,0,0,1,0c1.21-1.09,5.3-4.56,7.64-6.54a.36.36,0,0,0,0-.49,1.38,1.38,0,0,0-1.11-.54H1.92a1.38,1.38,0,0,0-1.11.54A.36.36,0,0,0,.85,3.66Z"/>
        <path d="M17.29,4.73a.34.34,0,0,0-.37,0L11.86,9.09a.37.37,0,0,0-.13.28.38.38,0,0,0,.14.27c1.41,1.13,3.53,2.68,5.07,3.79a.37.37,0,0,0,.21.06.45.45,0,0,0,.16,0,.37.37,0,0,0,.19-.32V5.05A.35.35,0,0,0,17.29,4.73Z"/>
        <path d="M1.06,13.43c1.54-1.11,3.66-2.66,5.07-3.79a.38.38,0,0,0,.14-.27.37.37,0,0,0-.13-.28L1.08,4.78a.34.34,0,0,0-.37,0,.35.35,0,0,0-.21.32v8.09a.37.37,0,0,0,.19.32.45.45,0,0,0,.16,0A.37.37,0,0,0,1.06,13.43Z"/>
        <path d="M17.07,14.39c-1.49-1.06-4.22-3-5.85-4.36a.34.34,0,0,0-.45,0l-.78.68a1.43,1.43,0,0,1-2,0L7.23,10a.34.34,0,0,0-.45,0c-1.63,1.32-4.36,3.3-5.85,4.36a.35.35,0,0,0-.14.25.34.34,0,0,0,.09.28,1.42,1.42,0,0,0,1,.45H16.08a1.42,1.42,0,0,0,1-.45.34.34,0,0,0,.09-.28A.35.35,0,0,0,17.07,14.39Z"/>
      </SvgIcon>
    );
  }
}

const styles = () => ({
  root: {
    verticalAlign: 'middle',
  },
});

export default withStyles(styles)(IconNavbarEmail);