import LayoutMain from "./layouts/layout-main";
import LayoutEmbed from "./layouts/layout-embed";
import PageCommingSoon from "./routes/page-comming-soon";
import PageHome from "./routes/page-home";
import EmbedClocks from "./routes/embed-clocks";
import PageHowManyWeAre from "./routes/page-how-many-we-are";
import PageMobile from "./routes/page-mobile";
import PageAbout from "./routes/page-about";

export const routes = [{
  path: "/",
  name: "home",
  exact: true,
  layout: {component: LayoutMain},
  page: {component: PageHome},
}, {
  path: "/cuantos-somos",
  name: "cuantos-somos",
  exact: true,
  layout: {component: LayoutMain},
  page: {component: PageHowManyWeAre},
}, {
  path: "/mobile",
  name: "mobile",
  exact: true,
  layout: {component: LayoutMain},
  page: {component: PageMobile},
}, {
  path: "/sobre-oia",
  name: "about",
  exact: true,
  layout: {component: LayoutMain},
  page: {component: PageAbout},
}, {
  path: "/costumbres-argentinas",
  name: "customs",
  exact: true,
  layout: {component: LayoutMain},
  page: {component: PageCommingSoon},
}, {
  path: "/embed/relojes",
  name: "embed_clocks",
  exact: true,
  layout: {component: LayoutEmbed},
  page: {component: EmbedClocks},
}];

export default routes;