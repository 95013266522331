import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid";
import Share from "../share";
import Utils from "../../utils/utils";
import {withRouter} from "react-router";
import Title from "./components/title";
import Highlight from "./components/highlight";
import Item from "./components/item";
import {withStyles} from "@material-ui/core/styles";

const anchor = 'cuantos-somos';

class WidgetHowManyWeAre extends Component {
  componentDidMount() {
    let hash = this.props.location.hash.substr(1);
    if (anchor !== hash) {
      return;
    }
    Utils.scrollTo(hash);
  }

  render() {
    let { classes, title, highlights, items, every100 } = this.props;
    return (
      <Grid container id={anchor}>
        <Grid item xs={12} container className={classes.titleContainer}>
          <Grid item xs={10} md={9}>
            <Title text={title.text} highlight={title.highlight} sources={title.sources} />
          </Grid>
          <Grid item xs={2} md={3} align="right">
            <Share fragment={anchor} />
          </Grid>
        </Grid>
        <Grid item xs={12} md={9}>
          <Highlight
            text={highlights[0].text}
            highlight={highlights[0].highlight}
            sources={highlights[0].sources}
            text2={highlights[1].text}
            highlight2={highlights[1].highlight}
            sources2={highlights[1].sources}
          />
          <Grid item xs={12}>
            <div className={classes.itemsContainer}>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Item values={items.values} sources={items.sources} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Item header values={every100.values} sources={every100.sources} />
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

WidgetHowManyWeAre.propTypes = {
  title: PropTypes.shape({
    text: PropTypes.string.isRequired,
    highlight: PropTypes.string,
    sources: PropTypes.array,
  }),
  highlights: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    highlight: PropTypes.string,
    sources: PropTypes.array,
  })),
  items: PropTypes.shape({
    sources: PropTypes.array,
    values: PropTypes.array,
  }),
  every100: PropTypes.shape({
    sources: PropTypes.array,
    values: PropTypes.array,
  }),
};

const styles = theme => ({
  itemsContainer: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(2),
  },
  titleContainer: {
    paddingBottom: theme.spacing(3),
  },
});

export default withRouter(withStyles(styles)(WidgetHowManyWeAre));