import React, {Component} from 'react';
import logo from '../../images/logo_geored.png';
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";

class LogoGeored extends Component {
  render() {
    let { classes, ...other } = this.props;
    return (
      <Box className={classes.container} {...other}>
        <Link href="https://www.geored.com/" target="_blank" rel="noopener" variant="inherit" color="inherit">
          <img src={logo} className={classes.logo} alt="logo-geored" />
        </Link>
      </Box>
    );
  }
}

LogoGeored.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = theme => ({
  container: {
    verticalAlign: 'middle',
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  },
  logo: {
    height: '100%',
    maxHeight: '30px',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '30px',
    },
  },
});

export default withStyles(styles)(LogoGeored);