import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SvgIcon from "@material-ui/core/SvgIcon";
import {withStyles} from "@material-ui/core/styles";
import clsx from "clsx";

class IconSms extends Component {
  render() {
    let { classes, selected, fontSize, ...other } = this.props;
    let fill1 = '#ffdb00';
    if (selected) {
      fill1 = '#fff';
    }
    let isFontSizeXLarge = false;
    if (fontSize === 'xlarge') {
      fontSize = 'inherit';
      isFontSizeXLarge = true;
    }
    return (
      <SvgIcon
        className={clsx(classes.root, isFontSizeXLarge && classes.xlarge)}
        viewBox="0 0 56 56"
        fontSize={fontSize}
        {...other}
      >
        <path
          fill={fill1}
          d="M50.5,2.63H5.5A4.51,4.51,0,0,0,1,7.13v31.5a4.5,4.5,0,0,0,4.5,4.5h6.32L9.77,53.37,26.14,43.13H50.5a4.5,4.5,0,0,0,4.5-4.5V7.13A4.51,4.51,0,0,0,50.5,2.63Z"
        />
      </SvgIcon>
    );
  }
}

IconSms.propTypes = {
  selected: PropTypes.bool,
  fontSize: PropTypes.oneOf(['small', 'default', 'large', 'xlarge', 'inherit']),
};

IconSms.defaultProps = {
  selected: false,
  fontSize: 'inherit',
};

const styles = () => ({
  root: {
    verticalAlign: 'sub',
  },
  xlarge: {
    fontSize: '3rem',
  },
});

export default withStyles(styles)(IconSms);