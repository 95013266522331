import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SvgIcon from "@material-ui/core/SvgIcon";
import {withStyles} from "@material-ui/core/styles";
import clsx from "clsx";

class IconShop extends Component {
  render() {
    let { classes, fontSize, ...other } = this.props;
    let isFontSizeXLarge = false;
    if (fontSize === 'xlarge') {
      fontSize = 'inherit';
      isFontSizeXLarge = true;
    }
    return (
      <SvgIcon
        className={clsx(classes.root, isFontSizeXLarge && classes.xlarge)}
        viewBox="0 0 62 47"
        fontSize={fontSize}
        {...other}
      >
        <path fill="#16b7ef"
              d="M22.67,30.61H45a4.61,4.61,0,0,0,4.6-4.6v-9.5a.07.07,0,0,0,0-.05s0,0,0-.06,0,0,0,0a.15.15,0,0,0,0-.06l0-.05,0,0s0,0,0-.06l0,0,0-.05,0,0s0,0,0-.05l0,0,0,0s0,0,0,0l0,0,0,0s0,0-.05,0l0,0,0,0-.05,0-.1,0-.05,0,0,0h-.2L20.33,11.58V7.63a.49.49,0,0,0,0-.12v0s0-.05,0-.07a.15.15,0,0,0,0-.07s0,0,0,0l0-.08v0a.25.25,0,0,0,0-.07,0,0,0,0,1,0,0l0,0,0,0,0,0,0,0L20,6.83l0,0s0,0-.06,0l0,0s0,0-.05,0l-.07,0,0,0-.11,0L13.5,4a1.12,1.12,0,1,0-.88,2.07l5.45,2.3V33a4.61,4.61,0,0,0,4,4.56A4.53,4.53,0,0,0,26,44.46a4.56,4.56,0,0,0,4.55-4.54,4.47,4.47,0,0,0-.63-2.29H40a4.47,4.47,0,0,0-.63,2.29,4.55,4.55,0,1,0,4.55-4.54H22.67A2.35,2.35,0,0,1,20.32,33V30a4.66,4.66,0,0,0,2.35.63Zm5.6,9.3A2.29,2.29,0,1,1,26,37.62a2.29,2.29,0,0,1,2.29,2.29Zm18,0a2.3,2.3,0,1,1-2.3-2.29,2.3,2.3,0,0,1,2.3,2.29ZM45,28.36H22.67A2.35,2.35,0,0,1,20.32,26V13.85l27.05,3.72V26A2.36,2.36,0,0,1,45,28.36Zm0,0"/>
      </SvgIcon>
    );
  }
}

IconShop.propTypes = {
  fontSize: PropTypes.oneOf(['small', 'default', 'large', 'xlarge', 'inherit']),
};

IconShop.defaultProps = {
  fontSize: 'inherit',
};

const styles = () => ({
  root: {
    verticalAlign: 'sub',
  },
  xlarge: {
    fontSize: '3rem',
  },
});

export default withStyles(styles)(IconShop);