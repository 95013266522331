import React, {Component} from 'react';
import SvgIcon from "@material-ui/core/SvgIcon";
import {withStyles} from "@material-ui/core/styles";

class IconNavbarFacebook extends Component {
  render() {
    let { classes, ...other } = this.props;
    return (
      <SvgIcon
        className={classes.root}
        viewBox="0 0 18 18"
        {...other}
      >
        <path
          d="M9,.55a8.5,8.5,0,0,0-1.33,16.9V11.51H5.51V9.05H7.67V7.18a3,3,0,0,1,3.21-3.31A12.93,12.93,0,0,1,12.79,4V6.13H11.71a1.23,1.23,0,0,0-1.38,1.33V9.05h2.36l-.38,2.46h-2v5.94A8.5,8.5,0,0,0,9,.55Z"
        />
      </SvgIcon>
    );
  }
}

const styles = () => ({
  root: {
    verticalAlign: 'middle',
  },
});

export default withStyles(styles)(IconNavbarFacebook);