import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SvgIcon from "@material-ui/core/SvgIcon";
import {withStyles} from "@material-ui/core/styles";
import clsx from "clsx";

class IconWhatsapp extends Component {
  render() {
    let { classes, selected, fontSize, ...other } = this.props;
    let fill1 = '#fff', fill2 = '#3d9919';
    if (selected) {
      fill1 = 'transparent';
      fill2 = '#fff';
    }
    let isFontSizeXLarge = false;
    if (fontSize === 'xlarge') {
      fontSize = 'inherit';
      isFontSizeXLarge = true;
    }
    return (
      <SvgIcon
        className={clsx(classes.root, isFontSizeXLarge && classes.xlarge)}
        viewBox="0 0 56 56"
        fontSize={fontSize}
        {...other}
      >
        <path
          fill={fill1}
          d="M28,3.37A24.64,24.64,0,0,0,7.94,42.29L5.1,51.47l9.4-2.55.35-.09A24.63,24.63,0,1,0,28,3.37Z"
        />
        <path
          fill={fill2}
          d="M28.28,54.28a26.55,26.55,0,0,1-12.54-3.13L1,55.15,5.31,41.2a26.72,26.72,0,1,1,23,13.08Zm0-49.07A22.37,22.37,0,0,0,10.07,40.53L7.5,48.87,16,46.56l.31-.09A22.35,22.35,0,1,0,28.28,5.21Zm13.6,29.32c-.14,1-.51,5-6,5.39,0,0-5.29.18-11.22-4.41a31.94,31.94,0,0,1-7.79-8.94c-.1-.17-.19-.33-.27-.49a10.09,10.09,0,0,1-1.24-4.82c0-.18,0-.38,0-.58a5.7,5.7,0,0,1,3.53-5.12s2.17-.32,2.55,0L23.88,21a2,2,0,0,1,0,2.18c-.79,1-1.44,1.71-1.44,1.71s-.7.51,0,1.53S26,31.85,31,33.79A1.65,1.65,0,0,0,33.16,33l1.57-2s.38-.52,1.58,0,5,2.36,5,2.36.69.23.56,1.25Z"
        />
      </SvgIcon>
    );
  }
}

IconWhatsapp.propTypes = {
  selected: PropTypes.bool,
  fontSize: PropTypes.oneOf(['small', 'default', 'large', 'xlarge', 'inherit']),
};

IconWhatsapp.defaultProps = {
  selected: false,
  fontSize: 'inherit',
};

const styles = () => ({
  root: {
    verticalAlign: 'sub',
  },
  xlarge: {
    fontSize: '3rem',
  },
});

export default withStyles(styles)(IconWhatsapp);