import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Utils from "../../../../utils/utils";
import {Typography} from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import Icon from "../icon";

class Categories extends Component {
  render() {
    let { classes, data } = this.props;
    return (
      <Grid container>
        {data.map((d, index) =>
          <Grid key={index} item xs={6}>
            <Hidden smDown>
              <Icon icon={d.icon} fontSize="xlarge" display="inline-block" />
            </Hidden>
            <Hidden mdUp>
              <Icon icon={d.icon} fontSize="large" display="inline-block" />
            </Hidden>
            <Typography variant="body2" className={classes.text}>
              {Utils.getTextBeforeHighlight(d.text, d.highlight)}<span className={classes.highlight}>{d.highlight}</span> {Utils.getTextAfterHighlight(d.text, d.highlight)}
            </Typography>
          </Grid>
        )}
      </Grid>
    );
  }
}

Categories.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    highlight: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
  })).isRequired
};

const styles = theme => ({
  highlight: {
    fontSize: '0.9rem',
    fontWeight: theme.typography.fontWeightBold,
  },
  text: {
    whiteSpace: 'pre-wrap',
    marginBottom: theme.spacing(2),
    display: 'inline-block'
  },
});

export default  withStyles(styles)(Categories);