import React, {Component} from 'react';
import {withRouter} from "react-router";
import {withStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";
import Link from "@material-ui/core/Link";

class WidgetSuggestions extends Component {
  render() {
    let { classes } = this.props;
    return (
      <Link href="mailto:contacto@n3ctar.com" target="_blank" rel="noopener" className={classes.suggestionsLink}>
        <Typography variant="caption" className={classes.footerText}>
          Si querés colaborar, tenés sugerencias de nuevos datos o mejoras sobre los actuales, recibiremos con gusto tu mensaje.
        </Typography>
      </Link>
    );
  }
}

const styles = theme => ({
  suggestionsLink: {
    color: theme.palette.common.black,
    textDecoration: 'underline',
  }
});

export default withRouter(withStyles(styles)(WidgetSuggestions));