import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SvgIcon from "@material-ui/core/SvgIcon";
import {withStyles} from "@material-ui/core/styles";
import clsx from "clsx";

class IconInstagram extends Component {
  render() {
    let { classes, selected, fontSize, ...other } = this.props;
    let fill1 = '#fff', fill2 = 'url(#Nueva_muestra_de_degradado_1)', fill3 = 'url(#Nueva_muestra_de_degradado_1-2)', fill4 = 'url(#Nueva_muestra_de_degradado_1-3)';
    if (selected) {
      fill1 = 'transparent';
      fill2 = '#fff';
      fill3 = '#fff';
      fill4 = '#fff';
    }
    let isFontSizeXLarge = false;
    if (fontSize === 'xlarge') {
      fontSize = 'inherit';
      isFontSizeXLarge = true;
    }
    return (
      <SvgIcon
        className={clsx(classes.root, isFontSizeXLarge && classes.xlarge)}
        viewBox="0 0 56 56"
        fontSize={fontSize}
        {...other}
      >
        <defs>
          <linearGradient id="Nueva_muestra_de_degradado_1" x1="19.97" y1="54.92" x2="32.62" y2="12.52"
                          gradientUnits="userSpaceOnUse">
            <stop offset="0" stopColor="#e9b12c"/>
            <stop offset="0.34" stopColor="#d8401c"/>
            <stop offset="0.61" stopColor="#c42e81"/>
            <stop offset="1" stopColor="#61438d"/>
          </linearGradient>
          <linearGradient id="Nueva_muestra_de_degradado_1-2" x1="20.01" y1="54.93" x2="32.65" y2="12.53"
                          xlinkHref="#Nueva_muestra_de_degradado_1"/>
          <linearGradient id="Nueva_muestra_de_degradado_1-3" x1="29.32" y1="57.71" x2="41.97" y2="15.31"
                          xlinkHref="#Nueva_muestra_de_degradado_1"/>
        </defs>
        <rect fill={fill1} x="4" y="4" width="47" height="49" rx="10.78"/>
        <path
          fill={fill2}
          d="M39.41,1H16.59A15.59,15.59,0,0,0,1,16.59V39.41A15.59,15.59,0,0,0,16.59,55H39.41A15.59,15.59,0,0,0,55,39.41V16.59A15.59,15.59,0,0,0,39.41,1ZM50,39.81A10.35,10.35,0,0,1,39.68,50.16H16.32A10.35,10.35,0,0,1,6,39.81V16.45A10.35,10.35,0,0,1,16.32,6.11H39.68A10.35,10.35,0,0,1,50,16.45Z"
        />
        <path
          fill={fill3}
          d="M28,14.12a14,14,0,1,0,14,14A14,14,0,0,0,28,14.12Zm0,23.1a9.09,9.09,0,1,1,9.08-9.09A9.08,9.08,0,0,1,28,37.22Z"
        />
        <circle fill={fill4} cx="42.48" cy="13.59" r="3.26"/>
      </SvgIcon>
    );
  }
}

IconInstagram.propTypes = {
  selected: PropTypes.bool,
  fontSize: PropTypes.oneOf(['small', 'default', 'large', 'xlarge', 'inherit']),
};

IconInstagram.defaultProps = {
  selected: false,
  fontSize: 'inherit',
};

const styles = () => ({
  root: {
    verticalAlign: 'sub',
  },
  xlarge: {
    fontSize: '3rem',
  },
});

export default withStyles(styles)(IconInstagram);