import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Logo from "../logo";

class FooterEmbed extends Component {
  render() {
    let { classes } = this.props;
    return (
      <div className={classes.root}>
       <Grid container spacing={1}>
        <Grid item xs={12} align="right">
          <Logo embed={true} />
        </Grid>
       </Grid>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
});

export default withStyles(styles)(FooterEmbed);