import React, {Component} from 'react';
import SvgIcon from "@material-ui/core/SvgIcon";
import {withStyles} from "@material-ui/core/styles";

class IconClose extends Component {
  render() {
    let { classes } = this.props;
    return (
      <SvgIcon className={classes.root} viewBox="0 0 18 18" {...this.props}>
        <rect x="8.15" y="1.36" width="1.7" height="15.27" transform="translate(-3.73 9) rotate(-45)"/>
        <rect x="1.36" y="8.15" width="15.27" height="1.7" transform="translate(-3.73 9) rotate(-45)"/>
      </SvgIcon>
    );
  }
}

const styles = () => ({
  root: {
    verticalAlign: 'middle',
  }
});

export default withStyles(styles)(IconClose);