import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import ElevationScroll from "./components/elevationScroll";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import Logo from "../logo";
import MenuNavbar from "../menu-navbar/menu-navbar";
import MenuNavbarMobile from "../menu-navbar-mobile";
import {Hidden} from "@material-ui/core";

class Header extends Component {
  render() {
    let { classes } = this.props;
    return (
     <ElevationScroll>
       <AppBar color="default" position="fixed" className={classes.root}>
         <Toolbar variant="dense" disableGutters>
           <Grid container spacing={1} alignItems="center">
             <Grid item xs={6} md={2}>
               <Logo />
             </Grid>
             <Grid item xs={6} md={10} align="right">
              <Hidden mdUp><MenuNavbarMobile /></Hidden>
              <Hidden smDown><MenuNavbar /></Hidden>
             </Grid>
           </Grid>
         </Toolbar>
       </AppBar>
     </ElevationScroll>
    );
  }
}

const styles = theme => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  }
});

export default withStyles(styles)(Header);