import React, {Component} from 'react';
import SvgIcon from "@material-ui/core/SvgIcon";
import {withStyles} from "@material-ui/core/styles";

class IconNavbarFlipboard extends Component {
  render() {
    let { classes, ...other } = this.props;
    return (
      <SvgIcon
        className={classes.root}
        viewBox="0 0 18 18"
        {...other}
      >
        <rect x="2" y="2" width="4.67" height="4.67"/>
        <rect x="6.67" y="2" width="4.67" height="4.67"/>
        <rect x="2" y="6.67" width="4.67" height="4.67"/>
        <rect x="2" y="11.33" width="4.67" height="4.67"/>
        <rect x="6.67" y="6.67" width="4.67" height="4.67"/>
        <rect x="11.33" y="2" width="4.67" height="4.67"/>
      </SvgIcon>
    );
  }
}

const styles = () => ({
  root: {
    verticalAlign: 'middle',
  },
});

export default withStyles(styles)(IconNavbarFlipboard);