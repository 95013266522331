import React, {Component, Fragment} from 'react';
import Grid from "@material-ui/core/Grid";
import WidgetClocks from "../../components/widget-clocks";
import WidgetRankings from "../../components/widget-rankings";
import {Hidden} from "@material-ui/core";
import WidgetStripe from "../../components/widget-stripe";

class PageHome extends Component {
  render() {
    return (
      <Fragment>
        <Grid container spacing={1}>
          <Grid item xs={12}><WidgetStripe /></Grid>
          <Hidden smDown>
            <Grid item xs={12} md={3}><WidgetRankings /></Grid>
          </Hidden>
          <Grid item xs={12} md={9}><WidgetClocks /></Grid>
          <Hidden mdUp>
            <Grid item xs={12} md={3}><WidgetRankings /></Grid>
          </Hidden>
        </Grid>
        <br/>
        <br/>
      </Fragment>
    );
  }
}

export default PageHome;