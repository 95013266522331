import React, {Component} from 'react';
import SvgIcon from "@material-ui/core/SvgIcon";
import {withStyles} from "@material-ui/core/styles";

class IconNavbarInstagram extends Component {
  render() {
    let { classes, ...other } = this.props;
    return (
      <SvgIcon
        className={classes.root}
        viewBox="0 0 18 18"
        {...other}
      >
        <path d="M12.38,1H5.62A4.62,4.62,0,0,0,1,5.62v6.76A4.62,4.62,0,0,0,5.62,17h6.76A4.62,4.62,0,0,0,17,12.38V5.62A4.62,4.62,0,0,0,12.38,1Zm3.15,11.5a3.08,3.08,0,0,1-3.07,3.07H5.54A3.08,3.08,0,0,1,2.47,12.5V5.58A3.08,3.08,0,0,1,5.54,2.51h6.92a3.08,3.08,0,0,1,3.07,3.07Z"/>
        <path d="M9,4.89A4.15,4.15,0,1,0,13.15,9,4.15,4.15,0,0,0,9,4.89Zm0,6.84A2.69,2.69,0,1,1,11.69,9,2.68,2.68,0,0,1,9,11.73Z"/>
        <circle cx="13.29" cy="4.73" r="0.97"/>
      </SvgIcon>
    );
  }
}

const styles = () => ({
  root: {
    verticalAlign: 'middle',
  },
});

export default withStyles(styles)(IconNavbarInstagram);