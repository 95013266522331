import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import FileCopyIcon from "@material-ui/icons/FileCopy";

class ButtonCopy extends Component {
  render() {
    let { classes, iconSize, ...other } = this.props;
    return (
      <Button className={classes.root} {...other}>
        <FileCopyIcon className={classes.icon} fontSize={iconSize} />
      </Button>
    );
  }
}

ButtonCopy.propTypes = {
  classes: PropTypes.object.isRequired,
  iconSize: PropTypes.oneOf(['small', 'default', 'large', 'inherit']),
};


ButtonCopy.defaultProps = {
  size: 'small',
  iconSize: 'small',
  disableElevation: true,
};

const styles = () => ({
  root: {
    minWidth: 'auto',
    verticalAlign: 'sub',
  },
  icon: {}
});

export default withStyles(styles)(ButtonCopy);