import React, {Component, Fragment} from 'react';
import {withStyles} from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";
import IconClose from "../../icons/icon-close";
import PropTypes from "prop-types";
import Item from "./components/item";
import Utils from "../../utils/utils";
import axios from "axios";
import Source from "../source";

class Ranking extends Component {
  constructor(props) {
    super(props);

    this.onOpen = this.onOpen.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);

    this.state = {
      open: false,
      hover: false,
      ranking: props.ranking,
    }
  }

  onOpen() {
    this.setState((state) => ({open: !state.open}));
  }

  onMouseEnter() {
    this.setState({hover: true});
  }

  onMouseLeave() {
    this.setState({hover: false});
  }

  componentDidMount() {
    if (this.props.slug === 'twitter_trends') {
      axios.get('https://inter.net.ar/api/twitter_trends.php')
        .then(res => {
          let ranking = res.data.map(data => { return {text: data, count: ''}});
          this.setState({ranking: ranking});
        })
    }
  }

  render() {
    let { open, hover, ranking } = this.state;
    let { classes, text, subtext, slug, icon, sources } = this.props;
    let IconComponent = Utils.getIconComponentFromSlug(icon);
    return (
      <Fragment>
        <Grid
          container
          alignItems="center"
          className={clsx(classes.root, (open || hover) && classes.rootOpen)}
          onClick={this.onOpen}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
        >
          <Grid item xs={11}>
            <IconComponent fontSize="default" display="inline" selected={open || hover} />
            <Typography variant="body2" display="inline" className={clsx(classes.text, (open || hover) && classes.textOpen)}>
              {text}
            </Typography>
            <Typography variant="caption" display="block" className={clsx(classes.subtext, (open || hover) && classes.textOpen)}>
              {subtext}
            </Typography>
          </Grid>
          <Grid item xs={1} align="right">
            {open && <IconClose fontSize="small" display="inline"  className={clsx(classes.icon, open && classes.iconHover)} />}
          </Grid>
        </Grid>
        <Collapse in={open}>
          <div className={clsx(classes.collapseContainer, (slug === 'google_trends') && classes.collapseContainerNoPadding)}>
            {slug === 'google_trends' &&
              <iframe title="Google Trends" scrolling="no" style={{border: 'none'}} width="100%" height="400" src="https://trends.google.com.ar/trends/hottrends/widget?pn=p30&amp;tn=5&amp;h=250" />
            }
            {ranking.map((item, index) =>
              <Item key={index} order={('0' + (index + 1)).slice(-2)} text={item.text} count={item.count} />
            )}
            <br/>
            {sources.map((source, index) =>
              <Grid container key={index}>
                <Grid item xs={1} />
                <Grid item xs={11}>
                  <Source key={index} text={source.text} url={source.url} />
                </Grid>
              </Grid>
            )}
          </div>
        </Collapse>
      </Fragment>
    );
  }
}

Ranking.propTypes = {
  slug: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  subtext: PropTypes.string,
  ranking: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    count: PropTypes.string,
  })).isRequired,
  sources: PropTypes.array,
};

const styles = theme => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    cursor: 'pointer',
    borderBottom: '1px solid #fff',
  },
  rootOpen: {
    backgroundColor: theme.palette.primary.main,
  },
  text: {
    paddingLeft: theme.spacing(1),
  },
  textOpen: {
    color: theme.palette.common.white,
  },
  subtext: {
    paddingLeft: theme.spacing(3.375),
  },
  collapseContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: '#fff',
  },
  collapseContainerNoPadding: {
    padding: theme.spacing(0),
  },
  icon: {
    borderRadius: '50%',
    padding: theme.spacing(0.25),
  },
  iconHover: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
});

export default withStyles(styles)(Ranking);