import React, {Component} from 'react';
import PropTypes from "prop-types";
import Title from "../../components/title";
import {withStyles} from "@material-ui/core/styles";
import WidgetInternetUsersByAge from "../../components/widget-internet-users-by-age";
import WidgetPopulationWithInternet from "../../components/widget-population-with-internet";
import WidgetDownloadSpeed from "../../components/widget-download-speed";
import WidgetInternetUsersByRegion from "../../components/widget-internet-users-by-region";
import WidgetHowManyWeAre from "../../components/widget-how-many-we-are";
import WidgetSuggestions from "../../components/widget-suggestions";

class PageHowManyWeAre extends Component {
  render() {
    let { classes, data } = this.props;
    return (
      <div className={classes.root}>
        <Title title={data.title} />
        <br/>
        <br/>
        <div className={classes.section}>
          <WidgetHowManyWeAre
            title={data.subheader.title}
            highlights={data.subheader.highlights}
            items={data.subheader.items}
            every100={data.subheader.every100}
          />
        </div>
        <div className={classes.section}>
          <WidgetPopulationWithInternet
            text={data.populationWithInternet.text}
            highlight={data.populationWithInternet.highlight}
            sources={data.populationWithInternet.sources}
            data={data.populationWithInternet.data}
          />
        </div>
        <div className={classes.section}>
          <WidgetInternetUsersByAge
            text={data.byAge.text}
            highlight={data.byAge.highlight}
            sources={data.byAge.sources}
            data={data.byAge.data}
          />
        </div>
        <div className={classes.section}>
          <WidgetInternetUsersByRegion
            text={data.byRegion.text}
            highlight={data.byRegion.highlight}
            sources={data.byRegion.sources}
            data={data.byRegion.data}
          />
        </div>
        <div className={classes.section}>
          <WidgetDownloadSpeed
            text={data.downloadSpeed.text}
            highlight={data.downloadSpeed.highlight}
            sources={data.downloadSpeed.sources}
            data={data.downloadSpeed.data}
            dataMobile={data.downloadSpeed.dataMobile}
          />
        </div>
        <div className={classes.suggestionsContainer}>
          <WidgetSuggestions />
        </div>
      </div>
    );
  }
}

PageHowManyWeAre.propTypes = {
  data: PropTypes.shape({
    byAge: PropTypes.shape({
      text: PropTypes.string.isRequired,
      highlight: PropTypes.string.isRequired,
      sources: PropTypes.array,
      data: PropTypes.array.isRequired,
    }),
    byRegion: PropTypes.shape({
      text: PropTypes.string.isRequired,
      highlight: PropTypes.string.isRequired,
      sources: PropTypes.array,
      data: PropTypes.array.isRequired,
    }),
    populationWithInternet: PropTypes.shape({
      text: PropTypes.string.isRequired,
      highlight: PropTypes.string.isRequired,
      sources: PropTypes.array,
      data: PropTypes.array.isRequired,
    }),
    downloadSpeed: PropTypes.shape({
      text: PropTypes.string.isRequired,
      highlight: PropTypes.string.isRequired,
      sources: PropTypes.array,
      data: PropTypes.object.isRequired,
      dataMobile: PropTypes.object.isRequired,
    }),
    lastWeekDownloadSpeed: PropTypes.shape({
      text: PropTypes.string.isRequired,
      highlight: PropTypes.string.isRequired,
      sources: PropTypes.array,
    }),
  }),
};

const styles = theme => ({
  root: {
    backgroundColor: '#f2f2f2',
    marginLeft: theme.spacing(-8),
    marginRight: theme.spacing(-8),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(-2),
      marginRight: theme.spacing(-2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  section: {
    paddingBottom: theme.spacing(8),
  },
  suggestionsContainer: {
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(4),
  }
});

export default withStyles(styles)(PageHowManyWeAre);