import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";
import ButtonShare from "../button-share";
import Collapse from "@material-ui/core/Collapse";
import {withStyles} from "@material-ui/core/styles";
import ShareClocks from "../share-clocks";
import Clock from "../clock";
import Box from "@material-ui/core/Box";
import TimeCount from "./components/timeCount";
import Utils from "../../utils/utils";
import {withRouter} from "react-router";
import WidgetSuggestions from "../widget-suggestions";

class WidgetClocks extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);

    this.state = {
      open: false,
    }
  }

  handleClick() {
    this.setState((state) => ({open: !state.open}));
  }

  componentDidMount() {
    let hash = this.props.location.hash.substr(1);
    if (hash !== 'relojes') {
      return;
    }
    Utils.scrollTo(hash);
  }

  render() {
    let { open } = this.state;
    let { classes, dataClocks } = this.props;
    return (
      <Fragment>
        <Grid id="relojes" container spacing={1} className={classes.headerRoot}>
          <Grid item xs={10}>
            <Typography color="primary">
              Llevas en este sitio <Typography component="span" className={classes.textHighlight}><TimeCount /></Typography>
            </Typography>
            <Typography className={classes.textHighlight}>Mientras tanto, en Argentina pasó todo esto:</Typography>
          </Grid>
          <Grid item xs={2} align="right">
            <ButtonShare onClick={this.handleClick} />
          </Grid>
        </Grid>
        <Box position="relative" width="100%" zIndex="1">
          <Collapse in={open} className={classes.shareContainer}>
            <ShareClocks dataClocks={dataClocks} onClose={this.handleClick} />
          </Collapse>
        </Box>
        {dataClocks.map((data, index) =>
          <Clock
            key={index}
            icon={data.icon}
            slug={data.slug}
            main={data.main}
            topItems={data.topItems}
            inArgentina={data.inArgentina}
            bottomItems={data.bottomItems}
          />
        )}
        <br/>
        <Typography variant="caption" className={classes.footerText}>
          **Estos contadores muestran inferencias estadísticas propias realizadas a partir de los datos disponibles. <br/>
        </Typography>
        <div className={classes.footerText}>
          <WidgetSuggestions />
        </div>
      </Fragment>
    );
  }
}

WidgetClocks.propTypes = {
  dataClocks: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    main: PropTypes.object.isRequired,
    topItems: PropTypes.array.isRequired,
    inArgentina: PropTypes.object.isRequired,
    bottomItems: PropTypes.array,
  })).isRequired,
};

const styles = theme => ({
  headerRoot: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
  },
  footerText: {
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  shareContainer: {
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    marginTop: theme.spacing(-6),
  },
  textHighlight: {
    fontWeight: theme.typography.fontWeightBold,
  },
})

export default withRouter(withStyles(styles)(WidgetClocks));