import React, {Component, Fragment} from 'react';
import {withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";
import PropTypes from "prop-types";

class Item extends Component {
  render() {
    let { classes, order, text } = this.props;
    return (
      <Fragment>
        <Grid container className={classes.root}>
          <Grid item xs={2} md={1}>
            <Typography className={classes.order}>{order}</Typography>
          </Grid>
          <Grid item xs={10} md={6}>
            <Typography variant="body2" className={classes.text}>{text}</Typography>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

Item.propTypes = {
  order: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

const styles = theme => ({
  root: {
    paddingBottom: theme.spacing(1),
  },
  order: {
    color: theme.palette.primary.main,
  },
  text: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
});

export default withStyles(styles)(Item);