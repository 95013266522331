import React, {Component} from 'react';
import PropTypes from "prop-types";
import Highcharts from 'highcharts';
import HighchartsReact from "highcharts-react-official";
import Utils from "../../../../utils/utils";

class Graph extends Component {
  constructor(props) {
    super(props);

    let data = props.data.map((d, index) => {
      if (props.categories[index].toLowerCase() === 'argentina') {
        return {
          selected: true,
          color: '#16b7ef',
          dataLabels: {
            enabled: true,
            color: '#16b7ef',
            align: 'center',
            padding: 10,
            style: {fontSize: '0.8163265306122448rem', fontWeight: 700, textOutline: 'none', fontFamily: 'Manrope, sans-serif'},
          },
          y: Math.round(d),
        };
      }
      return d;
    });

    this.state = {
      chartOptions: {
        chart: {
          type: 'column',
          backgroundColor: 'transparent',
          spacingTop: 32,
        },
        colors: ['#cccccc'],
        title: {text: null},
        xAxis: {
          gridLineWidth: 0,
          lineWidth: 1,
          lineColor: '#cccccc',
          title: {
            text: '',
            margin: 20,
            style: {color: '#16b7ef', fontWeight: 700}
          },
          categories: props.categories,
        },
        yAxis: {
          gridLineWidth: 1,
          gridLineColor: '#cccccc',
          lineWidth: 1,
          lineColor: '#cccccc',
          tickInterval: 10,
          title: {
            text: props.title,
            align: 'high',
            rotation: 0,
            style: {color: '#16b7ef', fontWeight: 700},
          },
        },
        tooltip: {
          backgroundColor: '#fff',
          borderColor: '#16b7ef',
          borderRadius: 0,
          borderWidth: 2,
          distance: 16,
          outside: true,
          shadow: false,
          useHTML: true,
          valueDecimals: 2,
          formatter: function() {
            if (this.x.toLowerCase() === 'argentina') {
              return false;
            }
            return '' +
              '<div class="MuiTypography-body2" style="padding-right: 8px; padding-bottom: 4px; vertical-align: top;">' +
                '<b>' + this.x + '</b>' +
              '</div>' +
              '<div class="MuiTypography-body2" style="vertical-align: top;">' +
                '<b style="font-size: 0.9rem; color: #16b7ef;">' + Utils.formatNumber(this.y) + '</b><br/>' +
                'del tiempo' +
              '</div>';
          },
        },
        legend: {enabled: false},
        plotOptions: {
          column: {
            borderColor: '#cccccc',
            // allowPointSelect: true,
            states: {
              hover: {color: '#16b7ef', borderColor: '#16b7ef'},
              select: {color: '#16b7ef', borderColor: '#fff', borderWidth: 10, backgroundColor: '#fff'},
            },
          }
        },
        series: [{
          data: data,
        }],
        credits: {enabled: false},
        responsive: (props.mobile && {
          rules: [{
            condition: {maxWidth: 600},
            chartOptions: {
              chart: {
                spacingLeft: 0,
                spacingTop: 64,
              },
              yAxis: {
                title: {
                  textAlign: 'left',
                  y: -32,
                  x: -138,
                  offset: -138,
                }
              }
            },
          }],
        }),
      }
    };

  }

  render() {
    let { chartOptions } = this.state;
    return (
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    );
  }
}

Graph.propTypes = {
  categories: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  mobile: PropTypes.bool,
  title: PropTypes.string,
};

Graph.defaultProps = {
  mobile: false,
  title: '',
};

export default Graph;