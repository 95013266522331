import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Ranking from "../ranking/ranking";
import {Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {withStyles} from "@material-ui/core/styles";

class WidgetRankings extends Component {
  render() {
    let { classes, dataRankings } = this.props;
    return (
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <Typography className={classes.title}>Ahora en Argentina</Typography>
        </Grid>
        <Grid item xs={12}>
          {dataRankings.map((data, index) =>
            <Ranking
              key={index}
              slug={data.slug}
              icon={data.icon}
              text={data.text}
              subtext={data.subtext}
              ranking={data.ranking}
              sources={data.sources}
            />
          )}
        </Grid>
      </Grid>
    );
  }
}

WidgetRankings.propTypes = {
  dataRankings: PropTypes.arrayOf(PropTypes.shape({
    slug: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    subtext: PropTypes.string,
    ranking: PropTypes.array.isRequired,
    sources: PropTypes.array,
  })).isRequired,
};

const styles = theme => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    backgroundColor: '#f2f2f2',
  },
  title: {
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    fontWeight: 'bold',
  },
});

export default withStyles(styles)(WidgetRankings);