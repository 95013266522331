import React, {Component, Fragment} from 'react';
import {withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";
import PropTypes from "prop-types";
import Hidden from "@material-ui/core/Hidden";

class Item extends Component {
  render() {
    let { classes, order, text, count } = this.props;
    return (
      <Fragment>
        <Grid container className={classes.root}>
          <Grid item xs={1}>
            <Typography className={classes.order}>{order}</Typography>
          </Grid>
          <Grid item xs={11} md={6}>
            <Typography variant="body2" className={classes.text}>{text}</Typography>
          </Grid>
          <Hidden mdUp>
            <Grid item xs={1}></Grid>
          </Hidden>
          <Grid item xs={11} md={5} align="right">
            {count && <Typography variant="body2" className={classes.count}>{count}</Typography>}
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

Item.propTypes = {
  order: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  count: PropTypes.string,
};

const styles = theme => ({
  root: {
    paddingBottom: theme.spacing(1),
  },
  order: {
    color: theme.palette.primary.main,
  },
  text: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  count: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
  },
});

export default withStyles(styles)(Item);