import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Header from "../../components/header";
import Footer from "../../components/footer";
import BackToTop from "./components/back-to-top";
import Box from "@material-ui/core/Box";

class LayoutMain extends Component {
  render() {
    let { page } = this.props;
    let PageComponent = page.component;
    return (
      <Box pr={{ xs: 2, md: 8}} pl={{ xs: 2, md: 8}}>
        <div id="back-to-top-anchor" />
        <Header />
        <PageComponent {...page.props} />
        <BackToTop />
        <Footer />
      </Box>
    );
  }
}

LayoutMain.propTypes = {
  page: PropTypes.object.isRequired,
};

export default LayoutMain;