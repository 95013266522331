import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";
import Utils from "../../../../utils/utils";

class Paragraph extends Component {
  render() {
    let { classes, text, highlight } = this.props;
    let textBeforeHighlight = Utils.getTextBeforeHighlight(text, highlight);
    let textAfterHighlight = Utils.getTextAfterHighlight(text, highlight);
    return (
      <div className={classes.root}>
        <Typography variant="body2" className={classes.text}>
          {textBeforeHighlight} <span className={classes.highlight}>{highlight}</span> {textAfterHighlight}
        </Typography>
      </div>
    );
  }
}

Paragraph.propTypes = {
  text: PropTypes.string.isRequired,
  highlight: PropTypes.string.isRequired,
}

Paragraph.defaultProps = {
  sources: [],
}

const styles = theme => ({
  root: {
    paddingBottom: theme.spacing(3),
  },
  highlight: {
    fontWeight: theme.typography.fontWeightBold,
  },
  text: {
    whiteSpace: 'pre-wrap',
  },
});

export default withStyles(styles)(Paragraph);