import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Utils from "../../../../utils/utils";

class Icon extends Component {
  render() {
    let { icon, ...other } = this.props;
    let IconComponent = Utils.getIconComponentFromSlug(icon);
    return (
      <IconComponent {...other} />
    );
  }
}

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
};

export default Icon;