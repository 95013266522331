import React, {Component, Fragment} from 'react';
import {withStyles} from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {Typography} from "@material-ui/core";
import Utils from "../../../../utils/utils";
import Source from "../../../source";
import Grid from "@material-ui/core/Grid";
import IconInternet from "../../../../icons/icon-internet";

class Highlight extends Component {
  render() {
    let { classes, text, highlight, sources, text2, highlight2, sources2 } = this.props;
    let textBeforeHighlight = Utils.getTextBeforeHighlight(text, highlight);
    let textAfterHighlight = Utils.getTextAfterHighlight(text, highlight);
    let hasAlternativeText = (text2 || highlight2 || sources2.length > 0 || false);
    let textBeforeHighlight2 = Utils.getTextBeforeHighlight(text2, highlight2);
    let textAfterHighlight2 = Utils.getTextAfterHighlight(text2, highlight2);
    return (
      <Fragment>
        <div className={classes.containerIcon}>
          <IconInternet fontSize="xlarge" />
        </div>
        <div className={classes.containerText}>
          <Grid container>
            <Grid item xs={12} md={hasAlternativeText ? 6 : 12}>
              <Typography component="p" variant="h6" className={classes.text}>
                {textBeforeHighlight} <span className={classes.highlight}>{highlight}</span> {textAfterHighlight}
              </Typography>
              <div className={classes.sourcesContainer}>
                {sources.map((source, index) =>
                  <Source key={index} text={source.text} url={source.url} />
                )}
              </div>
            </Grid>
            {hasAlternativeText &&
              <Grid item xs={12} md={6}>
                <Typography component="p" variant="h6" className={classes.text}>
                  {textBeforeHighlight2} <span className={classes.highlight}>{highlight2}</span> {textAfterHighlight2}
                </Typography>
                {sources2.map((source, index) =>
                  <Source key={index} text={source.text} url={source.url} />
                )}
              </Grid>
            }
          </Grid>
        </div>
      </Fragment>
    );
  }
}

Highlight.propTypes = {
  classes: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  highlight: PropTypes.string,
  sources: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  })),
  text2: PropTypes.string,
  highlight2: PropTypes.string,
  sources2: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  })),
};

Highlight.defaultProps = {
  highlight: '',
  sources: [],
  text2: '',
  highlight2: '',
  sources2: [],
};

const styles = theme => ({
  containerIcon: {
    float: 'left',
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
    },
  },
  containerText: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(4),
    marginBottom: theme.spacing(3),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    borderTopRightRadius: theme.spacing(3),
    borderBottomRightRadius: theme.spacing(3),
  },
  text: {
    color: theme.palette.primary.light,
    whiteSpace: 'pre-wrap',
    lineHeight: 1.4,
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
  },
  highlight: {
    fontWeight: theme.typography.fontWeightBold,
  },
  sourcesContainer: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(2),
    },
  },
});

export default withStyles(styles)(Highlight);