import {createMuiTheme} from "@material-ui/core";

const theme = createMuiTheme({
  typography: {
    fontFamily: 'Manrope, sans-serif',
    htmlFontSize: 21,
    fontSize: 16,
  },
  palette: {
    primary: {
      light: '#5fd3f2',
      main: '#16b7ef',
      dark: '#09a7cc',
    },
    text: {
      primary: '#333333',
    },
    background: {
      paper: '#f2f2f2',
      default: '#ffffff',
    }
  },
  shape: {
    borderRadius: 20,
  },
});

theme.overrides = {
  MuiButton: {
    text: {
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        '@media (hover: none)': {
          backgroundColor: theme.palette.primary.main,
        },
      },
    }
  },
};

export default theme;