import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";
import Item from "../item";

class Apps extends Component {
  render() {
    let { classes, title, data } = this.props;
    return (
      <Fragment>
        <Typography color="primary" variant="body2" className={classes.text}>{title}</Typography>
        {data.map((d, index) =>
          <Item
            key={index}
            text={d}
            order={('0' + (index + 1)).slice(-2)}
          />
        )}
      </Fragment>
    );
  }
}

Apps.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
};

const styles = theme => ({
  highlight: {
    fontWeight: theme.typography.fontWeightBold,
  },
  text: {
    whiteSpace: 'pre-wrap',
    marginBottom: theme.spacing(2),
  },
});

export default  withStyles(styles)(Apps);