import React, {Component, Fragment} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import imageMapAllArgentinian from "../../../../images/map-all-argentinians.svg"
import imageMapOneArgentinian from "../../../../images/map-one-argentinian.svg"
import Highlight from "../../../highlight";

class InArgentina extends Component {
  render() {
    let { classes, icon, main, all, one } = this.props;
    let allNote = '*', oneNote = '*';
    if (all.note && one.note) {
      oneNote = '**';
    } else if (!all.note && !one.note) {
      allNote = '';
      oneNote = '';
    }
    return (
      <div className={classes.root}>
        <Highlight text={main.text} highlight={main.highlight} icon={icon} />
        <Grid container>
          <Grid item xs={12} md={6} container spacing={1} alignItems="center" className={classes.containerGraph}>
            <Grid item xs={6} md={4}>
              <img src={imageMapAllArgentinian} alt="Todos los argentinos" />
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography variant="body2">Todxs lxs argentinxs{allNote}</Typography>
              <br/>
              {all.perSecond &&
                <Fragment>
                  <Typography variant="caption">POR SEGUNDO</Typography>
                  <Typography><strong>{all.perSecond}</strong></Typography>
                  <br/>
                </Fragment>
              }
              {all.perDay &&
                <Fragment>
                  <Typography variant="caption">POR DíA</Typography>
                  <Typography><strong>{all.perDay}</strong></Typography>
                  <br/>
                </Fragment>
              }
              {all.perYear &&
                <Fragment>
                  <Typography variant="caption">POR AÑO</Typography>
                  <Typography><strong>{all.perYear}</strong></Typography>
                </Fragment>
              }
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} container spacing={1} alignItems="center" className={classes.containerGraph}>
            <Grid item xs={6} md={4}>
              <img src={imageMapOneArgentinian} alt="Un argentino" />
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography variant="body2">1 argentinx{oneNote}</Typography>
              <br/>
              {one.perSecond &&
                <Fragment>
                  <Typography variant="caption">POR SEGUNDO</Typography>
                  <Typography><strong>{one.perSecond}</strong></Typography>
                  <br/>
                </Fragment>
              }
              {one.perDay &&
                <Fragment>
                  <Typography variant="caption">POR DÍA</Typography>
                  <Typography><strong>{one.perDay}</strong></Typography>
                  <br/>
                </Fragment>
              }
              {one.perWeek &&
                <Fragment>
                  <Typography variant="caption">POR SEMANA</Typography>
                  <Typography><strong>{one.perWeek}</strong></Typography>
                  <br/>
                </Fragment>
              }
              {one.perMonth &&
                <Fragment>
                  <Typography variant="caption">POR MES</Typography>
                  <Typography><strong>{one.perMonth}</strong></Typography>
                  <br/>
                </Fragment>
              }
              {one.perYear &&
                <Fragment>
                  <Typography variant="caption">POR AÑO</Typography>
                  <Typography><strong>{one.perYear}</strong></Typography>
                  <br/>
                </Fragment>
              }
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} container spacing={1} alignItems="center">
            <Grid item xs={6} md={4} />
            <Grid item xs={6} md={6}>
              <br/>
              <Typography variant="caption">{all.note}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} container spacing={1} alignItems="center">
            <Grid item xs={6} md={4} />
            <Grid item xs={6} md={6}>
              <br/>
              <Typography variant="caption">{one.note}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

InArgentina.propTypes = {
  icon: PropTypes.string.isRequired,
  main: PropTypes.shape({
    text: PropTypes.string.isRequired,
    highlight: PropTypes.string,
  }).isRequired,
  all: PropTypes.shape({
    perSecond: PropTypes.string,
    perDay: PropTypes.string,
    perYear: PropTypes.string,
    note: PropTypes.string,
  }).isRequired,
  one: PropTypes.shape({
    perSecond: PropTypes.string,
    perDay: PropTypes.string,
    perWeek: PropTypes.string,
    perMonth: PropTypes.string,
    perYear: PropTypes.string,
    note: PropTypes.string,
  }).isRequired,
};

const styles = theme => ({
  root: {
    paddingBottom: theme.spacing(5),
  },
  containerGraph: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(3),
    },
  },
  image: {
    maxHeight: '214px',
  }
});

export default withStyles(styles)(InArgentina);