import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";
import Utils from "../../../../utils/utils";
import Source from "../../../source";

class Title extends Component {
  render() {
    let { classes, text, highlight, sources } = this.props;
    let textBeforeHighlight = Utils.getTextBeforeHighlight(text, highlight);
    let textAfterHighlight = Utils.getTextAfterHighlight(text, highlight);
    return (
      <Fragment>
        <div className={classes.containerText}>
          <Typography className={classes.text}>
            {textBeforeHighlight} <span className={classes.highlight}>{highlight}</span> {textAfterHighlight}
          </Typography>
          {sources.map((source, index) =>
            <div key={index} className={classes.source}>
              <Source text={source.text} url={source.url} />
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

Title.propTypes = {
  text: PropTypes.string.isRequired,
  highlight: PropTypes.string.isRequired,
  sources: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  })),
}

Title.defaultProps = {
  sources: [],
}

const styles = theme => ({
  containerText: {
    paddingLeft: theme.spacing(3),
  },
  highlight: {
    fontWeight: theme.typography.fontWeightBold,
  },
  text: {
    display: 'inline-block',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  source: {
    display: 'inline-block',
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(0),
      display: 'block',
    },
  },
});

export default withStyles(styles)(Title);