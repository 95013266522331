import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconClose from "../../icons/icon-close";
import {Typography} from "@material-ui/core";
import IconTwitter from "../../icons/icon-twitter";
import IconFacebook from "../../icons/icon-facebook";
import IconWhatsapp from "../../icons/icon-whatsapp";
import IconLinkedin from "../../icons/icon-linkedin";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import ButtonShare from "../button-share";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";

class Share extends Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);

    this.state = {
      open: false,
    };
  }

  onClick() {
    this.setState((state) => ({open: !state.open}));
  }

  render() {
    let { open } = this.state;
    let { classes, text, fragment } = this.props;
    text = text ? encodeURIComponent(text) : '';
    fragment = fragment ? '%23' + encodeURIComponent(fragment) : '';
    let url = encodeURIComponent(window.location.origin);

    let facebookURL = 'https://www.facebook.com/sharer/sharer.php?u='
      + url + fragment + (text ? '&quote=' + text : '');

    let twitterURL = 'https://twitter.com/intent/tweet?text='
      + (text ? text + '%20' : '') + url + fragment;

    let whatsappURL = 'https://api.whatsapp.com/send?text='
      + (text ? text + '%0D%0A': '') + url + fragment;

    let linkedinURL = 'https://www.linkedin.com/shareArticle?mini=true&url='
      + url + fragment + '&title=OIA' + (text ? '&summary=' + text : '');

    return (
      <Fragment>
        <ButtonShare onClick={this.onClick} />

        <Box position="relative" width="auto" zIndex="1" align="left">
          <Collapse in={open} className={classes.shareContainer}>
            <Paper elevation={5} className={classes.paper}>
              <Button className={classes.buttonClose} onClick={this.onClick}>
                <IconClose fontSize="small" />
              </Button>
              <Typography variant="body2" className={classes.text}>
                Compartir:
              </Typography>
              <Link onClick={() => {window.open(twitterURL, '', 'width=550,height=400')}} className={classes.shareLink}>
                <IconTwitter display="inline-block" fontSize="small" />
              </Link>
              <Link onClick={() => {window.open(facebookURL, '', 'width=550,height=400')}} className={classes.shareLink}>
                <IconFacebook display="inline-block" fontSize="small" />
              </Link>
              <Link onClick={() => {window.open(whatsappURL, '', 'width=550,height=400')}} className={classes.shareLink}>
                <IconWhatsapp display="inline-block" fontSize="small" />
              </Link>
              <Link onClick={() => {window.open(linkedinURL, '', 'width=550,height=400')}} className={classes.shareLink}>
                <IconLinkedin display="inline-block" fontSize="small" />
              </Link>
            </Paper>
          </Collapse>
        </Box>
      </Fragment>
    );
  }
}

Share.propTypes = {
  text: PropTypes.string,
  fragment: PropTypes.string,
};

Share.defaultProps = {
  text: null,
  fragment: null,
}

const styles = theme => ({
  paper: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    borderRadius: theme.spacing(1.5),
    width: '140px',
  },
  shareLink: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(0.5),
    textDecoration: 'none!important',
    cursor: 'pointer',
  },
  buttonClose: {
    float: 'right',
    minWidth: 'auto',
  },
  text: {
    fontWeight: theme.typography.fontWeightBold,
    marginTop: theme.spacing(8/7),
    marginBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  shareContainer: {
    position: 'absolute',
    top: '0',
    right: '0',
  },
});

export default withStyles(styles)(Share);