import React, {Component} from 'react';
import SvgIcon from "@material-ui/core/SvgIcon";
import {withStyles} from "@material-ui/core/styles";

class IconLinkedin extends Component {
  render() {
    let { classes } = this.props;
    return (
      <SvgIcon className={classes.root} viewBox="0 0 24 24" {...this.props}>
        <path
          fill="#0071bc"
          d="M2.5,21.18V8.71H6.4V21.18Zm1.86-14h0a2.17,2.17,0,1,1,0-4.33,2.17,2.17,0,1,1,0,4.33Zm13.22,14V14.73c0-1.69-.69-2.85-2.22-2.85a2.23,2.23,0,0,0-2.1,1.54,2.62,2.62,0,0,0-.1,1v6.75H8.78s.06-11.44,0-12.47h4.38v1.95c.26-.85,1.66-2.07,3.89-2.07,2.77,0,4.95,1.79,4.95,5.66v6.93Z"
        />
      </SvgIcon>
    );
  }
}

const styles = () => ({
  root: {
    verticalAlign: 'middle',
  }
});

export default withStyles(styles)(IconLinkedin);