import React, {Component} from 'react';
import SvgIcon from "@material-ui/core/SvgIcon";
import {withStyles} from "@material-ui/core/styles";

class IconItem extends Component {
  render() {
    let { classes } = this.props;
    return (
      <SvgIcon className={classes.root} viewBox="0 0 10 18" {...this.props}>
        <polygon
          fill="#b3b3b3"
          points="1 1 9 9 1 17 1 1"
        />
      </SvgIcon>
    );
  }
}

const styles = () => ({
  root: {
    verticalAlign: 'sub',
  }
});

export default withStyles(styles)(IconItem);